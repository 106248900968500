.accord-box {  box-shadow: 0px 0px 8px rgba(0,0,0,0.1);}
/*----style----*/
.displayFlex{display: flex;}
/* vertical and horizontal centre with flex */
.centeredFlex{
  align-items: center;
  justify-content: center;
}
/* Content at the start */
.flexStart{justify-content: flex-start;}
.menuitem_rr .meetflavordiv .float-left.black.font-13{
  min-height: 2rem;
}
.alert.alert-success.text-center.profile-message-flash{
  padding-top :4.5rem !important;
}
.rate-menu-item{position: relative;}
.rate-menu-item svg{
  position: absolute;
  left: 0px  !important;
  height: 400px  !important;
  top: -150px  !important;
  display: none;
}
.animation-rate-order{
  display: none;
  position: absolute;
  left: 0;
  bottom: 0px;
}
.free-food-label { color: black !important;}
.top-nav-order{
  position: fixed;
  z-index: 1000;
  width: 100%;
  background-color: white;
}
#backGroundPopup { 
  width: 100%;
  height: 4000px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
}
/*-------changes----------*/
.login-container.custom-container.register-container{width:100%;}
.closeText { background-color: #eeeeee;}
.closeText:before {content: "OK";}
#btn-eyeball, #btn-eyeball1, #btn-eyeball2 {
  float: right;
  margin-right: 15px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.header-logo {
  margin: 0 .5em .5em 0;
  max-width: 200px;
}
.iziModal-overlay{
  opacity: .75 !important;
  background-color: #000 !important;
}
.close-model-partner{
  float: right;
  margin-right: 15px;
  color: #ce0f02 !important;
  font-weight: 500;
  font-size: 25px;
}
.va-wrapper.register-input{display: table;}
.clearfix.face-goog-btn{width:100%;}
.clearfix.face-goog-btn a{
    display:table-cell;
  	background:none;
  	border: none;
}
.footer-image-submit{
  width: auto !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.footer-image-submit .btn-image-rotate{margin: 10px;}
#body-qr-code{
  padding: 0px;
  background-color: white;
  min-width: 458px;
}
.bg-white{ background-color: white !important;}
.bg-white a{
  color: black !important;
  font-size: 14px !important;
  padding: 0px !important;
}
#content-about-mobile .card-body p {font-size: 14px !important;}
#html-content-holder img { width: 100%;}
.text-skip-rating-one{
  padding-top: 5px;
  font-size: 17px;
  color: #4B4B4B;
  font-family: Lato;
  font-style: normal;
  float: left;
}
.text-skip-rating-two{
  color: #02843D;
  font-weight: bold;
  margin-left: 5px;
  float: left;
}
.backgroundQRCode {
  position: absolute;
  z-index: 1;
}
.logoQRCode{
  z-index: 10;
  position: absolute;
  margin: 30px 115px;
}
.headerQRCode{
  position: absolute;
  z-index: 10;
  color: white;
  text-align: center;
  width: 100%;
}
.headerQRCode h1 {
  color: white;
  margin: 10px 40px;
  font-weight: 500;
  font-size: 2em !important;
}
.headerQRCode h2 {
  color: white;
  margin-top: 113px;
  margin-bottom: 25px;
}
.headerQRCode .centerBlock {
  text-align: left;
  font-size: 13px;
  margin-top: 30px;
}
.contentLeftQRCode{
  float: left;
  color: black;
  font-size: 9px;
  padding: 55px 5px 15px 15px;
}
.scroll-table-free-food{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.qrcode{
  z-index: 100;
  width: 150px;
  float: left;
}
#home-qrcode canvas{
  padding: 5px;
  border-top: 6px solid #000;
  border-right: 6px solid #000;
  border-bottom: 25px solid #000;
  border-left: 6px solid #000;
  border-radius: 10px;
}
#friends-qrcode canvas{
  padding: 8px;
  border-top: 6px solid #000;
  border-right: 6px solid #000;
  border-bottom: 30px solid #000;
  border-left: 6px solid #000;
  border-radius: 10px;
  background: #fff;
}
#home-qrcode span{
  position: absolute;
  bottom: 6%;
  left: 20%;
  color: #fff;
}
#friends-qrcode span{
  position: absolute;
  bottom: 6%;
  left: 24%;
  color: #fff;
}
#friends-qrcode{
  position: absolute;
  left: 30%;
  top: 24%;
}
#saveBtn-0 button, #saveBtn-1 button { min-width: 88px;}
.save-preferences button { min-width: 71px;}
#btn-download-qr-code{
  margin-top: 30px;
  padding: 7px;
}
#modal-all-order-infor .modal-header, #modal-all-my-order .modal-header{
  text-align: center;
  height: 64px;
  border-bottom: none;
}
#modal-all-order-infor .modal-header .modal-title, #modal-all-my-order .modal-header .modal-title{
  position: absolute;
  height: 28px;
  font-family: Lato;
  font-style: italic;
  font-weight: 800;
  font-size: 23px !important;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-left: 132px;
  opacity: 0.8;
}
#modal-all-order-infor .title-body, #modal-all-my-order .title-body{
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.05em;
color: #377D22;
}

#modal-all-order-infor .item-order, #modal-all-my-order .item-order {
  border-bottom: 1px solid  #CCCCCC;
  padding-top: 10px;
  padding-bottom: 8px;
}

#modal-all-order-infor .item-order .item-order-svg ,#modal-all-my-order .item-order .item-order-svg{
  width: 39px;
  float: left;
  height: 39px;
  margin-top: 5px;
  border: 25px;
  background-color: #CCCCCC;
  border-radius: 50%;
}
#modal-all-order-infor .item-order .item-order-svg svg, #modal-all-my-order .item-order .item-order-svg svg {
  margin-top: 6px;
  margin-left: 7px;
}
#expiringCreditNotificationCount {
  width: 80px;
  display: inline-block;
  margin-left: 10px;
  text-align: right;
  margin-right: 10px;
  padding-right: 5px;
  font-weight: bold;
}
.content-email-templates, .footer-email-templates{
  border: 1px solid black;
  padding: 30px;
}
.content-email-templates h3, .footer-email-templates h3{text-align: center;}
#code-emails a.add {color: #27C46B;}
#code-emails a.edit {color: #FFC107;}
#code-emails a.delete { color: #E34724;}
p a {cursor: pointer;}
a#btn-login-facebook:focus{
  border: none;
  box-shadow:none;
}
a.btn.btn-facebook.btn-new-facebook{
  border: none;
  background:none;
}
a.btn.btn-facebook.btn-new-facebook:hover{display:inline-block;}
a.btn.btn-facebook.btn-new-facebook:focus{ box-shadow:none;}
.btn-new-facebook:focus{
  border: none;
  background:none;
}
p.text-center.text-line a {display: contents;}
.box.row.login-box-new {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 50px;
  margin-bottom: 20px;
  background:none;
  border: none;
  width:100%;
}
.box.row.reg-box-new {
  margin-top: 90px;
  width:100%;
  background:none;
  border: none;
  margin-right: 0px;
  margin-left: 0px;
}
#btn-login-google{
  display: flex;
  font-size: 13px;
  background-color: #395AD8;
  color: white;
  border-radius: 6px;
}
#btn-login-google p{
  line-height: 35px;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: 0px;
}
#btn-login-facebook, #reg-btn-login-facebook{
  display: flex;
  font-size: 13px;
  background-color: #3C5499;
  color: white;
  border-radius: 6px;
}
#btn-login-facebook p, #reg-btn-login-facebook p{
  line-height: 35px;
  padding: 0px;
  text-align: center;
  width: 100%;
  margin: 0px;
}
a#btn-login-google:focus{
  border: none;
  box-shadow:none;
}
.login-input:focus{ outline:none!important;}
.create-button-n {
  width:100%;
  float:right;
}
.cust_twitter_btn_font{ font-size:15px!important;}
.cust_spin_color{ color:green}
.forget-right-new {
  float:right;
  width:100%;
}
span#select2-country-container {
    border-radius: 30px;
    background: #fff!important;
}
.select2-container--default .select2-selection--single{
  border-radius:1.25em!important;
  height:30px;
}
li.active.order-opt { width: 50%;}
/*---------resturant page--------*/
.menuitem_rr > .row {
  width: 100%;
  height: 100%;
  margin: 0;
}
.menuitem_rr .row > .col-4 {padding: 0;}
.menuitem_rr .row .col-4 img {
  padding: 0;
  border: none;
  border-radius: 0;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.menuitem_rr .row .col-4 .carousel-item {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.menuitem_rr .row > .col-8 {padding: 10px;}
.menuitem_rr .row .col-8 .rest-menuitem-description { margin-bottom: 0.3rem;}
.menuitem_rr .row .col-8 .bookmarkIcon {
  padding: 0;
  text-align: right;
}
.meet-btn-downarrow {
  color: black;
  font-size: 8px;
  margin-left: 3px;
}
.menuitem_rr .row .col-8 .meet-btn-downarrow {
  padding-left: 5px;
  color: black;
  font-size: 8px;
}
.menuitem_rr .meetflavordiv .col-12 > div {padding: 0;}
.menuitem_rr .meetflavordiv .col-12 .col-7 .meet-btn {
  padding: 5.5px 0;
  width: 100%;
}
.menuitem_rr .meetflavordiv .col-12 .col-4 .meet-btn { margin-left: 7px;}

/*---------freefood page---------*/
button.btn.btn-default.reset-btn.cross-bttn {
    padding: 2px 21px;
    height:32px;
}
button.btn.btn-default.reset-btn.cross-bttn span{font-size:15px;}
p.h2.equal-siqn { padding-left: 10px;}
/*---------freefood page---------*/
button.buttn-copy-ed{
  padding-bottom:0px;
  padding-top:0px;
  height:32px;
  border-bottom-right-radius: 50px !important;
  border-top-right-radius: 50px!important;
}
span.label-text.acc-label-txt {font-size: 1em!important;}
/*--------------My friends page---------------*/
.table-ful-disp{display:table;}
.table-ful-disp a{font-size: 15px;}
/*--------------Past rating---------------*/
.input-group-btn.clipbord-porfil-btn a {
    display: table-cell;
    height:32px;
    padding-top:2px;
    padding-bottom:2px;
    color:#fff;
    font-size:15px;
    border:1px solid #02843D;
    border-radius:50px !important;
}
.input-group-btn.clipbord-porfil-btn a:hover {
  background:#ffffff;
  color:#02843D;
  border:1px solid #02843D;
}
.btn.clipboard-white {color: #ffffff;}
.rating-control-btn ul{
    width:auto;
    border:3px solid #02843D;
    height:48px;
}
span.tab.btn.btn-md.btn-green.influence-input {
    background: #fff;
    border-radius: 5px;
    color:#02843d;
    border:1px solid #02843d;
}
.influence-frnd:checked+.tab {
  background-color:#02843d!important;
  color:#fff!important;
}
a.btn.btn-default.form-control.btn-drop-down.drop-influencer{ color:#fff;}
.friend-influnc {
    margin-left: 0px;
    margin-right: 0px;
}
.form-inline .date-selct{ width:100%;}
#search-filter{
  position: absolute;
  margin-left: 0%;
}
#search_menu_icon{
  cursor:pointer;
  float: right;
  margin-right: 31px !important;
  margin-top: -24px !important;
  position: relative;
  background-color: #FBFBFB;
  z-index: 2;
}
.mblWishlistIcon{padding-top: 15px;}
#filter_menu{
  float: right;
  margin-right: 6px;
  margin-top: -27px !important;
  position: relative;
  color: #666;
  background-color: #fbfbfb;
  z-index: 2;
  border-left: 1px solid rgba(0,0,0,0.25);
  padding-left: 4px;
}

button.buton-green-red{
    border-radius:50px;
    height:40px;
    padding:2px 21px;
}
.select-btn-full{
  width: 100%!important;
  border-radius: 50px;
}
.select-btn-full:focus{ border-radius: 50px;}
.adjust-item-price {
  width:98%!important;
  display: inline-block;
}
button.btn.btn-register.btn-sp-green.btn-primary.change-pass-btn{
  padding-left:21px;
  padding-right:21px;
}
.chng-pass-head h3{ margin-bottom:0px;}
/*--------my orders---------*/
a.btn-order-sm-else{ width: 100%; border-radius: 50px;}
a .btn .btn-order-sm-else :hover{ border-radius: 50px!important;}
.credits-left-side{text-align:right;}
.form-group.row.credit-input-form{
  margin-left:0px;
  margin-right:0px;
}
button.check-buton{
  height:32px;
  padding:2px 21px;
}
.privacy_terms tr th, .privacy_terms tr td{border: 1px solid black; padding: 0.4rem;} 
.friends_locals_influencer{height:64px}
.friends_locals_influencer .rateByFriends{background-image: url('../images/friends-bg.svg');}
.friends_locals_influencer .rateByInfluencer{background-image: url('../images/Influencer-bg.svg');}
.friends_locals_influencer .rateByLocals{background-image: url('../images/locals-bg.svg');}
.friends_locals_influencer_rating{cursor: pointer;width: fit-content; float:right;}
.home-carausal-items_time{color:#4A4A4A; float:right;}
.pac-container-search{border: 1px solid #BDBCBC; border-radius: 0 0 20px 20px;}
.resturant-credt{text-align:right;}
.sortViewMap{padding-right: 60px;}
.header-content {
  padding: 130px 0 196px 0;
}
.rest-header-content {padding: 50px 0 196px 0};
.third-color {color : #ffe20b}
.about-header {
  background: url(../images/about-header.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 485px;
  width: 100%;
}
.familiar-left-tree, .what-left-tree, .helping-left-tree {top: -13%;}
.loyality-section { padding: 110px 0;}
.loyality-plate-left {top: 0;}
.plate-left-two {top: -20%;}
.work-title {
  font-size: 20px;
  line-height: 146%;
}
.offer {padding: 110px 0;}
.introduce-right-vector{right: 0; bottom : 0}
.visitionary-vector {top: 33%;}
.founder-section {padding: 70px 0;}
.nano-title {
  font-size: 36px !important; /*it was important */
  line-height: 150%;
}
.primary-bg {background: #f4f4f4 !important}
.rounded-lg{ border-radius: 3rem !important;}
.img-start {left : 0};
.about-imgs {z-index : 1 !important};
.pos-bottom-right { bottom: 0; right: 0};
.primary-para {
  font-size: 18px;
  line-height: 180%;
  color: #4a4a4a;
}
.primary-btn {
  border-radius: 48px;
  border: none;
  outline: none;
  padding: 18px 60px;
  background-color: #ffe20b;
  transition: 0.5s;
}
.primary-btn:hover { background-color: orange; }
.restaurants-header {
  background: url(../images/restaurants-header.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* position: relative;
  width: 100%;
  height: 100%; */
}
.familiar-left-tree, .what-left-tree, .helping-left-tree {top: -13%;}
.familiar-right-vector {
  right: 0;
  bottom: 0;
  z-index: 1;
}
.card-img-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.primary-title {
  font-weight: 700;
  font-size: 42px !important;
  line-height: 50px;
  color: #4a4a4a;
}
.sub-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #4a4a4a;
}
#about_Partner_Name{
  top: 5px;
  left: 68px;
}
#about_Partner_phonenumber {
  left: 120px;
  top: 5px;
}
#about_email {
  left: 130px;
  top: 5px;
}
.familiar-border {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.work-title {
font-size: 20px;
line-height: 146%;
}
.work-radius { border-radius: 14px;}
.work-img-radius { border-radius: 14px 14px 0 0;}
.touchDivBtn {padding : 0 30px;}
.get-touch-content {
  width: 33%;
}
.get-touch-box {
  border-radius: 10.727px;
  background: #fff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
  height: 100%;
}
.display-4 {
  font-size: 3.5rem !important;
  font-weight: 300;
  line-height: 1.2;
}
.shadow-contact::after {
  content: "";
  background: #e6f3ec;
  width: 100%;
  height: 580px;
  position: absolute;
  transform: rotate(-41.26deg);
  right: -25%;
  top: 50%;
  z-index: 0;
}

.shadow-contact {
  position: relative;
}

@media (max-width: 520px) {
  .credits-left-side{ text-align:left;}
  .descriptiontext{padding-top: 8px !important;}
  .resturant-credt{text-align:left;}
  .nav-rounded.nav-pills .nav-link {font-size: 9px;}
  .nav-rounded { min-height: 37px !important;}
  .modal-dialog{margin:0;}
  .btn-rest-inline { right: 3%;}
  .btn.btn-default{ padding:6px 10px; }
  .item-btm-mrg{height:520px; }
  #popuppartner .close{top:-17%;right:1%;}
  .expert-form-alignment p {text-align:left;}
  .flavor-match .slider.slider-horizontal {width: 210px;}
  .nav.nav-pills .nav-item {
    display:inline-block;
    margin-top: 0px;
  }
  label.pull-right.font-bold.label-pull-left{
    float:left !important;
    margin-top:5px!important;
  }
  #collapseThree.range-high{ padding-left:0px !important;}
  .nav-rounded{
    background:#fff;
    color:#fff;
    border:2px solid #ccc;
    /* box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.1);
    height: 46px;
    border:2px solid #ccc; */
  }
  /* .nav-rounded {
    background: #fff;
    color: #fff;
    /* box-shadow: inset 0 1px 10px rgb(0 0 0 / 10%); */
    /* height: 40px; */
    /* border: 2px solid #ccc;
} */
  .menu-text-font{font-size:12px;}
  .restaurant-ordr-btns li a{ font-size:11px;}
  .restaurant-ordr-btns li{
    padding-left:5px;
    padding-right:5px;
  }
  .restaurant-ordr-btns li a{
    padding-left:0px!important;
    padding-right:0px!important;
  }
  #collapse_your_order{margin-left:0px;}
  #collapse_ordersList .position-rel{padding-left:0px;}
  #cart-items-list .cart-item-details{
      padding-left:0px;
      padding-right:0px;
  }
  #cart-items-list li{
      padding-left:0px;
      padding-right:0px;
  }
  #cart-items-container .order-tree{padding-left:0px; }
  .cart-item-details .order-image-container img{
      width:100px;
      height:100px;
  }
  .cart-item-details .order-image-container{
      padding-right:0px;
      padding-left:0px;
  }
  .cart-item-details .item-price-container{ padding-right:0px;}
  .cart-item-details .item-name-price-container { padding-right:0px;}
  #cart-items-list li .edit-remove-btn-row .flavor-match .sm-text{
    margin-bottom:0px;
    padding-left:0px!important;
  }
  .highest-rated-menu-container {
      padding-right:0px;
      padding-left:0px;
  }
  .highest-rated-menu-panel {
      padding-right:0px;
      padding-left:0px;
  }
  .table-resp {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .rest-menu-filtr-btn .filter-btn-list{
    padding-left: 0px;
    padding-right: 0px;
  }
  .rest-menu-filtr-btn .filter-btn-list li a{
    padding: 8px 10px;
    background: #02843d;
    color: #fff;
    font-size:10px;
  }
  .rest-menu-filtr-btn .filter-btn-list li{
    padding-left:0px;
    padding-right:0px;
  }
  .btn.btn-primary.btn-rectangle{ padding:8px 16px !important;}
  .btn.btn-primary.btn-highest-select{padding:8px 8px !important;}
  #submitItem .expert-review-section .row p{
    text-align:left !important  ;
  }
  #submitItem .expert-review-section .row .icon-checkbox::before{
    font-size:16px;
  }
  #submitItem .expert-review-section .row .na-no-padding{
    padding-left:0px;
    padding-right:0px;
    margin-left: 23px;
  }
  #submitItem .expert-review-section .row span {
    padding-left:0px;
    padding-right:0px;
    text-align:left!important;
  }
  #submitItem .expert-review-section .row .text-quantities {
    font-size: 10px;
  }
  #submitItem .expert-review-section .row .slider-outer {
      max-width: 42% ;
  }
  .drag-label-left{
    float:left !important;
    padding-top:.25rem;
  }
  .sensoryExp-slider-row span{font-size:13px;}
  .sensoryExp-slider-row.text-quantities p{font-size:14px;}
  .expert-form-alignment p { text-align:left; }
  #submitItem .silder-na-part{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 25px;
  }
  #submitItem .responsive-silder{ max-width:41% ; }
  #submitItem .slider-labels{padding-left: 0px;}
  .nav-tabs.nav-justified>li a {padding: 5px 2px !important;}
  button.check-buton {
    height: 32px;
    padding: 2px 8px;
    font-size: 1em !important;
  }
  .override-settings-container .override-switch .toggle { width: 90px !important;}
  .override-settings-container .override-switch .toggle .toggle-group label.toggle-on { width:43px !important; }
  .override-settings-container .override-switch .toggle .toggle-group label.toggle-off { width:55px !important; }
  .override-settings-container h4 .label-text{ font-size:0.8rem !important;}
  .sales-show .btn-font-size { font-size: 0.8rem !important; }
  .table-resp {
    overflow-x: auto;
    display: block;
  }
  .reqst-btn {
    font-size:0.78rem !important;
    padding:6px !important;
  }
  .btn-neverplay{ font-size:16px;}
  .infograph-top{  margin-top:75px;}
  .ratecss{
    background: #218838;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    text-align: center;
    padding: 12px 8px;
  }
  .earnheading{
    font-size: 12px;
    font-weight: normal;
  }
  .earncss{background: #fc8f3f; }
  .faarrow{font-size: 12px;}
  #earn .rateheading{font-size:17px !important;}
  #earn span.main-info-orange{font-size: 20px !important;}
  #discount_flip_div .ratecss{
    background: #218838;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    text-align: center;
    padding: 12px 8px;
  }
  #discount_flip_div .rateheading{font-size: 21px;}
  #discount_flip_div span.main-info-orange{font-size: 28px;}
  #earn .modal-content {padding: 15px 2px !important;}
  #imageCredits .container {
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  #expiringfooddiscount-label, #firsttimeorder-label, #firstmenuitemrating-label, #ratingrestaurant-label, #firstexpertreview-label {
    padding-left :0px;
  }

  #intro .earn-free-food-text-last {
    font-size: 10px !important;
  }

  #intro .earn-free-food-text-head {
    font-size: 24px !important;
  }

  #intro .earn-free-food-total-text {
    font-size: 16px !important;
    color: #02843D !important;
    text-align: center;
  }
  #intro .earn-free-food-image {
    padding: 0 !important;
  }

  #intro .img-arrow-total {
    position: absolute;
    top: -46px;
    right: 5% !important;
    transform: rotate(-15deg);
  }

  #intro .earn-free-food-step {
    width: 18px !important;
    height: 18px !important;
  }

  #intro .earn-free-food-arrow {
    width: 21px !important;
  }
  
}
.saleprsn-label , .restuarant-pref-rate{font-weight:700;}
.btn-cross-red span{font-size:1em;}
.btn-cross-red {
  padding: 5px 21px !important;
  height: 32px !important;
}
button.buton-term-edit{
	padding:2px 21px!important;
	height:32px !important;
}
button.butn-tern-delet{
  padding: 2px 21px;
  height: 32px;
}
button.btn-salesprsn-save {
    padding: 2px 21px!important;
    height: 32px!important;
    font-size: 15px!important;
}
button.saleprsn-cancl-btn{
	  padding: 2px 21px!important;
    height: 32px!important;
    font-size: 15px!important;
}
.table-full-disply thead{ background-color:#02843d;}
.table-full-disply th{padding: .75rem;}
.table-full-disply{display:table;}
.table-ful-disp th{color:#fff;}
.cancel-trm-bttn{height: 32px!important;}

.select2-container{ width:100%!important; z-index: 99999;}
div#howpayDiv1 .select2-container{ z-index: 0!important;}

.home-cuisine-box .select2-container{z-index: 1; position: unset;}
.star-rate-left{justify-content: left;}
.rating-usernm-font{font-size:1rem;}
.item-rating-value-change span{font-size:1rem;}
.item-rating-value-change input{font-size:1rem;}
.skip-btn{padding:6px 21px!important;}

@media screen and (max-width: 768px){
  .head-search-filter i {
    margin-top: 7px;
  }
  #results .food-details{
    padding: 10px 6px !important; 
  }
  .imgcuisine{
    height: 51px !important;
  }
  #intro .input-group {
    border-radius:30px;
    border:1px solid #BDBCBC;
    height:45px; 
    background:#fff;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius:30px !important;
  }
  span#head-auto-complete-divinput{
    border-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius:30px !important;
  }
  .image-lazy-load.go-to-specified-restaurant.carouselflip {
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.30);

    min-height: 370px !important;

    min-height: 430px !important;

    height: auto !important;
  }
  #cusionslider .slick-slide {
    /* background: #F8F8F845; */
    /* border-radius: 68%; */
    /* width: auto !important ;
    height: auto !important; */
    /* margin-right: 0 !important;
    padding: 10px; */
 }
   span.location-containersearch{ width: 60%;}
  .parent-wrapper-container{margin-top:0px !important;}
  .footer-top .footer-links,.footer-top .footer-smm,.footer-top .footer-restaurant { text-align: center !important;}
  #profileForm .img-circle{
    width: 100px !important;
    height: 100px !important;
  }
  #intro .carousel-control-next-icon, #intro .carousel-control-prev-icon {top: 195px;}
  .drop-title {
    float: left;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size:13px;
  }
 .star-rate-left{text-align:center!important;}
 .star-rate-center{justify-content: center;}
 .starrating-heading{
   background-color:#4a4a4a;
   margin-bottom:4px;
 }
 .starrating-heading h3{
   margin:0px;
   padding:6px 0px;
 }
 .white-span-text{color:#fff;}
 .rating-usernm-font{padding-left:20px;}
  .dietary-preferences {font-size:14px !important;}
  #results .results-col .free-price{font-size: 10px}
  #results .food-flavor{  padding: 0px 0px 0px 15px;}
  .restaurant-menu-infor .mobile-menu #menu-search {min-width: unset!important;}
  .craousalitemImage{height: 28vh !important}
  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .home-cust-row-margin { margin-top: -125px;}
  #cusionslider .slick-prev {
    left: 0%!important;
    display: none !important;
  }
  #cusionslider .slick-next {
    right: 0%!important;
    display: none !important;
  }
  #mobile-nav-toggle { display: inline;}
  #nav-menu-container {  display: none;}
  #intro .slider {-webkit-appearance: none;width: 200px;}
  #results .flip2 .card {
    width: 100%;
    transition: 0.5s;
    border:0 !important;
    margin-left:0px;
  }
  #search {
    margin-left: 10 !important;
    width: 230px !important;
  }
  .inputSwitch{
    margin-top: -2px !important;
    text-align: center;
    position: absolute;
    font-size: 16px;
    min-width:696px !important;
  }
  #venueItemsDiv button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info),
  #menuItemsDiv button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info) {
      width: 100px!important;
      padding: 3px 0 !important;
  }
  .privacy_terms{font-size:11px}
  #collapseAR{overflow-x: hidden;padding: 1rem !important;}
  .affiliate_earning{overflow-x: scroll;}
  .add_referrer_main{overflow: scroll;flex-wrap: nowrap !important;justify-content:flex-start !important;}
  .angle-arrow img {width: 2rem;}
  .add_referrer_cards  {width: 80%;margin: 0 2% 10%;}
  .yesPaddingTop label{font-size: 14px;}
  .referrer_input{padding:0px;}
  .affiliate_earning img{width: 280px;}
  input.referrer-email, input#customerReferralPct, input#secondDegreeAffiliate{width: 100% !important;padding-left: 0px;padding-right: 0px;}
  .margin{ margin-top:33px;}
  .cancel-save-responsive { display:inline-block!important;}
  .cancel-save-responsive button { margin-top:10px;}
  .btn-long-lat{ margin-top:10px;}
  .txt-left-mobil{text-align:left!important;}
  .butons-margin-top{ margin-top:10px; }
  .radio-inline-chkbox{ display:inline;}
  .clipbord-porfil-btn{ margin-top:10px;}
  .clipbord-porfil-btn a{ background-color:#02843d;}
  .bton-map-them{font-size:1rem;}
  .check-box-left{float:left;}
  .label-font-bold{ font-weight:700;}
  .contct-email-verify button{ padding: 2px 10px!important; }
  .back-to-top { bottom: 15px;}
  #header-new #head-logo h1 {font-size: 28px; }
  #header-new #head-logo img { max-height: 75px;}
  .static-content { margin-top: 25px !important;}
  .feeling-button {font-size: 0.7rem !important;}
  #mobile-nav-toggle { display: inline;}
  #nav-menu-container {  display: none;}
  #intro h2 {font-size: 24px;}
  .sidenav-bg { padding-top:30px !important;}
  .rangeslider { width: 350px !important;}
  #searchRestaurantForm .slider {
    -webkit-appearance: none;
    width: 60%;
  }
  #results .flip2 .card {
    width: 100%;
    transition: 0.5s;
    border:0 !important;
    margin-left:0px;
  }
  #search {
    margin-left: 0 !important;
    width: 240px !important;
  }
  .inputSwitch{
    margin-top: -2px !important;
    text-align: center;
    position: absolute;
    font-size: 16px;
    min-width:696px !important;
  }
  body {
    margin-bottom: 50px;
    overflow-x: hidden;
  }
  .table-full-disply{
    display:block;
    overflow: auto;
  }
  .resturant_setin-labl {
      padding-left:0px;
      padding-right:0px;
  }
  #nearby_deals #restaraunt_attributes_hours .input-time-child, #nearby_credits #restaraunt_attributes_hours .input-time-child{
      width: 70px;
      display: initial;
  }
  .highest-rated-iprice{ font-size:13px !important;}
  .rating-username{
      margin-top: 0px;
      padding-top:20px;
  }
  .tree-node .rating, .flavor-match, .earn-upto {
    display:block !important;
    margin-left:0px;
    padding-left:15px;
  }
  .group-time-selection{
    padding: 0px;
    text-align: center;
  }
  .group-time-selection .input-time-child {
    width: auto;
    display: initial;
    font-size: 13px;
    height: 30px;
    padding: 3px;
  }
  .group-time-selection .day-label,
  .group-time-selection .day-label{
    height: 30px;
    padding: 2px 0px;
  }
  .fancytree-node .restaurant-menu-item, .item-discount, .servings, .days-order-in-advance {
    font-size: 18px !important;
  }
  .sm-text {font-size: 13px;}
  h1 {font-size: 1.2em !important;}
  .icon-caret-right{ margin-left: -16px;}
  .show_diet_pref_label{
    position:absolute;
    top:60px;
  }
  .show_diet_pref_label .badge {float:right;}
  .goto-item-btn{
    padding:3px !important;
    font-size:10px !important;
    width:42px !important;
  }
  .highest-rated-menu-item { border-top: 1px solid #ddd;}
  .menu-item-name {font-size:1rem !important;}
  .flav-match {
    color: slategray;
    font-size:13px;
  }
  .width-25 {width:100% !important;}
  .width-10 {width: 11% !important;margin-right: 8px;}
  .width-34 {width:44% !important;}
  .flav-display {display:none;}
  .menu-rating-th {
    font-size:12px !important;
    padding-left:7px;
    padding-right:7px;
  }
  .m-list{ display:block; width:59% !important; }
  .col-group {
    display: inline-flex !important;
    float:left;
    width:60%;
    padding-left:5px !important;
    padding-right:5px;
  }
  .tree-node {
    padding-left:0;
    display: block;
    float: left;
  }
  .flavor-match{font-size:1rem; width:100% !important;}
  .earn-upto {
    padding: 0px 8px !important;
    display: -webkit-box;
    margin-left: 7px;
  }
  .col-group .menu-item-price { font-size: 13px !important;}
  .slider { -webkit-appearance: none;width: 123px !important;}
  #header-new {  height: 135px !important;}
  #header-new.header-scrolled {height: 135px !important;}
 .home_order_btns .form-check{margin-right: 0 !important;margin-left: 0 !important;padding-left: 0px !important;}
  .home_order_btns .form-check:not(:first-child){margin-left: 0rem !important;}
  .home_order_btns span{padding-left: 2px !important;padding-right: 2px !important; font-size: 12px !important;}

  #head-auto-complete-div .autocomplete-homepage-wrapper #autocomplete-results {
    top: 42px;
    width: 100%;
    border-radius: 0px 0px 25px 25px !important;
  }
  .addBorderRadInp{border-radius: 20px 0 0 0 !important;}
  .addBorderRadSearchIcon{border-radius: 0 20px 0 0 !important;}
  .home-address-text i{padding: 8px 4px 0 15px !important;}

  .headhome-inner-location-container{
    width: 83% !important;
  }

  .headhome-inner-search-container{
    width: 100% !important;
  }

  #intro-container .intro-search-wrapper {
    padding: 0 20px !important;
  }

}

a.menu-filtr-txt{ font-size:1rem;}
a.requst-resturant-menu-btn{ font-size:1rem;}
.table-menu-items-font th span{
   font-size:1rem;
   font-weight:normal;
}
.rating-item-restu-name span{  font-size:15px;}
.restaurant-ordr-btns{
   	background:none!important;
		border:none;
}
.table-green thead{  color:#fff;}
.modal-header-block{ display:block;}
.week-days-bton{ display: inline-block;}
.adjust-adres-content, .adjust-name-content{ line-height: 20px;}
.adjust-email-content{
  line-height: 20px;
  word-break: break-all;
  margin-top: 0px !important;
}
.fixTransform, .fixTransform:hover{text-transform: none !important;}
a.slect-this-item{ color:#fff!important;}
.item-gallery-top-m{
  bottom:0px;
	top:inherit;
}
.contct-email-verify button{
	padding: 2px 21px!important;
	height: 32px!important;
}
.contct-email-verify a{
  padding: 2px 21px!important;
  height: 32px!important;
 }
/*-------changes-----------*/
.col-centered{ float: none; margin: 0 auto;}
.wrapper {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 190px;
}
.part2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}
.info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#yelp {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.user-pic {text-align: center;}
.user-pic img {width: 100px;height: 100px;}
#price-area {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.material_accordion .material_accordion_panel,
.material_accordion .material_accordion_heading {
  border: none;
  border-radius: 0;
}
.material_accordion .row a {
  text-decoration: none;
  -webkit-transition: color 0.3s ease-out 0s;
  transition: color 0.3s ease-out 0s;
}
.panel-title a {
  text-decoration: none;
  display: block;
  color: #fff;
  padding: 0 15px;;
}
.panel-title a:hover {
  color: #000000; /*black*/
  text-decoration: none;
}
.panel-title a:visited { text-decoration: none;}
.material_accordion .material_accordion_collapse { text-decoration: none;}
.accord {
  background-color: #F1831D;
  font-size: 106%;
  letter-spacing: -0.01em;
  margin: 5px 0;
  padding: 1%;
  text-align: center;
  text-transform: uppercase;
  word-spacing: 0.1em;
}
.panel-body .panel-group{
  padding: 15px;
  border: 1px solid #8ba4b0;
}
.panel-body .panel-default>.panel-heading{
  background-color: #a9a9a9;
  padding: 2px 15px;
}
button.note-btn.btn.btn-default.btn-sm { margin-right: 5px;}
#editor_restaurantstory button { background-color: #02843d !important;}
#editor_restaurantstory .panel-heading {
  background-color: white !important;
  border-bottom: 1px solid #a9a9a9 !important;
  padding-bottom: 7px !important;
}
.table thead{background-color: #02843d;}
#check {left: 5%;}
.days {
  display: flex;
  flex-direction: row;
  padding: 2px;
  width: 100%;
}
.days input {
  margin: 4px;
  width: 30%;
}
.day {
  width: 3rem;
  padding-top: 0.5rem;
  text-align: center;
  margin-top: 0.3rem;
  margin-right: 5%;
  border: black;
  border-width: solid 5px;
  box-shadow: 0 0 10px black;
}
.day:hover {cursor: pointer;}
.datepicker .day {  box-shadow: none !important;}
.highlight {
  background: #149bdf;
  color: white;
}
#dates {
  width: 100%;
  display: flex;
  flex-direction: row;
}
#dates div { padding: 3px;margin: 10px;}
.checkbox {left: 2%;}
#last-div {margin-bottom: 2.3%;
  background-color: transparent;
}
#ratings-line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #5d9d36;
  margin: 1rem 0;
  padding: 0;
}
/*temporary fix*/
#tempFood {
  display: flex;
  width: 350px;
  height: 300px;
}
#review-count {
  padding-top: 1.5em;
  font-size: 12px;
}
#noise { width: 12%;}
#portion {margin-left: 11%;}
#payments { margin-bottom: 0.8%;}
#empty_col {float:left;width: 32%;}
#food_choices {
  float: left;
  width: 32%;
  font-size: 15px;
  font-color: black;
}
#menu-split-button {
  float: left;
  width: 32%;
  font-size: 15px;
}
#addRestDetHours {
  float: left;
  width: 30%;
  font-size: 15px;
}
#addRestDetStartTime {float: left;font-size: 15px;}
#addRestDetEndTime {
  float: left;
  width: 30%;
  font-size: 15px;
}
#addRestDetEdit {
  float: left;
  width: 10%;
  font-size: 15px;
}
span.review-author {
  position: relative;
  bottom: 21px;
  left: 80px;
}
img.detailed-review {height: 50%;width: 100%;}
.zipcode-value-select { color:#000;}
.flitercss{display: none !important;}
.foundPref input[type="checkbox"],
#collapseSalesCRM input[type="checkbox"],
#collapsePrivateRooms input[type="checkbox"],
#collapseOne input[type="checkbox"],
#collapseRp input[type="checkbox"],
#collapseTa input[type="checkbox"]{
  position: relative;
  right: inherit;
  width: 0;
  height: 0;
  visibility: hidden;
}
.natext {
  padding-top: 10px;
  font-size: 12px !important;
}
.preference-items input[type="checkbox"]+span.icon-checkbox:before,
.natext input[type="checkbox"]+span.icon-checkbox:before {
  content: "\f096";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  color: #02843d;
  cursor: pointer;
  position: absolute;
  font-size: 1.5rem;
  left: 0px;
  top: 0px;
}
.NDP input[type="checkbox"] {
  display: none;
}
.NDP input[type="checkbox"]+span.icon-checkbox:before {
  content: "\f096";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.6rem;
  margin-right: 5px;
}
.NDP input[type="checkbox"]:checked+span.icon-checkbox:before {
  content: "\f14a";
  color: #fff;
  -webkit-animation: effect 250ms ease-in;
  animation: effect 250ms ease-in;
}
.natext input[type="checkbox"]+span.icon-checkbox:before {
  left: -10px !important;
}
.preference-items input[type="checkbox"]:checked+span.icon-checkbox:before,
.natext input[type="checkbox"]:checked+span.icon-checkbox:before {
  content: "\f14a";
  color: #02843d;
  -webkit-animation: effect 250ms ease-in;
  animation: effect 250ms ease-in;
}
.preference-items input[type="checkbox"]:disabled+span.icon-checkbox { color: #aaa;}
.preference-items input[type="checkbox"]:disabled+span.icon-checkbox:before { color: #ccc;cursor: no-drop;}
.foundPref input[type="checkbox"]{
  position: inherit;
  right: inherit;
  visibility: hidden;
  display: none;
}
.foundPref input[type="checkbox"] + .label-text:before, #collapseSalesCRM input[type="checkbox"]+ .label-text:before, #collapsePrivateRooms input[type="checkbox"]+ .label-text:before, #collapseOne input[type="checkbox"]+ .label-text:before{
  margin-right: 5px;
  margin-left: 5px;
  width: inherit;
}
.foundPref input[type="checkbox"]:disabled+.label-text:before{ color: #aaa;}
a[data-toggle="collapse"].title-handle, a[data-toggle="collapse"].title-handle:hover, a[data-toggle="collapse"].title-handle:focus, a[data-toggle="collapse"].title-handle:active{
  font-size: 1.2rem;
  text-decoration-line: none;
  color: #02843d;
}
a[data-toggle="collapse"].title-handle > span:first-child{font-size: 1.2rem;}
a[data-toggle="collapse"].title-handle:before{
   content: "\f068";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  color:#02843d;
}

a[data-toggle="collapse"].title-handle.collapsed:before{content: "\f067";}
.results-wrapper .results-content .item-image-container .full-image-container{
  position: relative;
  height: 34px;
  margin-top: -34px;
  display: none;
}
.results-wrapper .results-content .item-image-container:hover .full-image-container, .results-wrapper .results-content .item-image-container:focus .full-image-container{ display: block;}
.results-wrapper .results-content .item-image-container .full-image-container > button{
  border-radius: 0;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  font-weight: bold;
}
.full-screen{
  width: 100vw !important;
  height: 100vh !important;
  background-color: rgba(68, 68, 68, 0.52);
}
.image-container-full{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  text-align: center;
  overflow: auto;
}
.image-container-full button.close-view{
  background-color: red;
  color: #fff;
  display: block;
  margin: auto;
  min-width: 80px;
}
#yourStoryContainer .restaurant-story-name{
  text-align: center;
  border: 1px solid #ccc;
  box-shadow: 3px 8px 9px #ccc;
}
#yourStoryContainer .restaurant-story-name > h3{
  margin-top: 20px;
  margin-bottom: 20px;
}
#yourStoryContainer .restaurant-story-content, #yourStoryContainer .restaurant-story-name{
  min-height: 30px;
  border: 1px dashed #ddd;
}
#collapseOurStory .restaurant-story-name > div{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  border: 1px solid #ccc;
}
.dotted-border {
   border-style: dashed;
   border-radius: 5px;
   border-color: #3c763d;
}
.nav-tabs.nav-justified > li{
  /* border: 1px solid #ccc; */
	 border-radius: 50px;
   margin-bottom: 5px;
}
.nav-tabs.nav-justified > li a{
  border: none;
  background-color: #fff;
  color: #4A4A4A;
  border-radius:5px;
  padding:8px 50px;
  border:1px solid #02843d;
	display:block;
}
.nav-tabs.nav-justified > li > a.active {
  background-color: #02843d;
  color: #fff;
	border:1px solid #02843d;
}
.nav-tabs.nav-justified > li > a.active:hover{color: #fff;}
.nav-tabs.nav-justified > li > a:hover { color: #02843d;}
.nav-tabs.nav-justified > li.active > a,
.nav-tabs.nav-justified > li.active > a:focus,
.nav-tabs.nav-justified > li.active > a:active{
  background-color: #02843d;
  color: #fff;
	border:1px solid #02843d;
}
.nav-tabs.nav-justified > li.active > a{
	background-color: #02843d;
  color: #fff;
}
#collapseOne label.checkbox-container {  font-weight: normal;}
input[type="checkbox"]:focus + .label-text, input[type="radio"]:focus + .label-text{
  box-shadow: inset 0 1px 1px transparent, 0 0 6px #02843d;
}
/* Switch button */
.btn-default.btn-on.active{background-color: #02843d;color: white;}
.btn-default.btn-off.active{background-color: #02843d;color: white;}
.decision-maker-container .grp-select select.form-control{
  width: auto;
  display: initial;
}
.decision-maker-container .grp-input > p > label{
  width: 50px;
  text-align: center;
  border: 1px solid #ccc;
  height: 34px;
  padding: 6px 12px;
  margin-top: initial;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
}
label.weekday-btn {
  width: 40px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 3px 6px;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
}
label.weekday-btn.selected { background-color: #02843d; color: #fff;}
.pac-container { z-index: 999999 !important;}
#restaraunt_attributes_hours .grp-input>p{text-align: center;}
#restaraunt_attributes_hours .grp-input>p.valid>label {
  background-color: #02843d;
  color: #fff;
}
.decision-maker-container .grp-input > p > *:not(:last-child){ margin-right: 15px;}
.decision-maker-container .decision-maker-logic{ margin-left: 95px;}
.decision-maker-container .grp-input select.form-control{
  width: auto;
  display: initial;
  max-width: 100px;
  background-color: #f5a623;
  -webkit-appearance: button;
  color: #fff;
}
.loyaltyProgress {
  position: absolute;
  width: 100%;
  height: 32px;
  background: #F4F4F4;
  border: 0.5px solid #E3E3E3;
  border-radius: 11px;
}
.loyaltyBar {
  position: absolute;
  width: 203px;
  height: 32px;
  background: #C8E2D4;
  border-radius: 11px 0px 0px 11px;
  color: #02843D;
  line-height: 32px;
  padding-left: 10px;
}
.loyaltyImg {
  height: 50px;
  box-sizing: border-box;
}
.loyaltyText{
  width: 100%;
  position: absolute;
  color: #02843d;
  line-height: 32px;
  text-align: center;
  padding-left: 10px;
}
.loyalty-credit-terms {font-size: 13px;}
#restaraunt_attributes_hours .grp-input>p>*:not(:last-child) { margin-right: 15px;}
#restaraunt_attributes_hours .tab-content #instore, #restaraunt_attributes_hours .tab-content #pickup, #restaraunt_attributes_hours .tab-content #delivery{  margin-top: 12px;}
.restaraunt-attributes .grp-btn-ctrl button{float: right;}
#restaraunt_attributes_hours .grp-input select.form-control[disabled]{  background-color: #d4a04a;}
.multi-factor-sorting button.selected, .res-menu-multi-factor-sorting button.selected{
  background-color: #02843d !important;
  color: white !important;
}
.res-menu-multi-factor-sorting{
  margin-top: 14px;
  margin-bottom: 14px;
  background: #02843d !important;
  border-radius: 4px;
}
textarea.noresize {resize: none;}
textarea.vresize {resize: vertical;}
textarea.hresize { resize: horizontal;}
#form_edit_referrerId .input-group .input-group-btn > button:not(:first-child){
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.results-search-container .btn-my-style{
  border: 2px dashed #5cb85c;
  background-color: #ffe0007d;
  color: #000;
}
.results-search-container .btn-my-style:hover, .results-search-container .btn-my-style:focus{
  border: 2px dashed #5cb85c !important;
  background-color: #ffe000b5 !important;
  color: #000;
}
#form-update-restaurant label{ margin-top: 0;}
#form-update-restaurant input.error{
  border-color: #ff6537;
  box-shadow: inset 0 1px 1px #959d99, 0 0 6px #ff5959;
}
#form-update-restaurant input[type="checkbox"]{
  left: 14px;
  height: 20px;
  position: absolute;
  visibility: visible;
  width: 20px;
  z-index: -1;
}
#form-update-restaurant input[type="checkbox"]:focus + .label-text:before{
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}
#form-update-restaurant .label-text, #listMovingRestaurant .label-text{font-size: 16px!important;}
#form-update-restaurant .label-text:before, #listMovingRestaurant .label-text:before{ font-size: 2rem !important;}
#collapseMyOrder .nav-justified>li>a{ margin-bottom: 0;}
#collapseMyOrder .nav-justified>li.disabled{ background-color: #ddd;}
#collapseMyOrder .nav-justified>li.disabled>a{color: #777;}
#collapseMyOrder #cart-items-totals .right-align{
  float: right;
  font-size: 16px;
  margin-left: 30px;
}
#collapseMyOrder .myorders-nav > li { position: relative;}
#collapseMyOrder .myorders-nav > li > a {
  padding: 10px 50px;
  display: block;
  width: 100%;
}
#collapseMyOrder .myorders-nav > li.active::after,
#collapseMyOrder .myorders-nav > li > a.active::after {
  color: green;
  content: '\f00c';
  font-family: 'FontAwesome';
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  right: 50px;
  top: 5px;
}
.cart-options--icon > i {font-size: 1.6rem;}
#cart-items-totals h2 {
  font-weight: 600;
  color: black;
}
#cart-items-totals .order-total-line {
  font-weight: 600;
  color: dimgrey;
}
.order-checkout-totals {
  display:flex;
  justify-content: flex-end;
}
.order-checkout-totals .price-value {
  float: right;
  font-size: 16px;
  margin-left: 30px;
}
.order-type li.active.disabled a { color: white !important;}
span.font-lg { font-size: 16px;}
#go_to_my_order{
  position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 999;
  padding: 6px 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 5px 18px #a2a2a2;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  opacity: .9;
}
#go_to_my_order, #go_to_my_order:focus, #go_to_my_order:hover{
  outline: none;
}
#go_to_my_order:focus, #go_to_my_order:hover{ opacity: 1;}
#go_to_my_order>.fa{
  color:  #52b370;
  font-size: 25px;
}
#add_to_order{
  position: fixed;
  bottom: 50px;
  z-index: 999;
  padding: 6px 10px;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  opacity: .9;
}
.btn-add-cart{ box-shadow: 2px 5px 18px #a2a2a2;}
#add_to_order, #add_to_order:focus, #add_to_order:hover{outline: none;}
#add_to_order:focus, #add_to_order:hover{opacity: 1;}
#add_to_order>.fa{color: white; font-size: 25px;}
#go_to_rate_items{
  position: fixed;
  bottom: 50px;
  z-index: 999;
  padding: 6px 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 5px 18px #a2a2a2;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  opacity: .9;
}
#go_to_rate_items, #go_to_rate_items:focus, #go_to_rate_items:hover{
  outline: none;
}
#go_to_rate_items:focus, #go_to_rate_items:hover{ opacity: 1;}
#go_to_rate_items>.fa{
  color:  white;
  font-size: 25px;
}
@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0);  }
  20%, 80% {  transform: translate3d(2px, 0, 0);  }
  30%, 50%, 70% {transform: translate3d(-4px, 0, 0);}
  40%, 60% {transform: translate3d(4px, 0, 0);}
}
.decision-maker-container .select2{
  width: auto !important;
  min-width: 80px;
}
.decision-maker-container .select2 .select2-selection{
  height: 34px;
  padding-top: 2px;
}
.link-defined{display: block;font-size: 11px;}
.btn-danger{
  color: #fff;
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
}
.select2-dropdown .select2-no-results-matched{
  padding-right: 4px;
  font-size: 16px;
}
.select2-dropdown .select2-text span:first-child, .select2-dropdown .select2-text .s2-label{
  font-size: 16px;
}
.select2-dropdown .select2-text > .fa{
  color: #4CAF50;
  margin-right: 4px;
}
.select2-dropdown .select2-text > em{font-size: 12px;}
.block-color{
  width: 20px;
  height: 20px;
  display: inline-block;
}
.block-color:not(:last-child){ margin-right: 3px;}
.salesperson-agreement-content .panel .panel-heading { padding-left: 10px;}
/* for time selection in user notifications*/
#nearby_deals #restaraunt_attributes_hours .input-time-child, #nearby_credits #restaraunt_attributes_hours .input-time-child{
    width: auto;
    display: initial;
}
#nearby_deals #restaraunt_attributes_hours .grp-input>p>label, #nearby_credits #restaraunt_attributes_hours .grp-input>p>label {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    height: 34px;
    padding: 6px 12px;
    margin-top: initial;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
}
.pd-0 {padding: 0px !important;}
.pd-l-0 {padding-left: 0px !important;}
.pd-r-0 { padding-right: 0px !important;}
.input-tab {display: none;}
.input-tab+ .tab {
  width: 100%;
  height: 40px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  color: #000;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 1px;
}
.input-tab:checked+.tab {
  background-color: #02843d;
  color: #fff;
  font-weight: bold;
}
.dropdown {
  background-color: #f5f5f5;
  border-radius: 1px 1px 1px 1px;
}
.box-drop-down {
  position: absolute;
  z-index: 9999999;
  overflow-y: scroll;
  max-height: 300px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-top: 1px;
  width: 98%;
}
.box-drop-down::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}
.box-drop-down::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
.box-drop-down:-webkit-scrollbar-thumb {background-color: #000000;}
.drop-down{
  display: inline-block;
  width: 100%;
}
.drop-down .divider {
  height: 1px;
  margin-bottom: 5px;
  overflow: hidden;
  background-color: #e5e5e5;
}
.list-option{
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 0px 15px;
  background-color: #fff;
}
.list-option .preference-items{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 0px;
  width: 100%;
}
.list-option:hover{background-color: #5897fb;}
.list-option.active{background-color: #ddd}
.list-option::has(input:checked) { background-color: #ddd;}
.list-option .defautl{ font-weight: 600;}
.drop-title {
  float: left;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size:15px;
}
.select2-selection {
  border: 1px solid rgba(0,0,0,0.1) !important;
  background-color: #f5f5f5 !important;
  border-radius: 1px;
}
.select2-selection:disabled {
  border: 1px solid rgba(0,0,0,0.1) !important;
  background-color: #d8d8d8 !important;
  border-radius: 1px;
}
.about-us-left ul li {
  padding: 5px 10px;
  font-size: 18px;
  color: #505050;
  border-radius: 5px;
  margin-bottom: 5px;
}
.about-us-left ul li a{ color: #505050;}
li.link-about-us.active-un a { color: #007625 !important;}
li.link-about-us.active-un {
  background-color: #e6f3ec !important;
  color: #007625 !important;
}
.title-us h2{color: #007625 !important;}
.about-us-p {
  line-height: 1.5;
  font-size: 16px;
  color: black;
}
.btn-search-reviews {
  padding: 5px 10px;
  background-color: #02843d;
  color: white;
  border: 1px solid #02843d;
  border-radius: 2px;
}
.item-content {
  display: inline-block;
  width: 100%
}
.review-tab .select2-container--default
.select2-selection--multiple
.select2-selection__choice {
  color: white !important;
  background-color: #02843d !important;
}
.review-tab .select2-selection__choice__remove {color: red !important;}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  color: #fff !important;
  background-color: #02843d !important;
  border-color: #02843d !important;
}
.pagination>li>a, .pagination>li>span { color: #02843d;}
.slate-gray {color: slategray;}
.center-col {
  margin:0 auto;
  float: none;
}
.search-term-highlight {background-color: yellow;}
.btn-rate-green{
  color : white;
  background-color: #0B843E;
  border-color: #0B843E;
}
.bg-text-venue{
  background-image: url('/images/bg-text-rate.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover;
}
.rate-only-mobo-banner{
  background-image: url('/images/only-rate-mobo-banner.png') !important;
  height: 100% !important;
  max-height: 153px !important;
  box-shadow: none !important;
  display: inherit !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.rating-menuitem-banner{
  background-image: url('/images/rate_menuitem_banner.png') !important;
  height: 172px !important;
  max-height: 175px !important;
  box-shadow: none !important;
  display: inherit !important;
}
.rating-menuitem-banner p{
  font-family: Lato;
  font-style: normal;
  color: #4A4A4A;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}
.btn-rate-green:hover{color: white !important;}
.btn-rate-white{  background-color: #fff !important;}
/* Action delete reiview */
.title-review {
  width: 100%;
  position: relative;
  margin: 15px 0px;
}
.title-review .btn-delete-rivew {
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
}
.order-item-rating-accordion {
  background-color: #02843d;
  padding: 0.5%;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #666666;
  font-family: 'Lato', sans-serif;
  margin: 0;
  width: 100%;
  padding-right: 0px !important;
}
a {color: #02843D;}
a:hover, a:active, a:focus {
  color: #02843D;
  outline: none;
  text-decoration: none;
}
p {padding: 0;}
/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: #02843D;
  color: #fff;
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}
.back-to-top i {padding-top: 12px; color: #fff;}
#header {
  padding: 6px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  border-bottom:8px solid #02843D;
  background: #fff;
}
#header.header-scrolled {
  background: #fff;
  padding: 6px 0;
  transition: all 0.5s;
  border-bottom:8px solid #02843D;
}
#header #head-logo { float: left;}
#header #head-logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
}
#header #head-logo h1 a, #header #head-logo h1 a:hover {
  color: #fff;
  padding-left: 10px;
  border-left: 4px solid #02843D;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #fff;
  padding: 0 0 30px 0;
  color: #707070;
  font-size: 13px;
  z-index: 999;
  position: static
}
/*-------------------------------------------header new-------------------------------------*/
#header-new {
  padding: 6px 0;
  height: 75px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  background: #fff;
}
#header-new.header-scrolled {
  background: #fff;
  padding: 6px 0;
  height: 75px;
  transition: all 0.5s;
}
#header-new #head-logo { float: left;}
#header-new #head-logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
}
#header-new #head-logo h1 a, #header-new #head-logo h1 a:hover {
  color: #fff;
  padding-left: 10px;
  border-left: 4px solid #02843D;
}
#header-new #head-logo img {
  padding: 0;
  margin-top: 4px;
  width:190px;
}
.why-pr {width:73%;}
/*------------------------------------------------------------------------footer-new*/
#footer-new {
  background: #F2F2F2;
  padding: 0;
  color: #4A4A4A;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
  z-index: 999;
}
#footer-new.dark {
  color: #fff;
  background: #231f20;
}
#footer-new .footer-top {background: #F2F2F2;}
#footer-new.dark .footer-top { background: #231F20;}
h3.cookies-font {  margin: 0 0 10px;}
#footer-new .well-well-lg {height: 13%;}
#footer-new .cookie-ok-btn {top:7px;}
#footer-new { z-index: 99 !important; }
#footer-new .footer-top .footer-info {
  margin-bottom: 30px;
  text-align:left;
}
#footer-new .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid #02843D;
}
#footer-new .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  color: #707070;
}
#footer-new .social-links-mobile,
.footer-top .social-links {
  display: inline-flex;
}
#footer-new .social-links-mobile a,
#footer-new .footer-top .social-links a {
  display: flex;
  background: #4A4A4A;
  line-height: 1;
  height: 30px;
  width: 30px;
  margin-right: 6px;
  border-radius: 50%;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
}
#footer-new.dark .social-links-mobile a,
#footer-new.dark .footer-top .social-links a {
  background-color: #fff;
}
#footer-new .social-links-mobile a:hover,
#footer-new .footer-top .social-links a:hover { color: #707070;}
#footer-new .footer-top h4 {
  font-size: 15px;
  font-weight: bold;
  color: #4A4A4A;
  position: relative;
  margin-bottom: 10px;
}
#footer-new.dark .footer-top h4 {color: #fff;}
#footer-new .footer-top h4::before, #footer .footer-top h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
#foofooter-newter .footer-top h4::before { right: 0;}
#footer-new .footer-top h4::after {width: 60px;}
#footer-new .footer-top .footer-links { text-align:left;}
#footer-new .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer-new .footer-top .footer-links ul i {
  padding-right: 8px;
  color: #ddd;
}

#footer-new .footer-top .footer-links ul li { padding-top: 5px;}
#footer-new .footer-top .footer-links ul li:first-child { padding-top: 0;}
#footer-new .footer-top .footer-links ul a:hover { color: #02843D;}
#footer-new .footer-top .footer-smm {text-align:left;}
#footer-new .footer-top .footer-smm p {
  line-height: 26px;
  margin-bottom: 10px;
}
#footer-new .footer-top .footer-restaurant {text-align:left;}
#footer-new .footer-top .footer-restaurant input[type="email"] {
  border: 0;
  padding: 6px 8px;
}
#footer-new .footer-top .footer-restaurant input[type="submit"] {
  background: #02843D;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}

#footer-new .footer-top .footer-restaurant input[type="submit"]:hover { background: #13a456;}
#footer-new .copyright { text-align: center;}
#footer-new .bottom-links {
  text-align: center;
  font-size: 13px;
  color: #02843D;
  font-weight:bold;
  display:-webkit-inline-box;
	padding:5px;
}
#footer-new .text-center {text-align: center;}
#footer-new .text-center {text-align: center;}
#footer-new .text-center ul li {
	padding:5px;
	display:unset;
}
#footer-new .footer-links-about > li > a {color: #4A4A4A;}
#footer-new.dark .footer-links-about > li > a { color: #fff !important;}
#footer-new .social-links-mobile i.fab,
#footer-new .social-links i.fab {
  color: #fff;
  font-size: 20px;
}
#footer-new.dark .social-links-mobile i.fab,
#footer-new.dark .social-links i.fab {
  color: #231f20;
}
.subscribe-form-footer {display: inline-flex;}
.subscribe-form-footer--email {
  flex: 1;
  padding: 10px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 0;
}
.subscribe-form-footer--submit {
  border-radius: 0 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background-color: #4a4a4a !important;
  border-color: #4a4a4a !important;
}
.subscribe-form-footer--submit:hover {
  background-color: #02843d !important;
  color: #fff !important;
}
.conversion-links {
  display: none;
  margin-top: 20px;
}
.conversion-links > a {
  background-color: #02843D !important;
  display: inline-block;
  padding: 3px;
  border-radius: 5px;
}
.conversion-links > a:not(:first-child) {margin-left: 5px;}
.conversion-links > a > img {  width: 100px !important;}
.copyright-wrap > .more-links { margin: 0;}
.more-links > li {margin-left: 20px;}
.more-links > li > a { color: #4A4A4A;}
#footer-new.dark .more-links > li > a {color: #fff;}
.footer-top .footer-links,
.footer-top .footer-smm,
.footer-top .footer-restaurant {
  margin-top: 9%;
}
.footer-bottom-divider {
  margin: 20px 0 50px;
  border-bottom: 0.2px solid #4a4a4a;
  border-bottom-style: dashed;
  opacity: 0.3;
  height: 1px;
  width: 75%;
}
#footer-new.dark .footer-bottom-divider { border-bottom-color: #fff;}

@media (max-width: 575px) {
   .free-food-card--credit {font-size: 1.2rem !important; }
  .ord-hist-left {margin-left:2rem !important;}
  .ord-hist-left-sub {margin-left:5rem !important;}
  .menu_item_title_fonts {
    font-weight: 500 !important;
    font-size: 1.1rem !important;
  }
  .copyright-wrap {flex-direction: column !important;}
  .more-links>li {
    margin-left: 0;
    padding: 0 8px;
  }
  #footer-new { padding-bottom: 40px;}
  .primary-btn {padding: 10px 24px;}
  .primary-title, .nano-title {font-size: 22px !important; line-height: 25px;}
  .familiar-left-tree, .what-left-tree, .helping-left-tree { top: 0; }
  .loyality-plate-left {left: -19% !important;}
  .plate-left-two {left: -35% !important; top: -10%;}
  .introduce-right-vector {bottom: -14% !important; right: -22% !important;}
  .offer-right-vector {bottom: -18% !important; right: -27% !important;}
  .aims-right-vector {top: 20%; right: -60% !important;}
  .aims-right-vector img {width: 80%;}
  .founder-plate-left {top: 22% !important;left: -20% !important;}
  .founder-plate-left img { width: 65%;}
  .about-vector {right: -28% !important; bottom: -15% !important;}
  .work-right-vector {right: -30% !important;bottom: 70px !important}
  .offer-left-tree {top: 14%;}
  .founder-section { padding: 58px 0;}
  .visitionary-vector {top: 90%;}
  .diners-vector {top: 8%; right: -90% !important;}
  .diners-left-two {top: -13%; }
  .rest-header-content { padding: 70px 0 196px 0;} 
  .heading-restaurants {font-size: 2.4rem !important;}
  .touchDivBtn{padding: 0 10px;}
  .getintouchbtn {width : 100%}
  .get-touch-width {width: 100% !important;}
  .header-font {font-size: 20px !important;}
  .primary-para {font-size: 16px;}
  .primary-btn, .outline-btn {padding: 10px 24px;}
  .getInTouchDiv{position: static; z-index: 3;}
  .about-header {
    background: url(../images/about-header-mobile.png);
    height: 445px;
    }
  .restaurants-header {
    background: url(../images/restaurants-header-mobile.png);
    height : 584px;
   }
   .offer {padding-left: 3px; padding-right:3px;}
   .offer-mobile {padding-top : 3rem}
}
/*---------------------------------------------------------------------------------------------*/
/* Call To Action Section
--------------------------------*/
#call-to-action-new {
  background:#4A4A4A;
  padding-top: 80px;
  padding-bottom: 80px;
}
#call-to-action-new h4 {
  color: #000;
  font-size: 1.3rem;
  font-weight: normal;
}
#call-to-action-new p {
  color: #000;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
}
#call-to-action-new .cta-btn {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#call-to-action-new .cta-btn:hover {
  background: #02843D;
  border: 2px solid #02843D;
}
.subscribe-form {position: relative;}
.subscribe-form--wrapper {
  background-color: #ffffffdd;
  padding: 0 4px 0 0;
  border-radius: 60px;
  border: 1px solid #02843D;
}
.subcribe-form--email {
  border: 0 !important;
  width: 350px;
  color:#BDC6C1;
  padding: 12px 0 12px 14px;
  background-color: transparent;
}
.subcribe-form--email:focus { border: 0 !important;}
.subcribe-form--submit {
    background: #02843D;
    border: 1px solid #fff;
    /* width: 30%; */
    padding: 6px 19px;
    text-align: center;
    color: #fff;
    transition: 0.3s;
    cursor: pointer;
    height: 40px;
    border-radius: 60px;
    margin-left: -133px;
}
.subscribe-form--submit:hover {
    color: #02843D;
    background-color: #fff;
    border-color: #02843D;
    border: 1px solid #02843D;
}
.subscribe-form--next {
  position: absolute;
  right: 10px;
  padding: 14px 20px;
  transition: 0.3s;
  cursor: pointer;
  top: 0;
}
.subscribe-form--next a {color: #000;}
.step-order{max-width: 1000px;}
.step-order ul li{
 float: left;
 max-width: 160px;
 text-align: center;
}
.step-order .step-left{
  float: left;
  font-weight: bold;
  width: 50%;
}
.step-order .step-left h3{
  font-weight: bold;
  color : black;
}
.step-order .step-left img{width: 54px;}
.step-order .step-right{
  float: left;
  width: 50%;
  padding-top: 25%;
}
.step-order .step-right span{
  font-weight: 700;
  color: #02843d;
  font-size: 20px;
}
.watch-video h3 {
 font-weight: bold;
 color: black;
}
.watch-video .video-left{float: left;}
.watch-video .video-left img{width:100%}
.video-right{
  float: left;
  text-align: center;
  padding: 0px;
}
#food-explorer img{
  width: 100%;
  margin-top: 50px;
}
#food-explorer .explorer-left{
  padding-left: 100px;
  margin-top: 50px;
}
#food-explorer .explorer-left h2 ,#food-explorer .explorer-left h3{
  margin: 0 0 10px 0;
  color: black;
  font-weight: bold;
}
#food-explorer a{
  z-index: 20;
  bottom: 3px;
  position: absolute;
  right: 30px;
  bottom: 20px;
  padding: 5px;
  background-color: white;
  color: black;
  border-radius: 4px;
}
#special-point{margin-top: 40px;}
#special-point .centerBlock{text-align: center;}
#special-point .centerBlock img{width: 150px;}
#special-point h4{
  margin-top: 10px;
  color: black;
  font-weight: 600;
}
#special-point p{
  /* margin-top: 30px; */
  color: black;
  font-size: 14px;
  font-weight: 600;
}
#pagination-footer-reasons{
  background-image: linear-gradient(#084b6f, #05334b);
  min-height: 100px;
  text-align: center;
  padding: 50px;
}
#pagination-footer-reasons h2{color: white;}
#pagination-footer-reasons p{
  color: white;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}
#footer-reasons ul li{
  background-color: white;
  border-radius: 5px;
  margin: 20px;
  padding: 20px;
  float: left;
}
#link-pagination .active{ text-decoration: underline;}
.left-flavor-profiles , .left-flavor-profiles, .left-page-3, .right-page-3
{float: left;}
.left-flavor-profiles img, .left-page-3 img , .right-page-3 img
{
  width: 100%;
  margin-bottom: 18px;
}
.left-flavor-profiles p{font-size: 14px;  color: black;}
.right-page-3 p , .left-page-3 p{
  font-size: 12px;
  color: black;
  margin-bottom: 10px;
}
.left-page-3 h3,.right-page-3 h3{
  font-weight: bold;
  color: black;
}
.signup-sidenav{
  line-height: 20px;
  color: #fff;
  background-color: #02843d;
  border-radius: 4px;
  font-weight: bold;

}
.login-sidenav{color: black;}
.left-page-3 ul,.right-page-3 ul{
  list-style: unset;
  margin-left: 30px;
  color: black;
  font-size: 12px !important;
}
.left-flavor-profiles h3{font-weight: 600;color: black;}
#link-pagination a{
  margin-left: 10px;
  color: white !important;
}
#footer-reasons{
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
}
#footer-reasons ul li:after {clear: both;}
.item-footer-reasons {
  width: 140px;
  height: 140px;
}
.item-footer-reasons span{color : green;}
.item-footer-reasons p{
  margin-bottom: 10px;
  margin-top: 10px;
  color: black !important;
  min-height: 48px;
}
.pagination-learn-more{
  margin-left: auto;
  margin-right: auto;
  width: 280px;
}
.pagination-learn-more .page-item a{
  border: none !important;
  color: black !important;
}
.pagination-learn-more .activecustom{
  font-weight: bold;
  text-decoration: underline;
}
#feedback-reasons{ margin-top: 30px;}
.header-feedback,.header-feedback-item{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
#feedback-reasons h1{
  font-size: 40px;
  margin-left: 20px;
  color: black;
}
.header-feedback-item img{
  width: 60px;
  height: 63px;
  margin-top: 30px;
}
.header-feedback-item p{
  padding: 30px 30px 30px 30px;
  color: black;
}
.header-feedback img{
  width: 109px;
  height: 111px;
}
.float-action-buttons {  bottom: 14% !important; z-index: 998 !important;}
h3.cookies-font { margin: 0 0 10px;}
#footer .well-well-lg{height: 13%;}
#footer .cookie-ok-btn{top:7px;}
#footer{ z-index: 99 !important;}
#footer .footer-top .footer-info {
  margin-bottom: 30px;
  text-align:left;
}
#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid #02843D;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  color: #707070;
}
#footer .footer-top .social-links a {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 0;
  margin-right: 8px;
  transition: 0.3s;
  background-color: #4A4A4A;
  border-radius: 50%;
}
#footer .footer-top h4 {
  font-size: 15px;
  font-weight: bold;
  color: #4A4A4A;
  text-transform: uppercase;
  position: relative;
}
#footer .footer-top h4::before, #footer .footer-top h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
#footer .footer-top h4::before {right: 0;}
#footer .footer-top h4::after {width: 60px;}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
  width:50%;
  text-align:left;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i { padding-right: 8px;color: #ddd;}
#footer .footer-top .footer-links ul li { padding: 5px 0;}
#footer .footer-top .footer-links ul li:first-child {padding-top: 0;}
#footer .footer-top .footer-links ul a {color: #707070;}
#footer .footer-top .footer-links ul a:hover { color: #02843D;}
#footer .footer-top .footer-smm {
  margin-bottom: 30px;
   width:50%;
   text-align:left;
}
#footer .footer-top .footer-smm p { line-height: 26px;}
#footer .footer-top .footer-restaurant { margin-bottom: 30px; text-align:left;}
#footer .footer-top .footer-restaurant input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}
#footer .footer-top .footer-restaurant input[type="submit"] {
  background: #02843D;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}
#footer .footer-top .footer-restaurant input[type="submit"]:hover {
  background: #13a456;
}
#footer .copyright {text-align: center; padding-top: 30px;}
#footer .bottom-links {
  text-align: center;
  font-size: 13px;
  color: #02843D;
  font-weight:bold;
  display:-webkit-inline-box;
	padding:5px;
}
#footer .text-center {text-align: center;}
#footer .text-center ul li {padding:5px;display:unset;}
.form-control {
    display: block;
    width: 100%;
    height: 32px;
    font-size: 13px;
    color: #BDC6C1;
    background-color: #fff;
    border: 1px solid #979797 !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.dropdown-toggle::after {
    content:"\f078";
    font-family: "FontAwesome";
    font-size: 10px;
    color:#fff;
    right:8px; top:4px;
    padding:0 4px 2px;
    border-top:0;
    pointer-events:none;
}
.btn-success{
	background-color:#02843D !important;
	border-color:#02843D !important;
	color:#fff;
}
.select-btn {
	font-family: 'Lato', sans-serif;
	color: #fff;
  background: #02843D;
  height: 40px;
  font-size: 15px;
	width:100px;
	border:0;
}
.select-btn::after {
    content: "\f078";
    font-family: "FontAwesome";
    font-size: 10px;
    color: #fff;
    right: 8px;
    top: 4px;
    padding: 0 4px 2px;
    border-top: 0;
    pointer-events: none;
}
.accord {
    background-color: #FC8F3F;
    font-size: 106%;
    letter-spacing: -0.01em;
    margin: 5px 0;
    padding: 1%;
    text-align: center;
    text-transform: uppercase;
    word-spacing: .1em;
}
.centerBlock {
  margin-left: auto;
  margin-right: auto;
  display: block;
	text-align:left;
}
#contactlessRestaurant .centerBlock {  width:100%;}
.panel-title{
	color:#fff;
	margin-top: 0;
  margin-bottom: 0;
	display:block;
}
.clickTrack{color:#fff;}
.slidecontainer { width: 100%;}
.slider {
  -webkit-appearance: none;
  width: 83% !important;
  height: 7px;
  background: #BDC6C1;
  outline: none;
  opacity: none !important;
  -webkit-transition: .2s;
  transition: opacity .2s;
	padding:0 !important;
}
.slider:hover { opacity: 1;}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
	border:2px solid #000;
}
.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
	border:2px solid #000;
}
#ex1Slider .slider-selection {	background: #02843D;}
.slider.slider-horizontal {
    width: 100% !important;
    height: 6px !important;
}
.slider-track{
  -webkit-appearance: none;
  width: 300px;
  height: 7px;
  background: #BDC6C1 !important;
  background-image:none !important;
  box-shadow:none !important;
  border-radius:0 !important;
  outline: none;
  -webkit-transition: .2s;
  padding:0 !important;
  opacity:none !important;
}
.slider-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff !important;
    border: 2px solid #02843D !important;
	background-image: none !important;
	opacity:none !important;
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff',endColorstr='#fff',GradientType=0);
	box-shadow: inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 2px rgba(0,0,0,0.05);
}
.slider-na{visibility: hidden;}
.preference-items{float: left;margin-left: 10px;}
#nearby_inbound-notification_inhours, #nearby_deals-notification_inhours{
  margin-left: 10px;
}
.select-expiring ,.text-expiring{margin-right: 10px;}
.select-expiring{margin-left: 20px;}
#breakfast_mealtime, #lunch_mealtime, #dinner_mealtime{
  max-width: 90px;
  margin-right: 10px;
}
input[type=range].half-width {width: 50%}
input[type=range].full-width {width: 100%}
.star-rating {line-height:32px;font-size:1.25em;}
.user-name{
	padding-left:30px;
	padding-right:15px;
	font-size:18px;
	color:#4A4A4A;
}
.top-links{font-size:13px;display:inline;}
.round.btn{ border-radius: 50px;}
.btn.btn-default {
	background: #02843D !important;
  text-align: center;
  color: #fff !important;
  transition: 0.3s;
  cursor: pointer;
	text-transform:uppercase;
}
.item-details-btn-disabled {
  background: #fff;
  border:1px solid #707070;
  padding: 4px 21px;
  text-align: center;
  color: #707070 !important;
  transition: 0.3s;
  cursor: pointer;
  	border-radius:3px !important;
  width:100%;
  margin-bottom:3px;
}
.bttn{
  background: #02843D !important;
  border:2px solid #02843D !important;
  padding: 6px 21px;
  text-align: center;
  color: #fff !important;
  transition: 0.3s;
  cursor: pointer;
  height: 40px;
  border-radius:50px !important;
}
.bttn:focus,.bttn:hover {
  background-color:#fff;
  color: #02843D;
  border:2px solid #02843D;
}
.btn-link{
		display:inline-block;
		width:200px;
}
.btn-link:hover {
  color: #02843D;
  text-decoration: none !important;
  background-color: transparent;
  border-color: #02843D;
	display:inline-block !important;
}
.btn-toggle.btn-lg.active {
    transition: background-color 0.25s;
}
.border-red{border: 1px solid #d9534f !important;}
.btn-toggle.btn-lg {
    margin: 0 5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 2.5rem;
    width: 5rem;
    border-radius: 2.5rem;
}
.btn-toggle.active {  background-color: #02843D	;}
.example .btn-toggle {
    top: 50%;
    transform: translateY(-50%);
}
.btn-toggle.btn-lg.active:before {opacity: 0.5;}
.btn-toggle.btn-lg:before {
    content: 'Off';
    left: -5rem;
}
.btn-toggle.btn-lg:before, .btn-toggle.btn-lg:after {
    line-height: 2.5rem;
    width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}
.btn-toggle:before, .btn-toggle:after {color: #6b7381;}
.btn-toggle.btn-lg.active > .handle {
    left: 2.8125rem;
    transition: left 0.25s;
}

.btn-toggle.btn-lg > .handle {
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 1.875rem;
    background: #fff;
    transition: left 0.25s;
}
.btn-toggle.btn-lg.active:after { opacity: 1;}
.btn-toggle.btn-lg:after {
    content: 'On';
    right: -5rem;
    opacity: 0.5;
}
.btn-toggle.btn-lg:before, .btn-toggle.btn-lg:after {
    line-height: 2.5rem;
    width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}
.btn-toggle:before, .btn-toggle:after { color: #6b7381;}
input[type="submit"] {
  background: #02843D;
  border:2px solid #02843D;
  padding: 6px 21px;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  height: 40px;
  border-radius: 50px;
}
input[type="submit"]:focus,input[type="submit"]:hover {
  background-color:#fff;
  color: #02843D;
  border:2px solid #02843D;
}
input.fa {font-family: FontAwesome, 'Lato', sans-serif;}
.tree .table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
	border:1px solid #f2f2f2;
}
.tree .menu-filter-btn{
	color:#fff !important;
	font-weight:normal;
}
.tree .table thead { background-color: #02843d;}
.menu-price-th{
	color:#fff !important;
	font-weight:normal;
}
.tree .menu-view-th{
	color:#fff !important;
	font-weight:normal;
}
table.fancytree-ext-table.fancytree-treefocus.fancytree-container > tbody > tr.fancytree-active > td {
    background-color: #BDC6C1;
}
table.fancytree-ext-table.fancytree-container > tbody > tr.fancytree-active > td {
    background-color: #BDC6C1;
}
.hidden {display: none;}
.chevron_toggleable {float: right; margin-left: 1em;}
.plus_toggleable {margin-right: 0.5em;}
.accord-green .panel-title a {
  text-decoration: none;
  display: block;
  color: #02843D;
  padding: 0 0 0 10px;
  font-weight:bold;
}
.accord-green .panel-title a:hover {color: #02843D;text-decoration: none;}
.accord-green .panel-title a:visited { text-decoration: none;}
.accord-green .material_accordion .material_accordion_collapse {
  text-decoration: none;
}
.clearfix {clear:both;}
.flexColumn{
  flex-direction: row;
  display: flex;
  justify-content: center;
}
.accord-green {
  background-color: #FFFFFF;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 1% 0%;
  word-spacing: 0.1em;
  border-bottom:1px solid #E0E4E1;
}
.about_menu{
  border-bottom:1px solid #E0E4E1;
  font-size:15px !important;
}
.accord-green .panel-body .panel-group{
  padding: 15px;
  border: 1px solid #8ba4b0;
}
.accord-green .panel-body .panel-default>.panel-heading{
  background-color: #8ba4b0;
  padding: 2px 15px;
}
.panel-body{padding:0 !important;}
.accord-grey .panel-title a {
  text-decoration: none;
  display: block;
  color: #707070;
  padding: 0 15px 0 10px;
  cursor: pointer;
}
.accord-grey .panel-title a:hover {color: #707070; text-decoration: none;}
.accord-grey .panel-title a:visited {text-decoration: none;}
.accord-grey .material_accordion .material_accordion_collapse {
  text-decoration: none;
}
.accord-grey {
  background-color: #FFFFFF;
  font-size: 106%;
  letter-spacing: -0.01em;
  margin: 0;
  padding: 1%;
  word-spacing: 0.1em;
  border-top: 1px solid #ccc;
}
.accord-grey .panel-body .panel-group{
  padding: 15px;
  border: 1px solid #8ba4b0;
}
.accord-grey .panel-body .panel-default>.panel-heading{
  background-color: #8ba4b0;
  padding: 2px 15px;
}
.note-popover.popover {display: none;}
.nav-rounded{
	background:#02843D;
	color:#fff;
	border-radius:50px;
   border:2px solid #ccc;
}
.nav-rounded .nav-link{
	background:#9ACEB1;
	color:#fff;
  display: block;
  padding: .3rem 0.8rem;
  border: 3px solid transparent !important;
}

.nav-rounded .nav-item{
	color:#fff;
	border-radius:50px;
}
.nav-rounded.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #02843D;
    height: 42px;
    border-radius: 30px;
}
.nav-rounded.nav-pills .nav-link {border-radius:30px;}
#menuRestaurantBar .nav.nav-pills {
  float:left;
  text-align: center;
}
.nav.nav-pills {width : auto;}
.nav.nav-pills .nav-item {display:inline-block;}
.week-hours-control.container {margin-top: 0 !important;}
.week-hours-control p {margin: 0;}
.btn-cancel-hours, .btn-save-hours{ min-width: 83px;}
.searchRadius{
  border-radius: 0 1.25rem 1.25rem 0;
  border: 1px solid #bdc6c1;
  padding:0 3px;
}
.sliderlabel{font-weight: 600;}
.img-circle{ border: 1px solid gray; border-radius: 50%;}
.img-circle-pic{border-radius:50%;}
#recommended_friendsspan {
  font-size: 20px;
  padding: 2px;
  font-weight: bold;
  margin-left: 10px;
}
/* Inner Results Page
--------------------------------*/
/* Section with Results count
--------------------------------*/
.results-bg {background: #fff; padding: 10px 0;}
.result-cnt {vertical-align:-webkit-baseline-middle !important;}
.credit-btn {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 3px;
    border-radius: 25px;
    transition: 0.5s;
    border: 2px solid #02843D;
    color: #02843D;
  text-align:center;
}
.cre-btn {text-align:left;padding-left:5px;}
.cre-btn .fa-gift{
    width: 26px;
    height: 26px;
    text-align: center;
    position: relative;
    background-color: #02843D;
    border-radius: 50%;
    text-align: center;
    transition: 0.3s;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.60);
}
.image-lazy-load.go-to-specified-restaurant.carouselflip {
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.30);
}
.cre-btn i {
  font-size: 15px;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
  padding-top:5px;
}
#results {
  padding: 0px 0 40px 0;
  position: relative;
}
#results::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}
#results .container {
  position: relative;
  z-index: 10;
}
.homecontainer {
    background-color: #F2F2F2;
    padding-bottom: 50px;
}
#homepageHighestRatedDiv, #recommendedbyLocalsDiv , #nearbyHappyhrDiv, #recommended_friendsviews{
  position: relative;
  z-index: 10;
}
#results .results-col {margin-bottom: 20px;}
#search-menuItemsDiv .results-col {  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10);}
#results .results-col .img { position: relative;}
#results .results-col .img img {
  border-radius: 0;
  width:100%;
}
#results .results-col i { font-size: 13px; transition: 0.3s;}
#results .results-col h2 {
  color: #4A4A4A;
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  padding: 0 5px;
  margin: 5px 0 1px 0;
}
#results .results-col h2 a {color: #4a4a4a;}
#results .results-col h2 a:hover { color: #02843D;}
#results .results-col p {
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
  white-space: normal;
  margin-bottom: 0;
  text-align:left;
}
@media(min-width: 641px){
  .image-container-full img{
    width: initial;
    margin: auto;
  }
  .touchDivBtn {position: absolute; right: 17px}
}
@media screen and (max-width: 999px) and (min-width: 370px) {
  #add_to_order {  left:35% }
}
@media (min-width: 1024px) {
   #add_to_order {left:45%}
  #header-new #head-logo { padding-left: 60px;}
  .why-pr { width:70%;}
}
@media screen and (max-width: 1200px){
  #results .results-col p {  font-size: 13px !important;}
  #results .btn-cart {
      font-weight: 500;
      font-size: 14px !important;
      letter-spacing: 0px !important;
      padding: 0px !important;
      margin: 10px 0 !important;
      width: 100% !important;
      height: 35px !important;
  }
  #results .btn-rate {
      margin: 10px 0 !important;
      font-size: 12px !important;
      padding: 0px !important;
      width: 58px !important;
      height: 35px !important;
  }
  #results .font-15 {  font-size: 13px !important;}
  .fix-width{
    max-width :100% !important;
    padding: 15px !important;
  }
  .menuitem_rr .row .col-4 img { height: 100% !important; }
}

@media (min-width: 768px) and (max-width: 1023px){
  .why-pr { width:68%;}
  .restaurant-bg-width {width: 95% !important;}
}
@media only screen and (max-device-width: 766px) {
  .accord-green {
    background-color: #FFFFFF;
    font-size: 106%;
    letter-spacing: -0.01em;
    margin: 0;
    padding: 1% 0%;
    word-spacing: 0.1em;
  }
  #collapsePersonalInfo .accord-green .row {
    padding-left: 0;
    padding-right: 0;
  }
  span.mobile_restaurant {
    display: block !important;
    font-size: 10px !important;
    font-weight: normal !important;
 }
}
@media screen and (max-width: 480px) and (min-width: 465px) {
  #results .btn-cart { margin: 0px 0 !important;}
  #results .btn-rate {   margin: 0px 0 !important;}
}
/* span.font-icons.scard.fix-margin-icon.margin-header-icons.animated.bounce span.badge.shopping_card_badge{ */
span.font-icons.scard.fix-margin-icon.margin-header-icons.animated.bounce {
 /* top: -16px;*/
}
#order-modal-content a.close{z-index:9999;}
#order-modal-content .cart-actions-sticky .eligible-credits-label {
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}
#results .food-details{
  padding:5px 6px;
  height: 115px;
}
#results .food-flavor{
  padding:5px 15px 0px 15px;
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top:none;
}
#results .fa-bookmark
{
  font-size:24px !important;
  float:right;
  margin-right:5px;
}
#results .over-image-icons
{
  font-size:15px !important;
	padding: 6px;
	background: #fff;
	border-radius: 50%;
	color: #979797;
	border: 0.5px solid #979797;
}
#results .over-image-iconstop
{
  font-size:15px !important;
  padding: 6px;
  background: #fff;
  border-radius: 50%;
  color: #979797;
	border: 0.5px solid #979797;
}
#results a.item-name.item-title{font-size:16px!important;}
#results .meet-btn{width: 100%;}
#results .fa-bookmark :active , #results .fa-bookmark-o :active{color: green;}
#results .results-col .earn{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: inline-flex;
  border-bottom: 1px solid #fff ;
  padding-bottom: 3px;
  margin-top:4px;
  color: #fff;
}
#results .results-col .earn-upto{
    float:right;
    font-size: 10px;
    display: inline-flex;
    color:#fff;
}
#results .results-col .free-price{
    float:right;
    font-size: 13px;
    display: inline-flex;
    color: #FC8F3F;
  font-weight:bold;
}

#results .results-col .price {
  font-size:18px;
  float:right;
  padding:0 5px 0 0;
}
#results .star-rating {
  line-height:14px;
  font-size:13px;
  display: inline-flex;
	padding: 4px;
	background: #fff;
	border-radius: 4px;
}
#results .star-rating .fa-star{color: #FC8F3F;font-size:13px;}
#results .star-rating .count{color: #FC8F3F;font-size:13px;}
#results .form-row{
  flex-wrap: wrap-reverse !important;
  padding:0 5px;
}
#results .btn-cart {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: .5s;
  margin: 10px 0;
  color: #fff;
  background: #02843d;
  border: 0;
  text-transform: capitalize;
  width: 97%;
  height: 35px;
}
#results .btn-cart:hover {
  background: #fff;
  color: #02843D;
  border:2px solid #02843D;
  font-weight: 500;
}
#results .btn-rate {
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    padding: 0px;
    border-radius: 4px;
    transition: 0.5s;
    border: 2px solid #02843D;
    color: #02843D;
    text-align: center;
  width:75px;
  height:30px;
  float:right;
  background:#fff;
}
#results .btn-rate:hover {
  background: #02843D;
  color: #fff;
  border:2px solid #02843D;
  font-weight: 500;
}
#results .progress{
  height:7px;
  margin-top:8px;
  background-color:#707070;
  border-radius:0;
}
#results .progress-bar{background-color:#02843D;}
#results .progress .down1{
  color: #02843D;
    position: absolute;
    font-size: 15px;
    bottom: 12px;
}
#results .badge {width:30px; height:15px;}
#results .badge-green {background:#02843D; color:#F1F4F3; border-radius:50px; font-size:10px;padding:2px;margin-left:5px;}
#results .fa-check{
    margin-right: 8px;
    vertical-align: text-top;
}
#results .flip2 {
  -webkit-perspective: 800;
   height: 26rem !important;
  position: relative;
  transform-style: preserve-3d;
}
ul.slick-dots.firstslick{ right: 8%;}
#homepagesearch #results .flip2 {
   height: 24rem;
   background-color: #fff;
}

#results .flip2 .card.flipped {
  -webkit-transform: rotateY(180deg);
}
#results .flip2 .card {
  width: 100%;
  height: 100%;
  transition: 0.5s;
  border:0 !important;
}
#results .flip2 .card .face {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden ;
  z-index: 2;
}
#results .flip2 .card .front {
  position: absolute;
  z-index: 1;
  transform: rotateY(0deg);
  transition: 0.6s;
  cursor: pointer;
}
#results .flip2 .card .back {
  -webkit-transform: rotateY(-180deg);
    background: linear-gradient(92.33deg, rgba(136, 62, 62, 0.2) 5.85%, rgba(0, 255, 230, 0.2) 92.5%), #02843D;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.6s;
}
#results .flip {
  -webkit-perspective: 800;
  height: 25rem;
  position: relative;
  transform-style: preserve-3d;
}

#results .flip .card.flipped { -webkit-transform: rotateY(180deg);}
#results .flip .card {
  width: 100%;
  height: 100%;
  transition: 0.5s;
  border:0 !important;
}
#results .flip .card .face {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-backface-visibility: hidden ;
  z-index: 2;
}
#results .flip .card .front {
  position: absolute;
  z-index: 1;
  transform: rotateY(0deg);
  transition: 0.6s;
  cursor: pointer;
}
#results .flip .card .back {
  -webkit-transform: rotateY(-180deg);
    background: linear-gradient(92.33deg, rgba(136, 62, 62, 0.2) 5.85%, rgba(0, 255, 230, 0.2) 92.5%), #02843D;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.6s;
}
#results .credit-bg {
  background: #fff;
  border-radius:15px;
  height:525px;
}
#results .credit-header{padding:1rem;}
#results .credit-title{
  text-align: center !important;
  font-size: 24px !important;
  color:#fff;
  text-transform:uppercase;
  word-spacing:8px;
}
#results .grey-bg{
  background:#4A4A4A;
  color:#F1F4F3;
  font-size:16px;
  text-align:center;
  width: 100%;
  height:40px;
  margin-left:0px;
}
#results .cl1{padding-top:5px;}
#results .cl2{
    padding-top: 3px;
    background: green;
    border: 4px solid #fff;
    border-radius: 50%;
    width: 45px !important;
    height: 45px;
    font-size: 18px;
}
#results .cl3{ padding-top:5px;}
#results .white-bg{
  background:#fff;
  color:#4A4A4A;
  text-align:center;
  width: 100%;
    display: inline-flex;
  margin-left:0px;
  text-transform:uppercase;
}
#results .ecl1{ border-right:1px solid #E8ECEA;}
#results .credit-bg .btn-cart {
  font-size:18px;
  width:200px;
}
#results .credit-bg i{font-size:18px;}
#results .social-links {
  text-align:center;
}
#results .social-links a {
  font-size: 24px;
  display: inline-block;
  background: #fff;
  color: #02843D;
  line-height: 1;
  padding: 0;
  margin-right:30px;
  transition: 0.3s;
}
#results .social-links a:hover {
  background: #707070;
  color: #02843D;
}
#results .bottom-arrow {
  text-align: center;
    color: #fff;
    width: 47px;
    border-radius: 50%;
    height: 47px;
    padding-top: 10px;
    left: 42%;
    position: absolute;
    bottom: -13px;
}
@media screen and (max-device-width: 320px) {
  .item-rating-price-container {
        display: inline-block;
        width: 150px;
    }
   .review-rating-stars {
        margin-left: 10px;
        margin-top: 20px;
    }
   .rating-username{
        margin-top: 5px;
        margin-left: 15px;
        position: absolute;
    }
  .inAddress {font-size: 13px;}
  #intro a.home-address-text span.home-filter-lens{min-width: 80px !important;}
  .search-filter {margin-top: 4px;}
  .headsearchhome {
    margin-top: 10px;
    right: -16px;
    float: right;
    position: relative;
    padding-top: 5px;
    z-index: 2;
    margin: -39px 10px 0 -16px !important;
    height: 32px;
    margin-right: 1% !important;
    color: #4a4a4a;
  }
  #earntext {margin-top: 50px !important;}
  #header .container-fluid span a {display: none;}
  .rate-earn-rewarded-text {
  font-size: 33px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  line-height: 48px;
  }
  .highest-rated-items-labels {
      padding-left: 10px;
      padding-right: 8px;
      letter-spacing: -0.9px;
  }
  .rating-stars { margin-left: 5px !important;}
  .highest-rated-items-rcount { padding-left: 25px; }
  .menu-button { font-size: .8em;}
  .glyphicon-chevron-right {
    margin-left: -1px !important;
    padding-left: 4px !important;
  }
  .glyphicon-chevron-down {
    margin-left: -1px !important;
    padding-left: 4px !important;
  }
  #earntext .homedesktopsubheader {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  #intro-container .homebannertext {
    /* padding-top: 27% !important; */
  }
  
}
@media(min-width: 641px){
  .image-container-full img{
    width: initial;
    margin: auto;
  }
}

@media screen and (max-width: 999px) and (min-width: 370px) {
  #add_to_order {  left:35% }
}

@media (min-width: 1000px) {
   #header-new #head-logo { padding-left: 60px;}
  .why-pr { width:70%;}
   #add_to_order {left:45%}
   #intro p {    width: 60%;  }
}
@media screen and (max-width: 1200px){
  #results .results-col p {  font-size: 14px !important;}
  #results .btn-cart {
      font-weight: 500;
      font-size: 12px !important;
      letter-spacing: 0px !important;
      padding: 0px !important;
      margin: 10px 0 !important;
      width: 98% !important;
      height: 35px !important;
  }
  #results .btn-rate {
      margin: 10px 0 !important;
      font-size: 12px !important;
      padding: 0px !important;
      width: 58px !important;
      height: 35px !important;
  }
  #results .font-15 {  font-size: 13px !important;}
  .fix-width{
    max-width :100% !important;
    padding: 15px !important;
  }
  .menuitem_rr .row .col-4 img { height: 100% !important; }
}
@media (max-width: 470px) {
  .homesignup-sidenav{height: 36px !important;}
  #header-new.header-scrolled {height: 110px !important;}
  #header-new { height: 110px !important;}
  #header-new #head-logo img { width:130px !important; }
  .login-container{ width: 324px;}
  .head-location-src-box ::placeholder{ font-size: 7px !important;}
  .head-location-src-box input[type=text]{ font-size: 9px !important;}
  .box.row.login-box-new{ width:304px!important;}
  .head-location-container input#locationinputheader{ width: 240px!important;}
  /* #header #head-logo img { width:40px !important; } */
  .font-icons {
      font-size: 20px !important;
      padding-right: 3px !important;
  }
  .login-reg-btn{
    font-size: 0.7rem !important;
    margin-right: 0px !important;
    height: 22px !important;
    padding-top: 3px;
  }
}

@media (min-width: 768px) and (max-width: 1023px){
  .why-pr { width:68%;}
}
@media only screen and (max-device-width: 766px) {
  .accord-green {
    background-color: #FFFFFF;
    font-size: 106%;
    letter-spacing: -0.01em;
    margin: 0;
    padding: 1% 0%;
    word-spacing: 0.1em;
  }
  #collapsePersonalInfo .accord-green .row {
    padding-left: 0;
    padding-right: 0;
  }
  span.mobile_restaurant {
    display: block !important;
    font-size: 10px !important;
    font-weight: normal !important;
 }
}
@media screen and (max-width: 480px) and (min-width: 465px) {
#results .btn-cart { margin: 0px 0 !important;}
#results .btn-rate {   margin: 0px 0 !important;}
}
#intro {
  width: 100%;
  /* padding-top:12px; */
}
/* #topheader{ height: 360px;} */
#intro hr {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid #BDC6C1;
}
#intro h2 {
  color: #F1F4F3;
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 700;
}
#intro h1 {
  color: #fff;
  font-size: 24px;
  font-weight: normal;
  margin:20px 0 0 0;
}
#intro p {
  width: 80%;
  margin: 0 auto 30px auto;
  color: #fff;
}
#intro .btn-cart {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  cursor:pointer;
  padding: 8px 32px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #02843D;
  border:0;
}
#intro .btn-cart:hover {
  background: #fff;
  color: #02843D;
}
#intro .btn-cart:hover {
  background: #fff;
  color: #02843D;
}
#intro .img-holder {
  display: block;
  position: relative;
  max-width: 100%;
  height: auto;
  margin-top:64px;
}
#intro .dish {
  max-width: 60%;
  height: auto;
}
#intro .info-icon{
  position: absolute;
    top: 25px;
    right: 15px;
    width: 20px;
    height: 20px;
    z-index: 2;
}
#intro .img-btm{ position:relative;}
.freefood {	position:relative;	top:0;}
#intro .star-rating {
  line-height:32px;
  font-size:13px;
}
#intro .star-rating .fa-star{color: #FC8F3F;font-size:13px;}
#intro .star-rating .count{color: #FC8F3F;font-size:13px;}
#intro .rev{color: #707070;font-size:13px;}
#intro .rev a {color: #707070;font-size: 13px;}
#intro .form-group {margin-bottom:0;}
#intro .progress-bar{  background-color:#02843D;}
#intro .rt-pref{
  display:block;
  text-align:right;
}
#intro .badge {width:30px; height:15px;color:#f1f4f3;font-weight:normal;}
#intro .badge-green {background:#02843D; color:#F1F4F3; border-radius:50px; font-size:10px;padding:2px;margin-left:5px;}
#intro .nav-link {display: block; padding: .5rem 0.8rem; border:0;}
@media (max-width: 349px){
  #intro .nav-link {
      display: block;
      padding: .7rem .3rem;
      border: 0;
      font-size: .55rem;
  }
  #intro .input-group-location {
      padding-top: 1px !important;
      font-size: 11px !important;
  }
}

#intro .fa-sliders-h {float:right; }
#intro .input-group {
  border-radius:30px;
  border:1px solid #BDBCBC;
  height:45px; 
  background:#fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#intro .input-group-text
{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background: 0;
    border: 0;
    margin-top:-1px;
}
#intro .input-group-rt
{
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background: #fff;
  border: 0; padding:0.475rem .85rem;
}

#intro .input-group-location {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-top: 0px;
    width: auto;
    font-size:12px;
    height: 41px;
}
#intro .form-control {
  height:50px;
  border:0;
  font-size:14px;
  border-radius:0px;
  border-left: 0px !important;
  border-right: 0px !important;
  border: 1px solid #979797;
  font-family: FontAwesome, Lato, sans-serif;
}
input#homehead-location-input-search:focus {
  box-shadow: none;
}
.autocomplete-homepage-wrapper #homehead-location-input-search {
  /*background-color: transparent !important;*/
}
.autocomplete-homepage-wrapper .wrap-hide-locationinput{
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  font-size: 14px;
  overflow: hidden; 
  vertical-align: middle;
  white-space: nowrap; 
  border-radius: 50px 0 0 50px;
  /* color: #b7b7b7; */
}
.autocomplete-homepage-wrapper .wrap-hide-locationinput #hide-locationinput{
  background-color: unset !important;
}
.autocomplete-homepage-wrapper .wrap-hide-locationinput #text-hide-locationinput {
  background: white;
  color: transparent;
  position: absolute;
  /* z-index: 1; */
}
#intro a.home-address-text{
  padding: 2px;
  background: #ffffff;
  height: 50px;
  border-radius: 50px 0px 0px 50px;
  border: 1px solid #979797;
  border-right: 0 !important;   
  display: table;
  max-width: 200px;
}
#intro a.home-filter-icon{
  background: #ffffff;
  height: 50px;
  border-radius: 0px 50px 50px 0px;
  border: 1px solid #979797;
  border-left: 0 !important;   
  display: table;
}
#intro a.home-address-text span.home-filter-lens, #intro a.home-filter-icon span.home-filter-lens{
  display: table-cell;
  vertical-align: middle;
}
#intro a.home-address-text span.home-filter-lens {
  min-width: 100px;
}
#intro a.home-filter-icon span.home-filter-lens {
  border-left: 0.5px solid #C4C4C4;
}
.location-container .form-control {
  font-size:15px;
  padding: 0 15px;
  font-family: FontAwesome, Lato, sans-serif;
}
#searchRestaurantForm .form-control {
  font-size:15px;
  font-family: FontAwesome, Lato, sans-serif;
}
#intro .form-check { padding-left:0.15rem;}
#intro .text-link {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 36px;
  text-align: center;
  text-decoration-line: underline;
  float: right;
  color: #656565;
}
#intro .text-link:hover {text-decoration:underline;}
#intro .slidecontainer {
  width: 100%;
  text-align:left;
}
#back_to_search{line-height: 32px;}

#intro .slider {
  -webkit-appearance: none;
  width: 87%;
  height: 7px;
  background: #BDC6C1;
  outline: none;
  opacity: none !important;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-top:17px;
}


#intro .slider:hover {
    opacity: 1;
}
#intro .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  border:2px solid #02843D;
}
#intro .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  border:2px solid #02843D;
}
#intro .slider-track{
  -webkit-appearance: none;
  height: 7px;
  background: #BDC6C1 !important;
  background-image:none !important;
  box-shadow:none !important;
  border-radius:0 !important;
  outline: none;
  -webkit-transition: .2s;
  padding:0 !important;
  opacity:none !important;
}
#intro .slider-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #fff !important;
    border: 2px solid #02843D !important;
  background-image: none !important;
  opacity:none !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff',endColorstr='#fff',GradientType=0);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 2px rgba(0,0,0,0.05);
}
#intro #filter-form h4 {
    font-size: 15px;
    font-weight: bold;
    color: #4A4A4A;
    text-transform: uppercase;
    position: relative;
}
#intro #filter-form {
    width: 100% !important;
    background: #fff;
    margin-top: 10px;
    padding: 15px;
    text-align: left;
  -webkit-box-shadow: inset 0px 2px 2px 0px #ABABAB;
    box-shadow: inset 0px 2px 2px 0px #ABABAB;
}
#intro #filter-form .row-width{
  margin:0 25px;
}
#intro #filter-form .custom-control-label {
    font-size: 13px;
    padding-top: 4px;
}

#intro #filter-form .form-control  {
    border: 1px solid #BEBABA;
    width: 288px;
    height: 33px;
    padding-left: 15px;
    color: #4a4a4a !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}
.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 0;
}

@media (min-width: 1024px) {
  #nav-menu-container {
    padding-right: 60px;
  }
}
/* Nav Meu Styling */
.nav-menu a {
  padding: 0 8px 10px 8px;
  text-decoration: none;
  display: inline-block;
  color: #4a4a4a;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  outline: none;
}
.nav-menu li:hover > a, .nav-menu > .menu-active > a {
  color: #02843D;
}
.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}
.nav-menu ul li {  transition: 0.3s;}
.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}
.nav-menu ul li:hover > a {  color: #02843D;}
.nav-menu ul ul {  margin: 0;}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 8px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {  color: #4A4A4A;}
#mobile-nav-toggle .fa-times {  color: #fff;}
/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: #fff;
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}
#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#mobile-nav ul li {  position: relative;}

#mobile-nav ul li a {
  color: #707070;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}

#mobile-nav ul li a:hover {  color: #fff;}
#mobile-nav ul li li {  padding-left: 30px;}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up { color: #02843D;}

#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}

#mobile-nav ul .menu-item-active { color: #02843D;}

.sidenav-bg {
	background:#fff;
	overflow-x: hidden;
}
.sidenav-bg-main {
	background:#fff;
	width:100%;
  padding-left: 30px;

}
@media (max-width: 414px) {
#mobile-body-overly {
    width: 10%;
    left: 90% !important;
}
}
#mobile-body-overly {
  width: 100%;
  height: auto;
  z-index: 997;
  top: 0;
  left: 93%;
  position: absolute;
  display: block;
}
@media screen and (max-width: 1000px) and (min-width: 690px) {
#mobile-body-overly {
  width: 5%;
  top: 0;
  left: 95%;
}
}
@media screen and (max-width: 1024px) and (min-width: 1001px) {
#mobile-body-overly {
  width: 11%;
  top: 0;
  left: 89%;
}
}
/* Mobile Nav body classes */
body.mobile-nav-active {  overflow: hidden;}
body.mobile-nav-active #mobile-nav {  left: 0;}
body.mobile-nav-active #mobile-nav-toggle {color: #fff;}
/* Sections Header
--------------------------------*/
.section-header h3 {
  font-size: 32px;
  color: #4A4A4A;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 0px;
  margin-top:0px;
}
.section-header p {
  text-align: center;
  padding-bottom: 30px;
  color: #333;
}
/* Section with background
--------------------------------*/
.section-bg {  background: #f7f7f7;}
.section-bg-green {
  background: #02843D;
  color: #fff;
    text-align: center;
    padding: 15px 0 0 0;
}
.h4, h4 {    font-size: 1.2rem;  font-weight:normal;}
.h5, h5 {    font-size: 1.2rem;    color: #000;}

/* About Us Section
--------------------------------*/
#about {
  background: #fff;
  padding: 27px 0 40px 0;
  position: relative;
}
#about::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;
}
#about .container {
  position: relative;
  z-index: 10;
}
#about .about-col {  margin-bottom: 20px;}
#about .reasons {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);

}
#about .about-col .img {  position: relative;}
#about .about-col .img img {  border-radius: 4px 4px 0 0;}
#about .about-col .icon {
  width: 110px;
  height: 110px;
  padding-top: 22px;
  text-align: center;
  position: relative;
  background-color: #02843D;
  border-radius: 50%;
  text-align: center;
  left: calc( 50% - 70px);
  transition: 0.3s;
  box-shadow:0px 5px 12px rgba(0, 0, 0, 0.08);
}
#about .about-col i {
  font-size: 60px;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
}
#about .about-col:hover .icon {  background-color: #fff;}
#about .about-col:hover i {  color: #02843D;}
#about .about-col h2 {
  color: #4A4A4A;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  margin: 20px 0 15px 0;
}
#about .about-col h2 a {color: #4a4a4a;}
#about .about-col h2 a:hover {color: #02843D;}
#about .about-col p {
  font-size: 18px;
  line-height: 24px;
  color: #4a4a4a;
  margin-bottom: 0;
  padding: 0 20px 20px 20px;
  text-align:center;
}
#about .sign-btn {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #02843D;
    color: #02843D;
  text-align:center;
}
#about .signup-btn { text-align:center; margin-bottom:20px;}
/* Reasons Section
--------------------------------*/
#reasons {
  background: #02843D;
  padding: 27px 0 40px 0;
  position: relative;
}
#reasons .section-header h3 {
    font-size: 32px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    position: relative;
    padding-bottom: 0px;
	margin-top:0px;
}
#reasons::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #02843D;
  z-index: 9;
}
#reasons .container { position: relative; z-index: 10;}
#reasons .reasons-col { margin-bottom: 20px;}
#reasons .reasons {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.60);
    height: 160px;
}
#reasons .reasons-col .img {  position: relative;}
#reasons .reasons-col .img img {  border-radius: 4px 4px 0 0;}
#reasons .reasons-col .icon {
  width: auto;
  height: 50px;
  padding: 22px 0 55px 0;
  text-align: center;
  position: relative;
  text-align: center;
  transition: 0.3s;
}
#reasons .reasons-col i {
  font-size: 50px;
  line-height: 1;
  color: #02843D;
  transition: 0.3s;
}
#reasons .reasons-col:hover i {  color: #02843D;}
#reasons .reasons-col h2 {
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  margin: 15px 0 15px 0;
}
#reasons .reasons-col h2 a {  color: #4a4a4a;}
#about .reasons-col h2 a:hover {  color: #02843D;}
#reasons .reasons-col p {
    font-size: 15px;
    line-height: 20px;
    color: #4a4a4a;
    margin-bottom: 0;
    padding: 0 10px 10px 10px;
    text-align: center;
}
#reasons .rt-arrow{font-size: 18px !important; text-align: center;}
#reasons .fa-arrow-right{ ont-size: 18px !important;  text-align: center;}
#reasons .sign-btn {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #02843D;
    color: #02843D;
  text-align:center;
}
#reasons .signup-btn {
  text-align:center;
  margin-bottom:20px;
}
#reasons .col-sm-6 {  width:50%;}
#reasons .flip-card {
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
/* This container is needed to position the front and back side */
#reasons .flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
/* Do an horizontal flip when you move the mouse over the flip box container */
#reasons .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
/* Position the front and back side */
#reasons .flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
/* Style the front side (fallback if image is missing) */
/* Style the back side */
#reasons .flip-card-back {
  background-color: #02843D;
  color: #fff;
  transform: rotateY(180deg);
  border-radius: 15px;
  border:2px solid #fff;
}
#reasons .flip-back-text{
  padding:8px;
  color: #fff;
  font-size: 13px;
  display:block;
}
/* Services Section
--------------------------------*/
#services {
  background: #fff;
  background-size: cover;
  padding: 60px 0 40px 0;
}

#services .box {  margin-bottom: 30px;}
#services .icon {  float: left;}
#services .icon i {
  color: #02843D;
  font-size: 36px;
  line-height: 1;
  transition: 0.5s;
}
#services .title {
  margin-left: 60px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
#services .title a { color: #111;}
#services .box:hover .title a { color: #02843D;}
#services .description {
  font-size: 14px;
  margin-left: 60px;
  line-height: 24px;
  margin-bottom: 0;
}

/* Call To Action Section
--------------------------------*/
#call-to-action {
  background:#4A4A4A;
  padding: 60px 0;
}

#call-to-action h4 {
  color: #fff;
  font-size: 1.3rem;
  font-weight: normal;
}
#call-to-action p {  color: #fff;}
#call-to-action .cta-btn {
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#call-to-action .cta-btn:hover {
  background: #02843D;
  border: 2px solid #02843D;
}
#call-to-action input[type="email"] {
  border: 0 !important;
  padding: 6px 15px;
  width: 80%;
  height: 45px;
  border-radius: 50px;
  color:#BDC6C1;
}
#call-to-action input[type="submit"] {
  background: #02843D;
  border: 2px solid #fff;
  padding: 6px 19px;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  height: 40px;
  border-radius: 50px;
  margin-left: -133px;
}
#call-to-action input[type="submit"]:hover {
    color: #02843D;
    background-color: #fff;
    border-color: #02843D;
}
.search-icon{
  position: absolute;
  float: right;
  left: 92%;
  background: transparent;
  color: #BDC6C1;
}
/* Contact Section
--------------------------------*/
#contact {  padding: 60px 0;}
#contact .contact-info {
  margin-bottom: 20px;
  text-align: center;
}
#contact .contact-info i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: #02843D;
}
#contact .contact-info address, #contact .contact-info p {
  margin-bottom: 0;
  color: #000;
}
#contact .contact-info h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}
#contact .contact-info a {  color: #000;}
#contact .contact-info a:hover {  color: #02843D;}
#contact .contact-address, #contact .contact-phone, #contact .contact-email {
  margin-bottom: 20px;
}
#contact .form #sendmessage {
  color: #02843D;
  border: 1px solid #02843D;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}
#contact .form #sendmessage.show, #contact .form #errormessage.show, #contact .form .show {
  display: block;
}
#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}
#contact .form input, #contact .form textarea {
  padding: 10px 14px;
  border-radius: 50px;
  box-shadow: none;
  font-size: 15px;
}
.form button[type="submit"] {
  background: #02843D;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  border-radius:50px;
}
.form button[type="submit"]:hover {
  background: #02843D;
  border-radius:50px;
}
.btn-primary {
  color: #fff;
  background-color: #02843D;
  border-color: #02843D;
	padding: 8px;
}
.btn-primary:hover {
    color: #02843D !important;
    background-color: #fff;
    border-color: #02843D;
}
 .btn-default.btn-on.active{background-color: #fff;color: #02843D;border-radius:50px; height:40px; width:100px;}
.btn-default.btn-off.active{background-color: #000;color: #fff;}
.myorders-nav{
  width: 100% !important;
  height: auto  !important;
}
.nav{
  background:#02843D;
  color:#fff;
  border-radius:0px;
  width:290px;
  height:24px;
}
.nav-link{
  background:#02843D;
  color:#fff;
}
.nav-item{
  color:#fff;
  border-radius:50px;
  border:0;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #02843D;
  background-color: #fff;
  border-radius:0px;
  /* border:3px solid #02843D; */
  height:32px;
}
.nav-pills .nav-link {    border-radius:50px;}
.star-rating {
  line-height:32px;
  font-size:1.25em;
}
.dropdown-toggle::after {
    content:"\f078";
    font-family: "FontAwesome";
    font-size: 10px;
    color:#fff;
    right:8px; top:4px;
    padding:0 4px 2px;
    border-top:0;
    pointer-events:none;
}
.sidenav-icon{
  font-size:22px;cursor:pointer;float:right;color:#4A4A4A;margin-top:10px;
}
.font-icons{font-size:22px;cursor:pointer;color:#4A4A4A;}
.sidenav {
  max-height: 60%;
  width: 0;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  padding-top: 60px;
  color:#4A4A4A;
  box-shadow: -7px 5px 22px #00000040;
}
.sidenav a {
  padding: 0px 0px 8px 0px;
  text-decoration: none;
  font-size: 14px;
  color: #02843D;
  transition: 0.3s;
  font-weight:normal;
}
.sidenav a:hover {color: #4A4A4A;}
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 22px;
  margin-left: 50px;
  background:transparent;
  color:transparent;
  border-bottom:none;
}
.sidenav a{font-size: 18px}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 64px;}
  .sidenav a {font-size: 18px;}
}
@media screen and (max-width: 480px) and (max-height: 640px) {
  .rest-available-now{
    overflow-y: scroll;
    height: 350px;
  }
}
@media (min-width: 1024px){
  .sidenav .closebtn {
    position: absolute;
    top: 2px;
    right: 60px;
    font-size: 22px;
    margin-left: 50px;
    background:transparent;
    color:transparent;
    border-bottom:none;
  }
}
.form-control {
    display: block;
    width: 100%;
    height: 32px;
    font-size: 13px;
    color: #4a4a4a;
    background-color: #fff;
    border: 1px solid #BDC6C1;
    font-family: FontAwesome,Lato,sans-serif;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: #BDC6C1 1px solid;
}
.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #fff;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #02843D;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #02843D;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #02843D;
}
.can-toggle {  position: relative;}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch { position: relative;}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  background: white;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.custom-control-input:checked~ .custom-control-label::before {
  border: 0px !important;
}
.custom-checkbox .custom-control-label::before {
  border: 2px solid #666;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #777;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #5e5e5e;
}
.can-toggle input[type="checkbox"]:hover ~ label {
  color: #6a6a6a;
}
.can-toggle input[type="checkbox"]:checked ~ label:hover {
  color: #55bc49;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #4fb743;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color: #5fc054;
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  color: #47a43d;
}
.can-toggle label .can-toggle__label-text {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  -webkit-transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
  background: #848484;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}
.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: unset;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
  transform: translate3d(65px, 0, 0);
}
.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  box-shadow: unset;
}
.can-toggle label {font-size: 13px;}
.can-toggle label .can-toggle__switch {
  height: 36px;
  -webkit-flex: 0 0 134px;
  -ms-flex: 0 0 134px;
  flex: 0 0 134px;
  border-radius: 50px;
}
.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 34px;
  width: 67px;
  padding: 0 10px;
}
.can-toggle label .can-toggle__switch:after {
  top: 0;
  left: -2px;
  border-radius: 50px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.can-toggle--size-small input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(44px, 0, 0);
          transform: translate3d(44px, 0, 0);
}
.can-toggle.can-toggle--size-small input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.can-toggle--size-small input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-small label {font-size: 13px;}
.can-toggle.can-toggle--size-small label .can-toggle__switch {
  height: 28px;
  -webkit-flex: 0 0 90px;
      -ms-flex: 0 0 90px;
          flex: 0 0 90px;
  border-radius: 2px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:before {
  left: 45px;
  font-size: 10px;
  line-height: 28px;
  width: 45px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:after {
  top: 1px;
  left: 1px;
  border-radius: 1px;
  width: 44px;
  line-height: 26px;
  font-size: 10px;
}
.can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-large input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.can-toggle--size-large input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-large input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(78px, 0, 0);
          transform: translate3d(78px, 0, 0);
}
.can-toggle.can-toggle--size-large input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.can-toggle--size-large input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.can-toggle--size-large label {font-size: 14px;}
.can-toggle.can-toggle--size-large label .can-toggle__switch {
  height: 50px;
  -webkit-flex: 0 0 160px;
  -ms-flex: 0 0 160px;
  flex: 0 0 160px;
  border-radius: 4px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
  left: 80px;
  font-size: 14px;
  line-height: 50px;
  width: 80px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 78px;
  line-height: 46px;
  font-size: 14px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
  color: rgba(181, 62, 116, 0.5);
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #9ACEB1;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #fff;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label {
  color: #02843D;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label:hover {
  color: #39916a;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #fff;
  left:0;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
  background-color:#9ACEB1;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-1 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  color: #fff;
}
.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  -webkit-transition: background-color 0.3s ease-in-out;
          transition: background-color 0.3s ease-in-out;
  background: #bdc6c1;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: #fff;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  color: #fff;
  background:#02843D;
}
.dropdown-toggle::after {
    content:"\f078";
    font-family: "FontAwesome";
    font-size: 10px;
    color:#fff;
    right:8px; top:4px;
    padding:0 4px 2px;
    border-top:0;
    pointer-events:none;
}
.btn-toggle.btn-lg.active { transition: background-color 0.25s;}
.btn-toggle.btn-lg {
    margin: 0 5rem;
    padding: 0;
    position: relative;
    border: none;
    height: 2.5rem;
    width: 5rem;
    border-radius: 2.5rem;
}
.btn-toggle.active {    background-color: #02843D ;}
.example .btn-toggle {
    top: 50%;
    transform: translateY(-50%);
}
.btn-toggle.btn-lg.active:before {  opacity: 0.5;}
.btn-toggle.btn-lg:before {
    content: 'Off';
    left: -5rem;
}
.btn-toggle.btn-lg:before, .btn-toggle.btn-lg:after {
    line-height: 2.5rem;
    width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}
.btn-toggle:before, .btn-toggle:after { color: #6b7381;}
.btn-toggle.btn-lg.active > .handle {
    left: 2.8125rem;
    transition: left 0.25s;
}
.btn-toggle.btn-lg > .handle {
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 1.875rem;
    background: #fff;
    transition: left 0.25s;
}
.btn-toggle.btn-lg.active:after {opacity: 1;}
.btn-toggle.btn-lg:after {
    content: 'On';
    right: -5rem;
    opacity: 0.5;
}
.btn-toggle.btn-lg:before, .btn-toggle.btn-lg:after {
    line-height: 2.5rem;
    width: 5rem;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}
.btn-toggle:before, .btn-toggle:after { color: #6b7381;}
.progress{
  height:13px;
  margin-top:8px;
}
#flavor .modal-dialog, #discount .modal-dialog { height:100%;}
#flavor .modal-content, #discount .modal-content{
  border-radius:0;
  background-color:#fff;
  background-size:cover;
  position:relative;
  width:100%;
}
#flavor .close, #discount .modal-close{
  color:#4A4A4A;
  text-shadow:none;
  opacity:inherit;
  font-size:2rem !important;
  background:none;
}
#flavor .modal-title, #discount .modal-title{
  color:#4A4A4A;
  margin-left: 10px;
  margin-top: 5px;
}
#flavor .modal-header, #discount .modal-header
{
  border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.green span { border-color: #549b2b #4f9329 #4b8b27;}
#flavor .modal-body, #discount .modal-body
{
    padding-left: 30px;
    color: #4A4A4A;
    padding-top: 0px;
}
.modal-backdrop.show{
  opacity: 0.5;
  position: sticky;
}
#flavor .progress{
  height:7px;
  margin-top:8px;
  background-color:#707070;
  border-radius:0;
}
#flavor .progress1{
 padding-left: 10%;
    width: 120px;
    height: 5px;
    display: inline-block;
    float: right;
    margin-top: 12px;
  }
#flavor .progress2{
  padding-left: 10%;
  width: 120px;
  height: 5px;
  display: inline-block;
  float: right;
}
#flavor .progress-bar{background-color:#02843D;}
#flavor .progress .down1{
  color: #FC8F3F;
  position: absolute;
  font-size: 15px;
  top: 20px;
}
#flavor .progress .down2{
  color: #FC8F3F;
  position: absolute;
  font-size: 15px;
  top: 40px;
}
#flavor .progress .down3{
  color: #FC8F3F;
  position: absolute;
  font-size: 15px;
  top: 60px;
}
#foodpreferencematch .modal-dialog {  height:100%;}
#foodpreferencematch .modal-content{
  border-radius:0;
  width:100%;
}
#foodpreferencematch .close{
  color:#000;
  text-shadow:none;
  opacity:inherit;
  font-size:2rem !important;
  background:none;
}
#foodpreferencematch .modal-title{  margin-left: 10px;}
#foodpreferencematch .modal-header
{
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#foodpreferencematch .progress{
  height:7px;
  margin-top:8px;
  background-color:#707070;
  border-radius:0;
}
#foodpreferencematch li.select-suitable-dish{
  background-color:#FAFAFA;
	padding:5px;
}
#foodpreferencematch .progress1{
  padding-left: 10%;
  width: 120px;
  height: 5px;
  display: inline-block;
  float: right;
  margin-top: 12px;
}
#foodpreferencematch .progress2{
  padding-left: 10%;
  width: 120px;
  height: 5px;
  display: inline-block;
  float: right;
}
#foodpreferencematch .progress-bar{ background-color:#02843D;}
#foodpreferencematch .progress .down1{
  color: #FC8F3F;
    position: absolute;
    font-size: 15px;
    top: 20px;
}
#foodpreferencematch .progress .down2{
  color: #FC8F3F;
  position: absolute;
  font-size: 15px;
  top: 40px;
}
#flavor .progress .down3{
  color: #FC8F3F;
  position: absolute;
  font-size: 15px;
  top: 60px;
}
/* end of food perfernce */
#pref .modal-dialog {  height:100%;}
#pref .modal-content{
  border-radius:0;
  background-color:transparent;
  background-image: url('../images/backimage.jpg'); background-size:cover; position:relative;
  width:100%;
  height:100%;
}
#pref .close{
  color:#FC8F3F;
  text-shadow:none;
  opacity:inherit;
  font-size:2rem;
}
#pref .modal-title{
  color:#F1F4F3;
  margin-left: 38px;
  margin-top: 32px;
}
#pref .modal-header
{
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
#pref .modal-body{
  padding-left: 54px;
  color: #F1F4F3;
  padding-top: 0px;
}
#pref .badge {width:30px; height:15px;}
#pref .badge-green {background:#02843D; color:#F1F4F3; border-radius:50px; font-size:10px;padding:2px;margin-left:5px;}
#pref .fa-check{
    margin-right: 8px;
    vertical-align: text-top;
}
#discount_flip_div .social-links {text-align:center;}
#discount_flip_div .social-links a {
  font-size: 24px !important;
  display: inline-block;
  background: #fff;
  color: #02843D;
  line-height: 1;
  padding: 0;
  margin-right:30px;
  transition: 0.3s;
}
.steporange {
    color: white;
    background: #fff;
    color: #fc8f3f;
    display: block;
    border-radius: 50%;
    font-weight: bolder;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 20px;
    font-size: 13px;
    padding-top: 20px;
    border: 2px solid #fc8f3f;
}
#discount_flip_div .social-links a:hover {
  background: #02843D;
  color: #707070;
}
#discount_flip_div .modal-dialog {  height:100%;}
#discount_flip_div .modal-content{
  border-radius:8px;
  background-image: linear-gradient(to right, #1e8444, #028255);
  width:100%;
  height:auto;
  padding:15px 0px;
}
#discount_flip_div .close{
  color:#fff;
  text-shadow:none;
  opacity:inherit;
  font-size:2rem;
  background:none;
}
#discount_flip_div .modal-title{
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 17px;
}
#discount_flip_div .modal-header{
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom:0;
  padding-top:3px;
}
#discount_flip_div .modal-body{  padding-top: 2px;}
#discount_flip_div .modal-footer{
    height: 95px;
    display: inherit;
    border-bottom-right-radius: 30px;
   border-bottom-left-radius: 30px;
}
#discount_flip_div hr {margin:0.5rem 0rem;}
#discount_flip_div .connected-steps {
  display: flex;
  width: 100%;
  align-items: center;
}
#discount_flip_div .step {
  color: white;
  background: #fff;
  color:#4caf50;
  display: block;
  border-radius: 50%;
  font-weight: bolder;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  padding-top: 20px;
  border: 2px solid #4caf50
}
#discount_flip_div .connector {
  flex-grow: 1;
  width: 10px;
  content: "";
  display: block;
  height: 1px;
  background-color: red;
}
.navbar a{    padding: 5px 10px;}
@media screen and (min-width:320px) and (max-width: 374px){
  .font-12{    font-size: 9px !important;  }
  #results .restimageeclipse {
  height: 105px !important;
  width: 101.24px !important;
}
.col-md-4.div_home_other_btn_top{  margin-top: 20px;}
 #discount_flip_div .connected-steps .step {
   font-size: 12px;
   line-height:16px;
   padding: 15px 6px 0 6px;
 }
  #discount_flip_div .subhading {	 font-size: 13px;  }
  #earn_discount .info-percentage{
    left: 76% !important;
  }
  .home_order_btns label span.span-check {font-size: 10px !important;}
  #reward_divmobo {margin-right: 12% !important;}
  a.home-mobile-logo img{right: 31% !important;}
}
#how .step-1{
  position: absolute;
  top: 93px;
  left: 50px;
  font-weight:bold;
  z-index: 2;
  color:#02843D;
  font-size:20px;
  padding-top:4px;
}
#how .step-2{
  position: absolute;
  top: 93px;
  left: 160px;
  font-weight:bold;
  z-index: 2;
  color:#02843D;
  font-size:20px;
  padding-top:4px;
}
#how .step-3{
  position: absolute;
  top: 93px;
  left: 270px;
  font-weight:bold;
  z-index: 2;
  color:#02843D;
  font-size:20px;
  padding-top:4px;
}
#how .step-bg{
  border: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50px;
  background: #fff;
  text-align:center;
  box-shadow: 1px 4px 12px #333333;
}
#how .social-links {  text-align:center;}
#how .social-links a {
  font-size: 24px;
  display: inline-block;
  background: #02843D;
  color: #fff;
  line-height: 1;
  padding: 0;
  margin-right:30px;
  transition: 0.3s;
}
#how .social-links a:hover {
  background: #02843D;
  color: #707070;
}
#how .modal-dialog {  height:100%;}
#how .modal-content{
  border-radius:0;
  background-color:#000;
  width:100%;
  height:100%;
  padding:15px 20px;
}
#how .modal-bg {
  border-radius:30px;
  background:#02843D;
}
#how .close {
  color:#fff;
  text-shadow:none;
  opacity:inherit;
  font-size:2rem;
}
#how .modal-title {
  color: #fff;
  text-align: center;
  font-size: 24px;
  padding: 15px 0 15px 50px;
}
#how .modal-title1 {
  color: #02843D;
  text-align: center;
  font-size: 24px;
  font-weight:bold;
}
#how .modal-header
{
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom:0;
  padding-top:3px;
}
#how .modal-body{
  text-align:center;
  color: #02843D;
  padding: 5px 27px;
  background: #fff;
}
#how .row{
  text-align: left;
  box-shadow: 1px 5px 5px #707070;
  margin-top: 5px;
  height: 100px;
}
#how .modal-footer{
    height: 95px;
    display: inherit;
    background: #02843D;
    border-bottom-right-radius: 30px;
   border-bottom-left-radius: 30px;
}
#how hr {margin:0;}
#how .earn-bg
  {background:rgba(255, 255, 255, 0.08); padding:15px 0;}
#how .earn-bg1
  {background:rgba(255, 255, 255, 0.2); padding:15px 0;}
#how .earn-bg2
  {background:#fff; padding:22px 18px; height: 124px;}
#how .green-bg
  {background: rgba(2, 132, 61, 0.4);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    position: absolute;
  color:#fff; text-align:center; padding-top: 20px;
    font-size: 13px;}
#how .connected-steps {
  display: flex;
  width: 100%;
  align-items: center;
  padding-left:17px;
}
#how .step {
    color: white;
    background: #02843D;
    display: block;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 20px;
    font-size: 13px;
    padding-top: 20px;
}
#how .connector {
  flex-grow: 1;
  width: 10px;
  content: "";
  display: block;
  height: 1px;
  background-color: red;
}
#how .fa-arrow-right  {  padding:0 15px 0 0;}
#how .col1{
  font-size: 13px;
  background: #02843D;
  color: #fff;
  padding-top: 10px;
  max-width: 30%;
}
#how .col1l{
  font-size: 24px;
  background: #fff;
  color: #02843D;
  padding-top: 10px;
  max-width: 53%;
  font-weight: bold;
}
#how .col2{
  font-size: 24px;
  background: #fff;
  color: #FC8F3F;
  padding-top: 10px;
  max-width: 40%;
  line-height: normal;
}
#how .col2l{
  font-size: 24px;
  background: #fff;
  color: #FC8F3F;
  padding-top: 10px;
  max-width: 47%;
  line-height: normal;
}
#how .col2 .upto
{
    font-size: 13px;
    color: #FC8F3F;
    position: absolute;
}
#how .col2 .plus{
  font-size: 30px;
  font-weight: bold;
  word-wrap: inherit;
  vertical-align: super;
}
#how .col2 .cnt{
  font-size: 48px;
  font-weight: bold;
}
#how .col2 .percent{
  font-size: 30px;
  font-weight: bold;
  vertical-align: top;
}
#how .col2l .upto{
  font-size: 13px;
  color: #FC8F3F;
  position: absolute;
}
#how .col2l .plus{
  font-size: 30px;
  font-weight: bold;
  word-wrap: inherit;
  vertical-align: super;
}
#how .col2l .cnt{
  font-size: 48px;
  font-weight: bold;
}
#how .col2l .percent{
  font-size: 30px;
  font-weight: bold;
  vertical-align: top;
}
#how .col3{
  font-size: 10px;
  background: #fff;
  color: #4a4a4a;
  padding: 10px;
  line-height: 12px;
  max-width: 30%;
  text-align:center;
}
#how .txt{
  font-size: 10px;
  background: #fff;
  color: #4a4a4a;
  position:absolute;
  line-height: 12px;
  text-align:center;
}
#how-black .modal-dialog {  height:100%;}
#how-black .modal-content{
  border-radius:0;
  background-color:#000;
  width:100%;
  height:100%;
  padding:15px 5px;
}
#how-black .modal-bg {
  border-radius:30px;
  background:#3C3C3C;
}
#how-black .close {
  color:#fff;
  text-shadow:none;
  opacity:inherit;
  font-size:2rem;
  background:transparent !important;
}
#how-black .modal-title {
    color: #fff;
    text-align: center;
    font-size: 24px;
    padding: 15px 0 15px 50px;
}
#how-black .modal-title1 {
  color: #02843D;
  text-align: center;
  font-size: 24px;
  font-weight:bold;
}
#how-black .modal-header{
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom:0;
  padding-top:3px;
}
#how-black .modal-body{
  text-align:center;
  padding: 0 ;
}
#how-black .row{
  text-align: left;
  margin:0;
}
#how-black .col1{
  font-size: 13px;
  background: #2F2F2F;
  color: #fff;
  max-width: 34%;
  border-bottom-left-radius:30px;
  text-align:center;
}
#how-black .col2{
  font-size: 13px;
  background: #222222;
  color: #fff;
  max-width: 33%;
  text-align:center;
}
#how-black .col3{
  font-size: 13px;
  background: #171717;
  color: #fff;
  text-align:center;
  max-width: 33%;
  border-bottom-right-radius:30px;
}
#how-black .cnt1{font-size:40px; text-align:center;}
#how-black .icon {
  width: 60px;
  height: 60px;
  padding-top: 12px;
  text-align: center;
  position: relative;
  background-color: #02843D;
  border-radius: 50%;
  text-align: center;
  left: 22%;
  transition: 0.3s;
  box-shadow:0px 5px 12px rgba(0, 0, 0, 0.08);
}
#how-black i {
  font-size: 40px;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
}
#how-black .col2 .plus{
    font-size: 30px;
    font-weight: bold;
    word-wrap: inherit;
    vertical-align: super;
  color:#FC8F3F;
}
#how-black .col2 .cnt{
  font-size: 48px;
  font-weight: bold;
  color:#FC8F3F;
}
#how-black .col2 .percent{
  font-size: 30px;
  font-weight: bold;
  vertical-align: top;
  color:#FC8F3F;
}
#how-black .col3 .plus{
  font-size: 15px;
  font-weight: bold;
  word-wrap: inherit;
  vertical-align: super;
  color:#FC8F3F;
}
#how-black .col3 .cnt{
  font-size: 40px;
  font-weight: bold;
  color:#FC8F3F;
}
#how-black .col3 .percent{
  font-size: 15px;
  font-weight: bold;
  vertical-align: top;
  color:#FC8F3F;
}
.modal {  background: #0003;}
/* nav-pills*/
.nav-rounded{
  background:#fff;
  color:#fff;
  box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.1);
  height: 46px;
   border:2px solid #ccc; 
  border-radius: 30px !important;
}
.nav-rounded {
  background: #fff;
  color: #fff;
  /* box-shadow: inset 0 1px 10px rgb(0 0 0 / 10%); */
  /* height: 40px; */
  /* border: 2px solid #ccc; */
}
.nav-rounded .nav-link{
  background:#fff;
  color:#000;
  /* display: block;
  padding: .1rem 0.8rem; */
  border:0;
}

.nav-rounded .nav-item{
  color:#fff;
  border-radius:50px;
}
.nav-rounded.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #02843D;
  border-radius: 30px !important ;
  height: 42px !important;
}
@media (min-width: 1025px) and (max-width: 1500px) {
.modal-dialog{  margin:0;}
}
@media (min-width: 1000px) and (max-width: 1024px) {
.modal-dialog{  margin:0;}
}
@media (min-width: 768px) and (max-width: 999px) {
.modal-dialog{  margin:0;}}
@media (min-width: 576px){
  .modal-dialog {  margin: auto;}
  #videopage .modal-dialog{
    max-width: 832px;
    margin: auto;
  }
}
.white{color:#ffffff !important;}
.black{color:#000000 !important;}
.green {color:#02843D !important;}
.red {color:#d9534f !important;}
.orange {color:#FC8F3F;}
.dark-grey {color:#4A4A4A;}
.grey {color:#707070;}
.grey-light {color:#BDC6C1;}
.grey-lighter {color:#E8ECEA;}
.grey-lightest {color:#F1F4F3;}
.font-10 {font-size:10px;}
.font-11 {font-size:11px !important;}
.font-12 {font-size:12px !important;}
.font-13 {font-size:13px;}
.font-14 {font-size:14px !important;}
.font-16 {font-size: 16px !important;}
.font-15 {font-size:15px !important;}
.font-18 {font-size:18px !important;}
.font-20 {font-size:20px !important;}
.font-21 {font-size:21px !important;}
.font-22 {font-size: 22px !important;}
.font-24 {font-size:24px !important;}
.font-40 {font-size:40px !important;}
.font-48 {font-size:48px !important;}
.font-96 {font-size:96px !important; font-weight: bold;}
.font-36 {font-size:36px !important;}
.font-32 {font-size:32px !important;}
.font-weight-700 {font-weight:700 !important;}
.cursor-pointer {cursor: pointer;}
.text-underlined {text-decoration: underline;}
/* for food prefrnace */
#food-pref {
  background: #fff;
  padding: 27px 0 40px 0;
}
#food-pref::before {
  content: '';
 left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;
}
#food-pref .container {
  position: relative;
  z-index: 10;
}
#food-pref .pref {
  padding:15px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}
#food-pref h4{
    color: #02843D;
    font-size: 20px;
    font-weight: normal;
    margin-bottom:20px;
}
#food-pref .input-group {
    border-radius: 50px;
    border: 1px solid #BDC6C1;
    height: 45px;
 }
#food-pref .input-group-text {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    background: #fff;
    border: 0;
}
#food-pref .form-control {    font-size: 13px;}
input#dietary-search{
  height: 43px;
  border-radius: 50px 0px 0px 50px;
  padding-left: 10px;
  font-size: 15px;
  border: 0.5px;
}
#food-pref .form-control {
    height: 41px;
    padding-left: 10px;
    font-size: 15px;
    border-radius: 50px;
}
#food-pref  .fa-info-circle{
  color:#DADADA;
  font-size:18px;
  margin-left:5px;
}
#rest-menu-filter-form .fa-info-circle, #menu-filter-form .fa-info-circle{
  color:#02843D;
  font-size:18px;
  margin-left:5px;
}
.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #BDC6C1;
}
.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
    top: 0;
    border-bottom-color: #BDC6C1;
}
.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
    margin-top: .5rem;
}
/* .popover {
    max-width: 295px;
    font-family:inherit;
    font-style: normal;
    line-height: 1.4;
    font-size: 12px;
    border: 1px solid #BDC6C1;
    border-radius: 0;
} */
#food-pref .select-btn{
    height: 27px;
    width: 78px;
    font-size: 12px;
    padding: 0 8px;
    border: 1px solid #02843d !important;
}
#food-pref .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #8A8A8A;
  border-color: unset;
	min-width:80px;
}
#food-pref .dropdown-menu {
    font-size: 15px;
    text-align: left;
    list-style: none;
    background-color: #8A8A8A;
    background-clip: padding-box;
    border: 0;
    border-radius: 50px;
  min-width:6.5rem;
  padding:.02rem 0;
}
#food-pref .dropdown-item {
  color:#fff;
  padding:.22rem 1.1rem;
  font-weight:normal;
}
/* end of food prefrance*/
#results .menuItemsId .results-col .icon{  width: 69%;}
#results .menuItemsId .star-rating{ margin-left: 11px;}
#results .results-col .carouselflip .icon {
   width: 30%;
   height: 40px;
  text-align: center;
  position: absolute;
  background-color: #02843d;
  text-align: center;
  transition: .3s;
  left: 0;
  top: 0;
  color: #fff;
  line-height: 23px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
#results a.icon.flip-action.topratedflip{  width: 30%;}
#results .results-col .icon {
  width: 66%;
  height: 31px;
  text-align: center;
  position: absolute;
  background-color: #02843d;
  text-align: center;
  transition: .3s;
  left: 0;
  top: 0;
  color: #fff;
  line-height: 16px;
  border-top-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.padding-0{  padding: 0px;}
#results .star-rating {
  line-height:14px;
  font-size:13px;
 }
#results .star-rating .fa-star{color: #FC8F3F;font-size:13px;}
.topclass{
  margin-top: 40px;
  margin-bottom:20px;
}
.item-venue-address{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0015em;
  color: #4A4A4A !important;
}
#results .results-col .price-title{
  font-size:14px;
  float:right;
}
#results .results-col .mune-img {
  position: relative;
  min-width: 100%;
  margin-top: 0;
}
#search-menuItemsDiv .results-col .mune-img {
  position: relative;
min-width: 100%;
  margin-top: 0;
  height: 160px;
}
#search-menuItemsDiv .flip {
  -webkit-perspective: 800;
   height: 28rem;
    position: relative;
    transform-style: preserve-3d;
}
.availablenowbutton{
   background: none !important;
    color: #02843D !important;
    font-family: Lato !important;
    font-style: normal !important;
    font-size: 15px !important;
    line-height: 150% !important;
    display: flex !important;
    align-items: flex-end !important;
    text-transform: capitalize!important;
    font-weight: 600 !important;
}
@media screen and (max-width: 1199px){
  #results .results-col .price-title{font-size:14px !important;}
  #results .star-rating {font-size: 10px !important;}
}
.btn-font-size{font-size:15px;}
@media screen and (max-device-width: 1000px) and (min-device-width: 690px) {
  p.greyText {    margin-bottom:0px;  }
}
p.greyText {  margin-bottom:0px;}
.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: #BDC6C1 1px solid;
}
.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #fff;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #02843D;
    color: #fff;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #02843D;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #02843D;
    color: #fff;
}
.preference-items input[type="checkbox"]+span.icon-checkbox:before, .natext input[type="checkbox"]+span.icon-checkbox:before{
  position:relative;
  top:4px;
  line-height:15px !important;
}
.presentation{
width: 100%;
text-align: center;
border: none !important;
}
.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
    border-right-color: #BDC6C1 !important;
}
.label.custom-control-label.foodlabel{ margin-top: .1em;}
.text-referal-credit{
  word-break: break-all;
  padding: 0;
  margin: 0;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.swal2-container {
  z-index: 99999 !important; /* Ensure it appears above other elements */
  opacity: 1 !important; /* Ensure visibility */
}

button#uncheckAllDietaryPrefs{
  padding: 12px 10px;
  width: 193px;
  height: 43px;
  background: #4A4A4A;
  border: 2px solid #4A4A4A;
  box-sizing: border-box;
  border-radius: 65.8333px;
  line-height: 12px;
}
label.btn.btn-s.btn-info.NDP {
  padding: 12px 10px;
  width: 193px;
  height: 43px;
  background: #4A4A4A;
  border: 2px solid #4A4A4A;
  box-sizing: border-box;
  border-radius: 65.8333px;
  line-height: 12px;
}
.dietary-search{
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #4A4A4A;
  box-sizing: border-box;
  border-radius: 30px;
}
#flavor .badge {width:30px; height:15px;}
#flavor .badge-green {background:#02843D; color:#F1F4F3; border-radius:50px; font-size:10px;padding:2px;margin-left:5px;}
#flavor .fa-check{
    margin-right: 8px;
    vertical-align: text-top;
}
#flavor .hr{
  margin: 0.5rem 0rem 0 -2.5rem;
border: 0;
border-top: 1px solid #BDC6C1;
}
.hr{
  margin: 0.5rem 1rem;
border: 0;
border-top: 1px solid #BDC6C1;
}
.foodclass {
  margin-top: 42px;
  margin-left: 35px;
}
.static-content{
    background: #f7f7f7;
    padding: 32px;
	margin-bottom:20px;
}
#private_room .private_room_css {
  height:32px;
  border:0;
  padding:12px;
}
#private_room_1 .private_room_css {
  height:32px;
  border:0;
  padding:12px;
}
#private_room_2 .private_room_css {
  height:32px;
  border:0;
  padding:12px;
}
.input-group-private-room {
  border-radius:50px;
  border:2px solid #02843D;
  height:36px;
}
#private_room .input-group-private-room {
  border-radius:50px !important;
  border:2px solid #02843D !important;
  height:36px !important;
}
#private_room_1 .input-group-private-room {
  border-radius:50px !important;
  border:2px solid #02843D !important;
  height:36px !important;
}
#private_room_text {  margin-bottom: 20px !important;}
#private_room_2 .input-group-private-room {
  border-radius:50px !important;
  border:2px solid #02843D !important;
  height:36px !important;
}
#private_room, #private_room_1, #private_room_2 {
  padding: 10px;
}
.about_menu {  padding-left:15px;}
.search-my-restaurant {

    border-width: 2px;
    text-decoration: underline;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    color: #02843D !important;
    border-radius: 3px;
    padding:6px 12px;
    line-height:1.42857143;
}
.search-my-restaurantlabel {
    background: yellow;
    border-width: 2px;
    border-style:dashed;
    border-color: mediumseagreen;
    text-align: center;
    margin-bottom: 5px;
    color: #333;
    padding:6px 12px;
    line-height:1.42857143;
}
.add-restaurant {
    width: 199px;
    background: #fff;
    border-width: 2px;
    margin-bottom: 5px;
    color: #02843D !important;
    padding:6px 12px;
    border-radius: 5px;
    line-height:1.42857143;
	  margin-top: 30px;
    margin-bottom:30px;
    text-align: center;
	  display: inline-block;
}
.mune-img.item-image-container.homepageimage{
  width: 100%;
  height: 300px;
  overflow: hidden;
  background:#f6f6f6
}
#rest .rest-col .img {  position: relative;}
#rest .rest-col .img img {  border-radius: 4px 4px 0 0;}
#rest .rest-col .icon {
  width: 238px;
  height: 238px;
  padding-top: 42px;
  text-align: center;
  position: relative;
  left: calc( 40% - 70px);
  transition: 0.3s;
  /* box-shadow:0px 5px 12px rgba(0, 0, 0, 0.08); */
}
#rest .rest-col h4 {
  color: #4A4A4A;
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  margin: 45px 0 15px 0;
  line-height:40px;
}
#rest .rest-col h4 a {  color: #4a4a4a;}
#rest .rest-col h4 a:hover {  color: #02843D;}
#rest .rest-col p {
  font-size: 20px;
  line-height: 24px;
  color: #4a4a4a;
  margin-bottom: 0;
  padding: 0 0 20px 0;
}
#rest {
    display: table;
    width: 100%;
    background: #fff;
}
#rest .rest-container {
    background-image: url(../images/restaurant-header-image.jpg);
    background-size: cover;
    position: relative;
    height: 561px;
}
#rest h2 {  color: #fff;  font-size:2.6rem;}
#rest a {
  color: #fff;
  text-decoration:underline;
  font-size:26px;
}
#rest .white-container{
  bottom: 0;
  position: absolute;
  right:30px;
	left:30px;
}
@media (max-width: 414px) {
#rest .white-container-col{	padding:5px !important;}
#rest .white-container h3{	margin:0;}
#rest .white-container p{	margin:0;}
#rest h2{	line-height:2.5rem;}
}
#rest .white-container-col{
  background: #fff;
  opacity: 0.9;
  padding: 30px 30px 0 30px;
}
#rest .white-container h3{
	color:#4B4B4B;
	font-size:24px;
}
#rest .white-container p{
	color:#4B4B4B;
	font-size:20px;
}
.rest-cols a {
  color: #02843D !important;
  text-decoration:none !important;
  font-size:16px !important;
}
#popuppartner .form-group{	display:-webkit-box;}
@media (min-width: 1024px) {
  #popuppartner .modal-dialog {
      margin-left: auto;
      margin-right: auto;
      top:50px;
  }
}
#popuppartner .form-control {
	border-radius: 0.25rem;
	height:45px;
}
#popuppartner .form-group {
    margin-bottom: 1rem;
    padding: 0 30px;
	display:-webkit-box;
}
#popuppartner .modal-content{	border:1px solid #02843D;}
#popuppartner .red{
    color: #CE0F02;
    position: absolute;
    padding: 7px;
    font-size: 24px;
}
#span_Partner_Name {
  position: absolute;
  left: 85px;
}
#span_Partner_phonenumber {
  position: absolute;
  left: 138px;
}
#span_email {
  position: absolute;
  left: 146px;
}
#popuppartner .modal {
    background: #fff !important;
    z-index: 1050;
}
#popuppartner .close{
    z-index: 1000;
    top: -8%;
    position: absolute;
    color: grey;
    text-shadow: none;
    opacity: 10;
    right: -82%;
    font-size:2rem;
    background:transparent !important;
}
.section-ft h3 {
    font-size: 40px;
    color: #4a4a4a;
    text-align: center;
    font-weight: bold;
    position: relative;
    line-height:50px;
}
.section-ft p {
  font-size: 24px;
  line-height: 24px;
  color: #4a4a4a;
  margin-bottom: 20px;
  padding: 0 0 20px 0;
  text-align:center;
  line-height:40px;
}
.partner-btn {
    border-radius: 5px !important;
    width: 320px;
    height: 70px;
    font-size: 24px !important;
    color:#fff !important;
}
.partner-btn-popup{
    height: 56px;
    background-color: #02843d;
    border-radius: 0.25rem;
    color: #fff;
    font-size: 24px;
    border: 0;
}
.about_menu .social-links a {
  display: inline-block;
  background: #fff;
  color: #02843D;
  line-height: 1;
  padding: 0;
  margin-right:16px;
  transition: 0.3s;
}
.about_menu .social-links a:hover {
  background: #fff;
  color: #707070;
}
.about_menu .share{
  color: #BDC6C1;
  text-transform: uppercase;
  font-size: 10px;
	letter-spacing:2px;
}
.food_preference {  cursor:pointer;}
.header-bg{
  background: #fff;
  height: 55px;
  padding-top: 6px;
  padding-left: 0px;
  margin: 0;
  width:100%;
}
label.form-check-label.pickup{  margin-top: 1px !important;}
a.social_share_bton{
  padding:2px 21px!important;
	height:32px!important;
}
.buttn-diet-slect{
	background:#fff;
	border:3px solid #bec7c2;
  border-right: 0px!important;
  padding: 5px;
}
.diet-butons-display{	display:inline-flex;}
.divid-page-or{	 position:relative;}
/*For flavor match popup*/
.flavor-match .flavor-title {margin-top: 20px;}
#flavor .flavor-match .flavor-title {margin-top: 10px;}
.flavor-match .flavor-slider span.label {font-size:12px;}
#flavor .item-score {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  filter: none;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
  border: 0px solid transparent;
  border-width: 10px 8px 0 8px;
  width: 0;
  height: 0;
  border-top-color: #FC8F3F;
  margin-top: -10px;
}
#flavor .legend {
  width: 20px;
  display: inline-block;
}
#flavor .explain {
  display: inline-block;
  font-size: 0.7em;
  margin-left: 5px;
}
.flavor-match .slider.slider-horizontal {width: 280px;}
.table thead tr th{  color:#fff;}
.table thead th{ border-bottom: 0px;}
.view-venue-btn{
  font-size:15px !important;
  margin-top:1rem;
}
.parent-wrapper-container{	margin-top:85px;}
.input-group-rt {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background: #fff;
    border: 0;
    padding: 0.575rem .85rem;
}
.table-view-btn a {	padding: 8px 8px;}
.btn-unfriend {
	padding: 8px 15px!important;
	font-size:15px!important;
}
.diet-butons-display a:last-child{
		border-right:3px solid #bec7c2!important;
}
.buttn-diet-slect-row{
	background:#fff;
	border:3px solid #bec7c2;
  border-right: 0px!important;
  padding: 5px;
}
#preview_agreements_modal .model-lg-center{
	max-width:800px;
}
.guru-portion-text-font span{	font-size:13px;}
.btn-unfriend {
	padding: 8px 15px!important;
	font-size:15px!important;
}
.label-term-conditions a {
	padding: 0px 0px 0px 10px;
	font-size:15px;
}
.fa-chevron-left,.fa-chevron-right {font-size: 30px; color: #02843d;}
 .carousel-control-prev {
    left: 3%;
    width: 30px;
    margin-bottom: 0%;
 }
 .carousel-control-next {
    margin-right: 3%;
    width: 30px;
    margin-bottom: 0%;
 }
#searchRestaurantForm .slider {	width: 88%;}
.rangeinput {
    float: right;
    width: 125px;
    padding: 6px 7px 0 2px !important;
}
.rangeslider{    width:960px !important;}
.rangeinput input {
    width: 40px;
    float: left;
    margin-right: 5px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 5px 0px;
}
.miradius {
    line-height: 30px;
	width: 120px;
}
.free-food-range-slider {  width: 915px !important;}
.free-food-rangeinput input {
  width: 50px;
  float: left;
  margin-right: 5px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0px 2px;
  font-size: 14px;
}
.distance-container.free-food-container {width: 150px !important;}
.free-food-rangeinput {
  float: right;
  width: 110px;
  padding: 4px 7px 0 7px !important;
}
.free-food-rangeinput  .miradius {  line-height: 22px;}
.slidecontainer .slider {
  height: 41px !important;
  margin-top: 0px !important;
  margin-left: 5px !important;
  background-color: white !important;
}
@media screen and (max-width:1200px){
  .rangeslider {width: 770px !important;}
}
@media screen and (max-width:994px){
.slider-infl{    width: 80%;}
.rangeslider { width: 530px !important;}
}
@media screen and (max-width:540px){
.rangeslider {width: 60% !important;}
}
@media screen and (max-width:365px){
.rangeslider {width: 50% !important;}
#head-auto-complete-div .autocomplete-homepage-wrapper #autocomplete-results {
    width: 100% !important;

  }
}
input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number].no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #bdc6c1 !important;
    outline: 0;
    background: #fff!important;
}
.select2-container--default .select2-selection--multiple {
    background-color: #fff !important;
    border: 1px solid #bdc6c1 !important;
    cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    border-radius: 2x;
}
.slider-infl{
    width: 87%;
    margin-top: 5px;
    margin-right: 5px;
}

@media (max-width: 511px){
	.slider-infl{    width: 59% !important;  }
  .past-rating-slider-control{	  width:90%;  }
  .rating-control-btn .nav.nav-pills .nav-item{
	  margin-top: 0px;
  }
  .rating-control-btn .nav-rounded .nav-link{
	  /* padding-top: 5px; */
	  font-size:10px;
  }
  /* .rating-control-btn .nav.nav-pills{ margin-left: 12%;  } */
  .rating-control-btn ul { border-radius: 15px;  }
  #form-filter-restaurant { padding: 0px;  }
}
.radius-infl{	border-left: 1px solid #bdc6c1;padding-top:  5px;height: 30px;margin-top: -6px;}
.influncer-rslts{	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);}
.s-switch{	background:transparent;}
.distance-container {
    width: auto;
    padding-left: 3px;
	height:23px;
	font-size:13px;
}
.item-ratings-container{padding-top:.625rem;margin:0 auto;width:25%}
.rating-img{max-width:350px;max-height:350px;margin:0 auto}
.edit-past-review{font-size:1.3em}
.star-ratings-sprite {
  display: inline-flex;
  background: url("/images/star-rating-sprite.png") repeat-x;
  font-size: 0;
  height: 15px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 75px;
  margin: 0;
}
.star-ratings-green {
  display: inline-flex;
  background: url("/images/star-rating-green.png") repeat-x;
  font-size: 0;
  height: 15px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 75px;
  margin: 0;
}
.star-ratings-sprite-rating {
  background: url("/images/star-rating-sprite.png") repeat-x;
  background-position: 0 100%;
  float: left;
  height: 15px;
  display:block;
}
.star-ratings-green-rating {
  background: url("/images/star-rating-green.png") repeat-x;
  background-position: 0 100%;
  float: left;
  height: 15px;
  display:block;
}
.search-filter-icon a .fa-sliders-h{
	background-color:#02843d;
	padding:3px;
	border-radius:4px;
}
.filter-form-width{
	width:100%;
	box-shadow: 0 2px 12px rgba(0,0,0,0.2);
}
.food-pref-filter-btn{font-size:1rem;}
#food-pref .select-btn{	color:#fff!important;}
.expert-form-alignment .preference-items .icon-checkbox{
	float:left;
  padding-left:20px;
}
.expert-form-alignment span {  font-size:14px;}
.expert-form-alignment .preference-items{
  margin-top:0px;
}
.rating-item-restu-name span{ font-weight:bold;}
#removeWords span{font-size:1rem;}
#MenuResult .results-col .price-title{
  font-size:18px;
  float:right;
}
#MenuResult .results-col h2 {
  color: #4a4a4a;
  text-align: left;
  font-weight: 700;
  font-size: 18px;
  padding: 0 5px;
  margin: 15px 0 1px 0;
}
 #MenuResult.item-details {
  padding-left: .625rem;
  width: 60%;
  min-height: 100%;
  margin: 0 auto;
}
#MenuResult .btn-rate {
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  padding: 8px;
  border-radius: 5px;
  transition: 0.5s;
  border: 2px solid #02843D;
  color: #02843D;
  text-align: center;
  width:85px;
  height:40px;
  background:#fff;
}
#MenuResult .btn-rate:hover {
  background: #02843D;
  color: #fff;
  border:2px solid #02843D;
  font-weight: 500;
}
#rating-details p{	margin-bottom: 0.50rem;}
#MenuResult .star-rating .fa-star{color: #FC8F3F;font-size:13px;}
#MenuResult .star-rating {
  line-height:32px;
  font-size:13px;
}
.btn-rest-inline {
	display:inline-flex;
  margin-top: 10px;
}
.btn-rate {
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    padding: 8px;
    border-radius: 25px;
    transition: .5s;
    border: 2px solid #02843d;
    color: #02843d;
    text-align: center;
    width: 75px;
    height: 40px;
    float: right;
    background: #fff;
}
.btn-rate:hover {
  background: #02843D;
  color: #fff;
  border:2px solid #02843D;
  font-weight: 500;
}
.item-yelp-ratings-container {
    margin-top: 0;
    margin-left: 0;
}
.topgreen{
  margin-top: 0.75rem;
  color: #fff;
  background: #02843d;
  padding-left: 5%;
}
.book-rest{	margin-left:75%;}
.table td, .table th {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.fancytree-title{
  width: 100%;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.fancytree-plain.fancytree-container span.fancytree-node.fancytree-active span.fancytree-title {
  background-color: transparent !important;
}
span.fancytree-expander {  display: none !important;}
#other-orders-label {
  cursor: pointer;
  font-size: 18px;
}
#customMenuTree .restaurant-menu-item, #customMenuTree .fancytree-title, #tree-menu-trigger .fancytree-title, #tree-menu-reward .fancytree-title{
	  color: #02843d!important;
    position: absolute;
    display: inline-block !important;
    font-size: 15px !important;
    font-weight: bold;
    padding: 0px 0px 0px 5px !important;
    padding-top: -5px !important;
}
h2, h3 {    color: #4a4a4a;}
@media only screen and (max-width: 768px){
  #results { padding: 0px 0 0px 0 !important;}
  .navbar { margin-left: -19px !important;  margin-right: -19px !important;}
  .navbar a { padding: 0px  0px !important;}
  .modal-lg { top:30%;   max-width: 100% !important;}
  #modal-item-reviews .modal-lg{top: 15% !important;}
  .hatsIcon { right: 7.5% !important;}
}
@media only screen and (min-width: 768px){
  .modal-lg { top:15%;   max-width: 80% !important;}
}
.item-details-btn {
    background: #02843d !important;
    color: #fff !important;
    text-transform: uppercase;
    border: 1px solid #02843d;
    border-radius: 3px !important;
    width: 100%;
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 3px;
}
.edit-remove-btn a{	float:right;}
#collapse_your_order{		margin-left:30px;	}
.cart-item-details .item-price-container p{
	float:right;
	font-weight:bold;
}
.cart-item-details .free-food-coupn{
	float:right;
	font-size:10px;
}
.cart-item-details .item-price, .cart-item-details p {color: black;}
.cart-item-details > a {  display: inline;}
#cart-items-list li .edit-remove-btn input{
	 width: 55px;
	 height: 32px;
   border-radius: 50px;
}
#cart-items-list li .edit-remove-btn .item-quantity-container{
	 display: inline-block;
   float: right;
}
#cart-items-list li .edit-remove-btn-row .flavor-match .sm-text{
		padding-left:40px;
}
.rated-item-price{
	width: 25%;
  display: inline-flex;
  margin-bottom: 10px;
}
.registration-message-container{	margin-bottom:40px;}
#ratingImageCarousel .carousel-control-prev{
    padding: 0;
    top: 40%;
}
#ratingImageCarousel .carousel-control-next{
    padding: 0;
    top: 40%;
}
.btn-warning {
    color: #000 !important;
    background-color: #FC8F3F !important;
    border-color: #FC8F3F !important;
}
table.dataTable.no-footer {
    border-bottom: 1px solid #BDC6C1 !important;
}
table.dataTable thead th, table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid #fff !important;
}
.btn.btn-primary.btn-rectangle{
	border-radius: 0px !important;
	background: #fff !important;
	color: #02843d !important;
    border-top: 3px solid #02843d !important;
	border-bottom: 3px solid #02843d !important;
	border-left: 3px solid #02843d !important;
	border-right: 3px solid #02843d !important;
	padding:8px 24px !important;
}
.btn.btn-primary.btn-highest-select{
	border-radius: 0px !important;
	background: #fff !important;
	color: #02843d !important;
	border-top: 3px solid #02843d !important;
	border-bottom: 3px solid #02843d !important;
	border-left: 3px solid #02843d !important;
	border-right: 3px solid #02843d !important;
	padding: 6px 15px;
}
.btn.btn-primary.selected{
	background: #02843d !important;
	color: #fff !important;
	border-radius:0px !important;
	margin:4px;
}
.btn.btn-primary.selected:last-child{
	border-right:0px solid #02843d !important;
}
.menuItems.highest-rated-menu-item { background: #F1F4F3;}
.custom_highest_rated{
	background: none !important;
	border-bottom: 1px solid #ccc;
}
#carousel-image-0.carousel-image{height:190px;}
.toggle-expert-review {font-weight: normal !important;}
#profileForm .img-circle{
    width: 250px;
    height: 250px;
}
.car-image-rating {  max-width: 350px !important;}
.toggle {  width :138px !important;}
.toggle-group label.toggle-on {
  /* left: 57px; */
  font-size: 12px;
  line-height: 32px;
  width: 66px;
  padding: 0 10px;
  color: #fff ;
  background: #9ACEB1 !important ;
  position: absolute;
  z-index: 5;
  text-transform: uppercase;
  text-align: center;
  opacity:1 !important;
	border:none;
}
.toggle-group label.toggle-off {
  width: 66px;
  line-height: 26px;
  font-size: 12px;
  left: 48%;
  position: absolute;
  z-index: 5;
	background: #9ACEB1 !important;
	color: #fff !important;
  text-transform: uppercase;
  text-align: center;
}
#friendsRequestTable tr td .public_profile_name{ color: #02843d;}
#friendsListTable tr td .public_profile_name{ color: #02843d;}
.rest-menu-filtr-btn .filter-btn-list li a{
  padding: 8px 10px;
	background: #02843d;
	color: #fff;
	border-radius: 50px;
	text-transform: unset;
}
.font-bold{font-weight:bold;}
.text-underline{text-decoration:underline !important;}
#collapseInfluencerDetail .panel-body{padding:20px 35px !important;}
#collapseOrdersHistory .panel-body{padding:20px 35px !important;}
#collapseReviews .panel-body{padding:20px 35px !important;}
#collapsePastRatings .panel-body{padding:20px 35px !important;}
#collapsePhotoGallery .panel-body{padding:20px 35px !important;}
#collapseInfluencerFind .panel-body{padding:20px 35px !important;}
#myRestaurantTable_wrapper {padding:20px 5px !important;}
#collapseFive .panel-body{padding:20px 0 !important;}
#collapseAdminFeatures .panel-body{padding:20px 35px !important;}
#collapsePlaterate .panel-body{padding:20px 0 !important;}
#search-review-loading h3 {font-size:18px;}
#public-ratings-friends h3 {font-size: 20px; color: #02843d; margin-bottom: 0; margin-top: 20px;}
#rating-search-restaurant h3 {font-size:18px;}
#search-review-loading h3 {font-size:18px;}
#form-filter-menuitem h3 {font-size:18px;}
.cal {
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 0 20px 20px 0;
}
.price-tag2 {
    font-size: 20px;
    color: #02843d;
}
.custom-dropdown select {
    padding: 3px 6px !important;
    background-color: #fff;
    font-size: 14px !important;
    color: #4a4a4a;
    border: 1px solid #bdc6c1;
    border-radius: 1.25rem;
    height: 32px;
}
@media screen and (max-width: 420px) and (min-width: 321px){
  .fix-margin-icon{
    margin-top: -8px !important;
    float: none;
  }
  .custom-dropdown select.day-hours-select {
      font-size: 13px !important;
      padding: 0.1rem !important;
  }
  .custom-dropdown select {
    height: 2rem !important;
  	width: 6.5rem !important;
  }
}
.toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}
.toggle-group {
	width:105% !important;
	background:#9ACEB1;
}
.toggle.off .toggle-group {  left: 0%!important;}
.toggle input[type="checkbox"]:checked ~ .toggle-group .toggle-on {
   content: attr(data-checked);
	 background:#02843d!important;
	 color:#fff!important;
}
.toggle input[type="checkbox"]:not(:checked)  ~ .toggle-group .toggle-off {
   content: attr(data-unchecked);
	 background:#02843d!important;
	 color:#fff!important;
}
.toggle.btn.btn-success{
  background: #9aceb1 !important;
  border: 3px solid #02843d; height: 44px;
}
.toggle .toggle-group label.btn.btn-success.toggle-on{
  min-width: 65px;
  height: 40px;
}
.sensoryExp-slider-row span{	font-size:14px;}
.sensoryExp-slider-row.text-quantities p{
	font-size:14px;
}
#submitItem .expert-review-section .row .icon-checkbox{
	margin-right:10px;
	float:left
}
#submitItem .expert-review-section .row .preference-items{
	margin-top:0px;
	font-size:14px;
}
#submitItem .expert-review-section .row p{font-weight:bold;}
#submitItem .expert-review-section .row .slider-outer {padding-top:8px;}
#submitItem .expert-review-section .row span {	font-size:14px;}
#submitItem .expert-review-section .row .na-no-padding{margin-left: 25px;}
.btn-edit-time{
	background:#02843d !important;
	color:#fff;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show>.btn-danger.dropdown-toggle{
	border-radius:.2rem !important;
}
.venue-awards-container .custom-dropdown { margin: 0 !important;}
.save-corkage-btn{	height:unset;}
#byob .btn-primary {
    border: 2px solid #ccc !important;
    border-radius: 0px !important;
}
#byob .btn-success {
    border-radius: 0px !important;
		color: #ffffff !important;
		background: green !important;
}
#byob .unselected {
    background: #ffffff !important;
    height: 43px;
		color: green !important;
}
#byob .btn-group {background-color: #ffffff !important;}
#byob .btn {	margin: 0 !important;}
th {font-weight:normal;}
.past-ratings-slider .slider.slider-horizontal{
	width:100% !important;
}
.my-rating-expert-slider p{	font-weight:bold;}
.my-rating-expert-slider .pull-down{	margin-top:10px;}
.my-rating-expert-slider .not_rated{
	padding-top:10px;
	font-weight:normal!important;
}
.my-rating-expert-slider .slider-labels{margin-bottom:5px !important;}
.expert-form-alignment .preference-items .icon-checkbox{
	float:left;
  padding-left:20px;
}
.expert-form-alignment span {  font-size:14px;}
.expert-form-alignment .preference-items{margin-top:0px;}
/*advance search.css*/
.edit_text {
  font-weight: 500;
  color: #666;
}
label, .stars {  margin-top: .5em;}
.dropdown.open {  margin-bottom: 250px;}
#distance_dropdown {  min-width: 160px;}
#location {  width: 210px;}
.input-group-btn.open {  margin-bottom: 100px;}
.marginVertical {  margin: 1em 0;}
.marginLeft {  margin-left: 2em;}
.panel .panel-heading {  padding-left: 0;}
#advSearch {  text-align: center;}
#byob .btn { text-align: center;}
/*basicsearch.css*/
img.feeling-image{
  height: 32px;
  width: auto;
}
.row-no-padding{
  margin-left: 0;
  margin-right: 0;
}
.row-no-padding>[class*="col-"]{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.write-review {
  height: 42px;
  width: 200px;
}
#registerorlogindiv a {  display: inline-block;}
div.feeling-button {
    background-color: #74c046;
    background-image: none;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: none;
    border: 1px solid black;
}
div.feeling-button:hover {
    background-color: #566f7d;
    color: #fff;
    text-shadow: none;
}
.toogleSelect { background-color: #e41a6a; color: #fff}
/* customize input placeholder color */
#locationinput[type="search"]::-webkit-input-placeholder {
    color: #137dc0 !important;
}
#locationinput[type="search"]:-moz-placeholder { /* Firefox 18- */
    color: #137dc0 !important;
}
#locationinput[type="search"]::-moz-placeholder {  /* Firefox 19+ */
    color: #137dc0 !important;
}
#locationinput[type="search"]:-ms-input-placeholder {
    color: #137dc0 !important;
}
.location-map {
    float: left;
    margin-left: 4px;
    margin-top: 10px;
    position: absolute;
    z-index: 2;
    color: #137dc0
}
img.feeling-image{
  height: 32px;
  width: auto;
}
.row-no-padding{
  margin-left: 0;
  margin-right: 0;
}
.row-no-padding>[class*="col-"]{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.write-review {
  height: 42px;
  width: 200px;
}
#registerorlogindiv a {  display: inline-block;}
div.feeling-button {
    background-color: #74c046;
    background-image: none;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: none;
    border: 1px solid black;
}
div.feeling-button:hover {
    background-color: #566f7d;
    color: #fff;
    text-shadow: none;
}
/*mydeals.css*/
.price-area td {  text-align: right;}
.price-area #total{
  font-size: 18px;
  font-weight: bold;
}
.buy-now-btn{
  height: 50px;
  width: 300px;
  font-size:25px;
}
.wrap-buy-btn{  text-align: center;}
/*my-private-rooms.css*/
.form-room-name {
  color: mediumseagreen;
  border: none;
  background: #e5eaed;
  border-bottom: 1px solid mediumseagreen;
  font-size: 26px;
}
@media (min-width: 420px) {
  .terms-form {
    background: white;
    padding: 20px 30px 0px 30px;
  }
}
label.control-label {  margin: 0;}
#uploadFileMenu {  display: none;}
.custom-dropdown select {
  text-align: center;
  text-align-last: center;
}
/*photo-gallery.css*/
/* overwrite Justified.js default styles */
.photo-container {  overflow: visible !important;}
.image-thumb {
  width: 100%;
  height: auto;
}
/* \overwrite Justified.js default styles */
#photo-gallery {
  border-bottom-style: dashed;
  border-color: mediumseagreen;
  padding-bottom: 25px;
}
#photo-gallery-text {
  visibility: hidden;
  color: mediumseagreen;
}
#photo-gallery-loading {
  font-size: 25px;
  display:none;
}
#photo-gallery-loading span { font-size: 25px;}
#photo-gallery-grid-container {  margin-bottom:50px;}
/*private-roomsearch*/
.tab_view {  margin-bottom: 20px;}
.tab_item {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    text-decoration: none !important;
    color: mediumseagreen;
    border-bottom: 2px solid mediumseagreen;
}
.list-rooms{
  display: inline-block;
  padding-bottom: 30px;
  width: 100%;
}
/* change these if you want */
.raterater-bg-layer { color: rgba( 0, 0, 0, 0.25 );}
.raterater-hover-layer { color: rgba( 255, 255, 0, 0.75 );
}
.raterater-hover-layer.rated { /* after the user selects a rating */
    color: rgba( 255, 255, 0, 1 );
}
.raterater-rating-layer {color: rgba( 255, 155, 0, 0.75 );}
/* don't change these - you might break something.. */
.raterater-wrapper {
    overflow:visible;
	margin-top:5px;
}
.raterater-layer,
.raterater-layer i {
    display: block;
    position: absolute;
    overflow: visible;
    /*top: 0px;*/
    left: 0px;
}
.raterater-hover-layer { display: none;}
.raterater-hover-layer i,
.raterater-rating-layer i {
    width: 0px;
    overflow: hidden;
}
/*rating-new.css*/
.action {  text-align: center;}
.rating-input {  display: inline-block;}
.image {
  height: auto;
  width: auto;
}
.price {
  background-color: transparent;
  border: none;
  text-align: right;
  width: 120px;
  box-shadow: 0 1px 0px black;
}
.flat-slider-group { text-align: right;}
.flat-slider-group > * {  display: inline-block;}
.text-review {
  width: 100%;
  resize: none;
  border: 1px solid #bdc6c1;
  background-color: #fff;
}
.portion-size {  text-align: center;}
.selected {  color: rgb(0,0,0);}
.unselected {
  color: rgb(150,150,150);
	opacity:1;
}
.text-na {  text-align: center;}
.text-quantities {
  font-size: 12px;
  width:auto;
}
.item-rating-price-container {
    display: inline-block;
    width:300px;
}
.review-rating-stars {
    padding-left: 5px;
    margin-left: -50px;
    margin-top: 20px;
}
.rating-username{
    margin-top: -20px;
		padding-top:20px;
}
.item-name {
    padding-right: 10px;
    text-transform: capitalize !important;
}
.price-input {
    padding-left: 3px;
    padding-right: 3px;
    text-align: left;
    max-width: 120px;
}
.value-price-slider-label-right {
  font-size: 10px;
  text-align: right;
  float: right;
}
.value-price-slider-label-left {
  font-size: 10px;
  text-align: left;
  float: left;
}

/*
*
* Style for media screen sizes more than 800px
*
*/
@media screen and (min-device-width: 800px) {
    .price-input { max-width: 160px; }
}
/*sales-crm.css*/
.select2-container--default .select2-selection--multiple .select2-selection__arrow b{border-color:#888 transparent transparent transparent;border-style:solid;border-width:5px 4px 0 4px;height:0;left:50%;margin-left:-4px;margin-top:-2px;position:absolute;top:50%;width:0}
.select2-container--default .select2-selection--multiple .select2-selection__arrow{height:33px;position:absolute;top:1px;right:1px;width:20px}
.select2-container--default.select2-container--open .select2-selection--multiple .select2-selection__arrow b{border-color:transparent transparent #888 transparent;border-width:0 4px 5px 4px}
/*# sourceMappingURL=sales-crm.css.map */
/*stle.css*/
.restaurant-order-info label {  margin-top: 0px;}
.discount-tag {
  color: black;
  background-color: yellow;
  border-color: #5a5a5a;
  border: 3px solid;
  box-shadow: 5px 5px #5a5a5a;
}
.following-badge{
  margin-bottom: 5px;
  margin-right: 5px;
}
/*uploadmenu.css*/
 .download-btn, .upload-btn {
   color: #ffffff;
   background-color: mediumseagreen;
   border: none;
   text-transform: uppercase;
 }
 .upload-btn:hover,.upload-btn:focus, .upload-btn:active, .upload-btn.active {
   color: #ffffff;
   background-color: mediumseagreen !important;
   opacity: 0.8 !important;
   border: none;
   text-transform: uppercase;
 }
.glyphicon-upload, .glyphicon-download {
   font-size: 1em;
   color: white;
   margin-left: .75em;
 }
 #uploadFile {   display: none; }
 #progressBarDiv {   padding-top: .5em; }
 .progress-bar { background-color: mediumseagreen !important;
 }
 #uploadDiv, #downloadDiv {
   width: 100%;
   text-align: center;
   background-color: white;
   height: 8em;
   border-radius: 1em;
 }
 .textCenter {   text-align: center !important; }
/*Sswitch.css*/
.s-switch input[type='checkbox']{display:none}
.s-switch {
  width: 52px;
  height: 32px;
  background: #9ACEB1;
  z-index: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  cursor: pointer;
  position: relative;
  border-radius:16px;
  -moz-border-radius:16px;
  -webkit-border-radius:16px;
}
.s-switch:before {
  content: ' ';
  background: #9ACEB1 !important;
  position: absolute;
  left: 1px;
  top: 1px;
  width: 50px;
  height: 30px;
  z-index: 1;
  border-radius:16px;
  -moz-border-radius:16px;
  -webkit-border-radius:16px;
}
.s-switch:after {
  content: ' ';
  height: 29px;
  width: 29px;
  border-radius: 28px;
  z-index: 2;
  background: #02843C;
  position: absolute;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  top: 1px;
  left: 3px;
  -webkit-box-shadow: 0 2px 5px #999999;
  box-shadow: 0 2px 5px #999999;
}
.s-switch-on, .s-switch-on:before{ background: #9ACEB1 !important;}
.s-switch-on:after{  left: 21px !important;}
.s-switch-readonly, .s-switch-disabled{  opacity: 0.8;}
/*search-private-room.css*/
.private-rooms {
  display: none;
  margin-top: 30px;
  margin-bottom: 30px;
}
.box-search-private {
  display: inline-block;
  width:100%;
}
.box-search-private input {background: white !important;}
.title-search {  padding: 3rem;}
.icon-date {
  padding-left: 0px;
  padding-top: 3px;
}
.text-left {  text-align: left;}
.text-menu-hours {
  text-align: center;
    display: inline-block;
    width: 100%;
}
.pd-0 {  padding: 0px;}
.pd-r-0 {  padding-right: 0px;}
.mg-0 {  margin: 0px;}
.time-dropdown select {
  border: 1px solid #bdc6c1 !important;
  font-size: 1.5rem;
  padding: .625rem;
  border: 0;
  margin-right: 0;
  border-radius: 3px;
  text-indent: .01px;
  text-overflow: '';
  width: 45px;
  padding-left: 10px;
  -webkit-appearance: button;
  margin-top: 4px;
}
/*socialshare.css*/
/***** PAGE CONTENT *****/
.h4-lg-font {
  font-size: 16px;
  text-align: center;
}
.font-subheader-m {  font-size: 1.2rem;}

/***** BUTTON *****/
#sent_invite {  margin-top: 20px;}
/***** SOCIAL MEDIA BUTTONS *****/
.social {  text-align:center;}
/***** PAGE DIVIDER *****/
.divide-page{
  text-shadow: 0 1px 0 #fff;
  text-align: center;
  background-image: linear-gradient(to bottom,#fff 0,#E0E0E5 110%);
  position: absolute;
  width: 100%;
  left: 0;
}
/***** EMAIL FORM *****/
#customize{
  padding-left: 18px;
  padding-bottom: 8px;
}
/* Override main css label settings to allow for horizontal form*/
.invite_label{margin-right: 0px;}
/*static_content.css*/
.alignedH3 {margin-top: 5px;}
#listBtn, #mapBtn {background-color: #F1831D;}
.alignedP {    margin-top: 10px;}
.food-items {
    border: 2px solid black;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: -2px;
}
.venueImg {border: 1px solid black; width: 100px;}
.row-border { border: 2px solid black;}
.border-left {
    border-width: 0px 0px 0px 1px;
    border-style: solid;
    border-color: black;
}
.border-right {
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    border-color: black;
}
div.center {
    background-color: #74c046;
    background-image: none;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: none;
    padding: 10px 0px;
    margin-bottom: -2px;
}
div.center:hover {
    background-color: #566f7d;
    color: #fff;
    text-shadow: none;
    text-decoration: none;
}
.reviews {font-style: oblique;}
div:target {display: block;}
.restaurant-info {
    text-align: right;
    padding-right: 16px;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: -2px;
}
.rating-text {width: 100px;margin: auto;}
#yelp-logo-img {
    position: absolute;
    margin-top: -2px;
    margin-left: 52px;
    width: auto;
    height: 20px;
}
.yelp-logo {
    position: absolute;
    padding: 0;
    margin-top: -24px;
    margin-left: 55px;
}
.yelp-logo img{width: auto; height: 20px;}
#linkMIName {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

#venue-name {
    font-style: italic;
}
ul.bulleted {
  list-style-type: disc;
  padding-left: 30px;
}
a {display: inline;}
/* Style the tab for list/map view*/
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}
/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
}
/* Change background color of buttons on hover */
.tab button:hover { background-color: #ddd;}
/* Create an active/current tablink class */
.tab button.active {background-color: #ccc;}
/* Style the tab content */
.tabcontent { padding: 6px 12px;}
#map_canvas { height: 500px; }
.mapview{    display: none;}
#listviewbtn{    display: none;}
/* for sort button placement */
.results_info{	width:60%;  float:left;}
.sortorder{width:100%;float:right;}
/*my-order.css*/
/* Center nav-pills */
.centered-pills { text-align:center; }
.centered-pills ul.nav-pills { display:inline-block; }
.centered-pills li { display:inline; }
.centered-pills a { float:left; }
* html .centered-pills ul.nav-pills { display:inline; } /* IE6 */
*+html .centered-pills ul.nav-pills { display:inline; } /* IE7 */
.delivery-address {  margin: 0 auto;}
.distance-message {  text-align: right;}
.delivery-info {  text-align: center;}
.change-address {  margin-top: 10px;}
#new-address {
  margin-top: 120px;
  width: 90%;
  margin: 0 auto;
}
a.btn.change-address{  display: inline-block;}
.date-time {
  display: none;
  width: auto;
  margin: 0 auto;
  text-align: center;
}
.delivery-time {
  width: auto;
  margin: 0 auto;
  text-align: center;
}
#closed-today {display: none; color: red;}
.special-instructions { margin-top: 5px;}
.no-bottom-padding {padding-bottom: 0;}
.no-bottom { margin-bottom: 0;padding-bottom: 0;}
#distance-success { color: #02843d;display: none;}
#distance-failure {color: red;display: none;}
/* ********TEMPORARY**********TEMPORARY*******************TEMPORARY**************** TEMPORARY */
.position-rel input[type="checkbox"] { /* TEMPORARY */
    visibility: hidden; /* TEMPORARY */
}
/* ********TEMPORARY*********TEMPORARY********************TEMPORARY**************** TEMPORARY */
a[type="button"]:not(.btn-danger):not(.btn-warning) {
    background-color: #02843d;
    background-image: none;
    color: #fff;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: none;
}
a[type="button"].btn-warning {
    background-color: #f0ad4e;
    border-color: #eea236;
    background-image: none;
    color: #fff;
    font-weight: 400;
    font-size: 1em;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: none;
}
.orders-container .position-rel [class^="label-menu"] {
  font-size: 2rem !important;
  padding-left: 0;
}
.orders-container .position-rel label {font-size: 2rem !important;}
.orders-container div[class^="form-group"] {
  margin-top: 10px;
  margin-left: 30px;
}
.orders-container div[class^="form-group"].content-node {
  margin-left: 0;
}
fieldset.fieldset{
  border: 1px solid #02843d;
  border-radius: 5px;
  padding: 5.6px 10px 12px 10px !important;
}
fieldset.fieldset > legend {
  margin-bottom: 10px;
  padding: 0 5px 0 5px;
  width: auto;
  font-size: 16px;
}
fieldset.fieldset.fieldset-small{
  border: 1px solid #02843d;
  border-radius: 5px;
  padding: 0px 10px 12px 10px !important;
}
fieldset.fieldset.fieldset-small > legend {
  margin-bottom: 10px;
  padding: 0 5px 0 5px;
  width: auto;
  font-size: 15px;
}
fieldset.fieldset.fieldset-medium{
  border: 2px solid #02843d;
  border-radius: 5px;
  padding: 0px 10px 12px 10px !important;
}
fieldset.fieldset.fieldset-medium > legend {
  margin-bottom: 10px;
  padding: 0 5px 0 5px;
  width: auto;
  font-size: 18px;
}
/* mobile */
@media(min-width:1200px){
  .orders-container .position-rel [class^="label-menu"] {
    font-size: 2.5rem !important;
    padding-left: 0;
  }
  .orders-container .position-rel label {font-size: 2.5rem !important; }
  fieldset.fieldset > legend {font-size: 16px;}
}
.badge-notify{
    font-family: "Open Sans",sans-serif;
    background: #b30000;
    position: relative;
    top: -12px;
    left: -10px;
    color: white;
    border-radius: 3px;
}
.label-box {
    padding: 5px 25px 5px 25px;
    background-color: #02843d;
    color: white;
    font-size: 18px;
}
#order-loading {
  font-size: 0px;
  line-height: 15px;
  width:100%;
  height:100%;
  border-radius: 5px;
  z-index:999;
}
#order-loading span {
  font-size: 50px;
  line-height: 0px;
}
/* Order Total */
.order-total-section {
  display:flex;
  justify-content:flex-end;
}
.order-total-section .right-align{
  float: right;
  font-size: 16px;
  margin-left: 30px;
}
.order-total-section {
  display:flex;
  justify-content:flex-end;
}
.order-total-section p {
  color: #4a4a4a;
  font-weight: 600;
}
/*my-deals.css*/
.price-area td {text-align: right;}
.price-area #total{
	font-size: 18px;
	font-weight: bold;
}
.buy-now-btn{
  height: 50px;
  width: 300px;
  font-size:25px;
}
.wrap-buy-btn{text-align: center;}
/*tagStyles.css*/
.tagContainer {
  width: 100%;
  margin: auto;
}
.tagContainer span {font-weight:bold;}
.tags { zoom: 1;}
.tags:before,.tags:after {
    content: '';
    display: table;
}
.tags:after {clear: both;}
.tags li {
    position: relative;
    float: left;
    margin: 0 0 8px 12px;
}
.tags li:active {
    margin-top: 1px;
    margin-bottom: 7px;
}
.tags li:after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 10px;
    right: -2px;
    width: 5px;
    height: 6px;
    opacity: .95;
    background: #eb6b22;
    border-radius: 3px 0 0 3px;
    -webkit-box-shadow: inset 1px 0 #99400e;
    box-shadow: inset 1px 0 #99400e;
}
.tags button,.tags span {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tags button {
    height: 26px;
    line-height: 23px;
    padding: 0 9px 0 8px;
    font-size: 13px;
    color: #555;
    text-decoration: none;
    text-shadow: 0 1px white;
    background: #fafafa;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: #dadada #d2d2d2 #c5c5c5;
    border-radius: 3px 0 0 3px;
    background-image: -webkit-linear-gradient(top, #fcfcfc, #f0f0f0);
    background-image: -moz-linear-gradient(top, #fcfcfc, #f0f0f0);
    background-image: -o-linear-gradient(top, #fcfcfc, #f0f0f0);
    background-image: linear-gradient(to bottom, #fcfcfc, #f0f0f0);
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.7), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.7), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.tags button:hover span {
    padding: 0 7px 0 6px;
    max-width: 40px;
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15), 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15), 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.tags span {
    position: absolute;
    top: 1px;
    left: 100%;
    z-index: 2;
    overflow: hidden;
    max-width: 0;
    height: 24px;
    line-height: 21px;
    padding: 0 0 0 2px;
    color: white;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
    background: #eb6b22;
    border: 1px solid;
    border-color: #d15813 #c85412 #bf5011;
    border-radius: 0 2px 2px 0;
    opacity: .95;
    background-image: -webkit-linear-gradient(top, #ed7b39, #df5e14);
    background-image: -moz-linear-gradient(top, #ed7b39, #df5e14);
    background-image: -o-linear-gradient(top, #ed7b39, #df5e14);
    background-image: linear-gradient(to bottom, #ed7b39, #df5e14);
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    -webkit-transition-property: padding, max-width;
    -moz-transition-property: padding, max-width;
    -o-transition-property: padding, max-width;
    transition-property: padding, max-width;
}
.green li:after {
    background: #65bb34;
    -webkit-box-shadow: inset 1px 0 #3a6b1e;
    box-shadow: inset 1px 0 #3a6b1e;
}
.green span { border-color: #549b2b #4f9329 #4b8b27; }
.gray li:after {
    background: gray;
    -webkit-box-shadow: inset 1px 0 gray;
    box-shadow: inset 1px 0 gray;
}
.gray span {
    background: gray;
    border-color: gray gray gray;
    background-image: -webkit-linear-gradient(top, gray, gray);
    background-image: -moz-linear-gradient(top, gray, gray);
    background-image: -o-linear-gradient(top, gray, gray);
    background-image: linear-gradient(to bottom, gray, gray);
}
.gray { opacity:.66;}
.gray:hover {opacity:1;}
ul {
    padding: 0;
    list-style-type: none;
}
.goto-item-btn{
	 background-color: #fc8f3f;
	 font-size: 11px;
   background-color: mediumseagreen;
	 width:55px !important;
	 color:#fff;
}
#market-bowl .modal-dialog {height:100%;}
#market-bowl .modal-content{
	border-radius:0;
	background-color:#000;
	width:100%;
	height:100%;
	padding:15px 20px;
}
#market-bowl .modal-bg {
	border-radius:30px;
	background:#fff;
}
#market-bowl .close {
	color:#FC8F3F;
	text-shadow:none;
	opacity:inherit;
	font-size:2rem;
}
#market-bowl .modal-title {
    color: #fff;
    text-align: left;
    font-size: 24px;
    padding: 15px 0 15px 20px;
}
#market-bowl .modal-title1 {
  color: #02843D;
  text-align: center;
  font-size: 24px;
	font-weight:bold;
}
#market-bowl .modal-header{
	border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
	padding-bottom:0;
	padding-top:3px;
}
#market-bowl .row{
  text-align: left;
  margin: 0;
  height:80px;
	border-top:1px solid #BDC6C1;
	padding:5px;
}
#market-bowl .modal-footer{
  height: 45px;
  background: #FC8F3F;
  border-radius: 50px;
	border:3px solid #fff;
	justify-content: space-between;
  padding: 5px;
	letter-spacing:2px;
}
#market-bowl .select-qty{
    width: 15%;
    height: 30px;
    border-radius: 1.25rem;
    border: 1px solid #02843D;
    display: inline-flex;
}
#market-bowl .search-box{
  border: 1px solid #BDC6C1;
  border-radius: 50px;
  height: 30px;
	font-size:13px !important;
	color:#BDC6C1 !important;
}
#market-bowl .panel-heading{
	background: #F1F4F3;
  padding: 5px 20px;
	color:#4a4a4a;
}
#market-bowl .star-rating {
    line-height: 25px;
    font-size: 0.8em;
}
#market-bowl .panel-heading1{
	background: #02843D !important;
  padding: 5px 20px;
	color:#fff !important;
}
#market-bowl .cart-icon{background: white;color: green;border-radius: 50%;padding: 3px 0 0 6px; width: 30px;height: 30px;  text-align: left;}
.highest-rated-items-ratebox.colorgreen.raterater-wrapper div.raterater-rating-layer i.fa-star{
color:#2ba824 !important;
}
.alignFlex{display:flex !important;}
.isBookMark{ color: black !important;}
.table-month-data{display:flex !important;}
.item-detail-column{display:inline-flex !important;}
@media (max-width:520px) {
	.res-menu-multi-factor-sorting button{font-size:10px !important;}
	.btn.btn-primary.selected{margin:2px !important;}
	.btn-group .btn-primary{padding:4px !important;	}
}
@media (max-width:470px) {
  #reg-btn-create-account{ padding: 0 30px !important; }
}
#public-ratings-friends span.icon-checkbox {
    float: left;
    margin-right: 6px;
}
.resturant-review-avg-rating .row .yesMarginY{
	margin-top:5px !important;
}
.advanance-search-exp-filter-slider.low{padding-right:10px;}
.advanance-search-exp-filter-slider span.guru-presenation-text-margin{
	padding-left:0px;
}
.advanance-search-exp-filter-slider span.icon-checkbox.check-box-left{
  margin-right: 10px;
}
a.order-ahead.restaurant-order-type:hover {
  color: #02843d;
}
.custom-range-slider, .tick-slider-selection, .in-selection {
    background: #02843d !important;
}
.slider-track-low, .slider-track-high{background: #bdc6c1 !important;}
.backpage{ color:#fff;}
.backpage:hover {color:#fff;}
.discount {	border-right:3px solid #02843d !important;}
div .toggle-group span.toggle-handle.btn.btn-default {
    background: #9ACEB1!important;
    border: none!important;
}
div.toggle.btn.btn-default { background: #fff!important;}
.switchDervice{
  display: flex;
  width: 215px;
  margin-left: auto;
  margin-right: auto;
}
.switchDervice p{
  margin: 7px;
  min-width: 62px;
  line-height: 35px;
  text-align: center;
  color: #02843d;
}
.switchDervice .slider-item-user-metrics{background-color: #02843d !important;}
.item-user-metrics{ display: -webkit-box;}
.text-item-user-metrics {
  margin: 7px;
  min-width: 62px;
}
#resetAllNotifications{ margin-left: 10px;}
.background-blur:before {
  content: '';
  background-color: #000;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}
.background-blur .modal-dialog { z-index: 1100;}
.switch-item-user-metrics {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch-item-user-metrics input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider-item-user-metrics {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider-item-user-metrics:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider-item-user-metrics {background-color: #02843d;}
input:focus + .slider-item-user-metrics { box-shadow: 0 0 1px #02843d;}
input:checked + .slider-item-user-metrics:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider-item-user-metrics.metrics-round {border-radius: 34px;}
.slider-item-user-metrics.metrics-round:before {border-radius: 50%;}
label.btn.btn-default.active.toggle-off { border: none!important;}
.toggle.btn.btn-default.off {
    border: 3px solid #02843d!important ;
    background: #9ACEB1!important;
}
.toggle.btn.btn-primary {
    background: #bdc6c1!important;
    border: 3px solid #02843d;
}
div label.btn.btn-primary.toggle-on:hover,
div label.btn.btn-primary.toggle-on:focus{
	background:#9ACEB1!important;
	width:66px!important;
}
.rng-slider .slider-track-high {background: #bdc6c1 !important;}
.map-google-item-container{	width:80%;}
.map-google-item-container .item-details{
	width:50%;
	float:right;
	text-align:center;
	padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.map-google-item-container .item-details span{
	font-size: 15px;
	font-weight: 700;
}
.map-google-item-container .item-image-container{
	height:75%;
}
.toggle-accordion{
	 color: #fff;
   background: #02843d;
	 padding:6px 20px;
	 border-radius:50px;
}
.toggle-accordion:hover,.toggle-accordion:focus{
	 color: #fff;
}
.custom-control.custom-checkbox.custom-checkbox-inline {
    display: inline-block;
}
.custom-control.custom-checkbox.custom-label-inline-flex {
    display: flex;
}
.main-border {
	box-shadow: 0 2px 8px rgba(0,0,0,0.1);
	margin:1rem 1.5rem 1rem;
	padding: 18px 0;
}
#intro div.slidecontainerNew{	width:97% !important;}
#intro div.slidecontainerNew p{color: #666 !important;}
.user-price input.price.price-menu-input.item-price.green.form-control.whts_its_worth{
	 display:inline-flex;
}
input.price.price-input.green.user-value-price.form-control{
  display:inline-flex;
}
.btm-line-rem {
	box-shadow: unset;
    border-bottom: 0 !important;
}
.media-object {width:100%;}
.main-menu-inline {
	width: auto;
    text-align: center;
    background: #fff;
    border: 0;
    display: inline-flex;
}
.login-container {	margin:50px 8px;}
span.custom-dropdown.select-menu-hours.private-room-menu-hour {
    display: inline-flex;
}
.green-filter{background-color: #9aceb1 !important;color:#333 !important}.green-filter .fancytree-title span{color:#333 !important}
.green-filter span,.green-filter .flavor-match i,.green-filter .menu-item-name,.green-filter .flavor-match p{color:#333 !important}
#intro .home-customer-rest-btn li a{
  border-radius: 50px!important;
  background: #fff;
  padding: .8rem !important;
  font-size: 24px !important;
}
#intro .home-customer-rest-btn li.nav-item.home-toggle-customerbtn a.nav-link.active{
  border-top-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 50px !important;
}
#intro .home-customer-rest-btn li.nav-item.home-toggle-restbtn a.nav-link.active{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-bottom-left-radius: 0px !important;
}
#intro .home-customer-rest-btn ul{
  border: 1px solid #4A4A4A;
  border-radius: 50px;
  box-shadow: none;
}
.subheadersearch{
  float: left;
  width: 58%
}
.subheadersearch-border-radius{
  border-radius: 25px 25px 0px 0px !important;
}
 .autocomplete-homepage-wrapper .wrap-hide-locationinput{
    background-color: unset !important;
  }
  #intro .form-control{
   
    border-radius: 0px 50px 50px 0px !important;
    font-size: 15px !important;
    height:50px;
    padding-left: 0px;
  }
#homeearnup.intro-search-wrapper{
    margin-top: 10% ;
  }
/**for tabs full width**/
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .homearrows{
    height: 49px !important;
  }
  #intro .form-control{
      border:none !important;
      border-radius: 50px !important;
  }
  #intro .home-customer-rest-btn li.nav-item.home-toggle-customerbtn a.nav-link.active{
    height: 100% !important;
  }
  #intro .home-customer-rest-btn li.nav-item.home-toggle-restbtn a.nav-link.active{
 
  height: 100% !important;
}
  #intro a.home-address-text{
        border: none !important;
  }
  #homeearnup.intro-search-wrapper{
    margin-top: 24% !important;
  }
input#homehead-location-input-search:focus {
  box-shadow: none;
 
}
  #intro .earn-free-food-total-text.steps-text{font-size: 19px!important;}

  #intro .earn-free-food-image{display:none !important;}

 .menucard{padding-left:14px!important; padding-right:14px!important ;}

.homepage-label{padding: 0px 4px 2px 5px !important; }
 #intro .input-group-location {
      padding-top: 1px !important;
      font-size: 10px !important;
      margin-left: 0%;
} 
.image-lazy-load.go-to-specified-restaurant .topRated_discount_div{
  bottom: 233px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{padding:0 10px !important;}
.home_order_wraper {
    position: relative;
    left: 2%;
}
.obj-rating-right-mobo-img{left: 78% !important;}
.home-always-earn-right-img{left:83% !important;}


  .selected-items-errors{padding-left: 15px;}
  .homearrow{ width: 44px !important;}
  .subheadersearch{
    float: left;
    width: 75% !important;
    background-color: white;
    height: 42px;
    border-radius: 50px;
  }
  .home_order_btns .nav-rounded {background: #fff !important;height: 53px !important;}
  #intro .nav-link {
    /* padding: .3rem !important; */
    font-size: 18px !important;
    font-weight: 600;
    /*border-radius: 12.5px !important;*/
  }
  #intro .nav.nav-pills .nav-item {
    /* max-width: 47%; */
    width:50%;
  }
  #intro .nav.nav-pills {
    width: 100% !important;
   /* border-radius: 13.5px !important;*/
    /* padding: 10px 5px; */
    /* padding-top: 2px !important; */
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  border-radius: 12.5px !important;
  }
  #intro .can-toggle {
      width: 100%  !important;
      margin-top: 0%;
      margin-left: 16px;
  }
  #modal_search_filter_div .orders-type-container{
    margin-left: 0px !important;
  }
  .loggedinheadericon{  display: none;  }
  .restaurant_main_infor .top_slide_image{left: 0px !important; }
  #modal-all-order-infor .modal-title{margin-left: 77px !important;}
  .recommendedheading{font-size: 16px !important;}
  .flavorpreferences{
    font-size: 10px !important;
    text-align: center;
  }
  .discoverfood{
    font-size: 18px !important;
    text-align: center;
    line-height: 20px !important;
  }
  #call-to-action-new{display: none; }
  #intro .intro-container{
    height: 100% !important;
    /* background-image: url('../images/banner_mobo.svg'); /* !important; */
		background-position: bottom;
    background-size: cover;
  }
	#intro .cuisine-container{background-image: none !important;}
  .why-pr{ width: 43% !important;  }
  .bannertext{margin-left: 0% !important;}
  .homebannertext{
     padding-top: 23% !important; 
    margin-left: 0% !important;
  }
  .signup-sidenav{padding-right: 7px !important;}
  .banneradvance{text-align: center !important; }
  /* #topheader{min-height: 370px !important; height: auto;} */
  .bottomwishlist{
    float: left !important;
    left: 2% !important;
    top:45% !important;
  }
  .topwishlist{
    float: right !important;
    top:45% !important ;
    right: 2%;
  }
  .carousel-control-next{
    bottom: 68% !important;
    right: 0!important;
  }
  .carousel-control-prev{ bottom: 68% !important; }
  .carousel-control-next, .carousel-control-prev{ bottom: 68% !important;}
  .topcarousel-control-next, .topcarousel-control-prev{ bottom: 43% !important; }
  a.topcarousel-control-next.carousel-control-next {right: 7% !important; }
  #results .results-col h2{ margin: 8px 0 2px 0 !important;}
  .head-inner-location-container{
        width: 100% !important;
        
  }
  #intro .form-control{
    font-size: 15px !important;
    height: 42px;
    padding-left: 0px;
  }
  
  #intro #search-address-label-homepage{
    height: 42px;
    font-size: 13px !important;
    border-radius: 0px;
    padding: 12px 9px 12px 0 !important;
  }
  .rewards{display: none !important;  }
  .aboutus{display: none !important;  }
    #mobilefooter-new{
    border-top: 1px solid grey;
    background: #fff;
    z-index: 999;
    display: block;
    position: fixed;
    height: 73px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
  }
  .nav-count-2 .menuitem {float: left;  width: -webkit-fill-available;}
  .nav-count-2 .restaurant {float: right; width: -webkit-fill-available;}
  .nav-count-1 .menuitemrestaurant {display: contents !important;}
  .nav-count-2 .menuitemrestaurant {float: left;  width: 50%;  max-width: 52%  !important;}
  .nav-count-2 .privateroom {float: right; width: 45% !important;}
  .nav-count-3 .menuitem {float: left;}
  .nav-count-3 .privateroom {float: right;}

  .homedesktopsubheader {
    padding-top: 8% !important;
    /* padding-right: 7% !important; */
  }
  #intro .text-link {
    top: 96%;
    left: 33%;
    position: absolute;
    font-size: 20px;
    z-index: 1;
  }
  #search-filter {
    position: absolute;
    margin-left: 7%;
  }
  .rate-earn-rewarded-text {font-size: 24px !important; margin-bottom: 0 !important; }
  .highly-rated-dish-text {font-size: 36px !important; margin-bottom: 0 !important; text-underline-offset:4px !important; margin-left:0 !important; }
  .restaurantmaindiv #imageCarousel .carousel-control-prev {
    bottom: 0% !important;
  }
  .restaurantmaindiv #imageCarousel .carousel-control-next  {
    bottom: 0% !important;
    right: 15px;
  }
  .homeheadsearchhome {margin-right: -8px !important;}
}
@media only screen and (min-device-width: 769px) and (max-device-width: 1200px) {
    .home-search-arrow-btn {
      position: absolute;
      left: 39%;
      top: 270%;
      float: none;
      z-index: 1;
  }
}

#home-advanced-search{font-weight: 600}
@media only screen and (min-width: 768px) {
   .item-gallery {
      position: absolute;
      width: 95% !important;
      left: 5% !important;
      top: 2px;
  }
  .item-description p {    font-size: 15px !important;}
  .price-text {  font-size: 15px !important;}
  .title-fnt {    font-size: 20px !important;}
  .venue-distance label, .venue-distance span {
    font-size: 15px !important;
    font-weight: bold !important;
  }
  .item-image-container {
    width: 100% !important;
    margin-right: 10px;
  }
  .results-wrapper { width: 80%  !important;}
  .item-venue-address {   max-width: 100%!important;  }
  .restaurant_main_infor .custom_rest_reviews {
    font-weight: 600;
    line-height: 16px;
  }
  .platerate_review_outer_div {
    top: 66px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .custom_rest_reviews .yelp-logo-img {   height: 18px; }
  .restaurant-carousel-cust_btns a{ font-size:13px!important; }
  .restaurant-carousel-cust_btns #remove-restaurant-image{
    padding-left: 6px!important
  }
  .main-border ,.no_box_shadow {min-height: 261px!important;  }
  #head-form-search-basic #head-location-input-search #homehead-location-input-search {
    width:100%;
    border-radius:5px;
  }
  .results-content { min-height: 14.25rem;}
  .results-content {display:block !important;}
  .slider-bathroom-cleanliness {margin-left: 20%;}
  #earn .earn-bg2 { padding-left:55px !important;}
  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  #contact .contact-address, #contact .contact-phone, #contact .contact-email {
    padding: 20px 0;
  }
  .hrm-minus-left {left:-30px;}
  .minus-left-view {  left:-45px;}
  .tree-node .rating, .flavor-match, .earn-upto {
    padding-left:0;
    padding-right:0;
  }
  .highest-rated-menu-item {
    border-top: 1px solid #ddd;
    padding: 5px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .menu-item-name {
    font-size:1rem;
    padding-left:0;
  }
  .flav-text-none{  display:none !important;}
  .flav-match {display:none;}
  .mr-lf {margin-left:-29px;}
  .col-group {
    display: inline-flex !important;
    padding-left:0;
  }
  #intro .nav-link {
      padding: .3rem !important;
      font-size: 18px !important;
      font-weight: 600;
      height:100%;
  }
  .topcarousel-control-next{margin-bottom: 59% !important; }
  #intro .nav.nav-pills .nav-item {
    max-width: 49%;
    min-width: 50%;
  }
  #intro .nav.nav-pills { width: 100% !important; background: #FFFFFF !important;}
  #intro .can-toggle { width: 100%  !important;}
  .topclass{margin-top: 40px;}
  .ugpo-div-margin{ margin:5px; }
   .nav-count-2 .menuitem {float: left;  width: -webkit-fill-available;}
  .nav-count-2 .restaurant {float: right; width: -webkit-fill-available;}
  .nav-count-1 .menuitemrestaurant {display: contents !important;}
  .nav-count-2 .menuitemrestaurant {float: left;  width: 100%;  max-width: 52%  !important;}
  .nav-count-2 .privateroom {float: right; width: 45% !important;}
  .nav-count-3 .menuitem {float: left;}
  .nav-count-3 .privateroom {float: right;}
}
.flavor-match { cursor:pointer !important;}
.dietwhitebag{ background: #FFFFFF;}
.fancytree-ico-e span {float: left;}
@media (min-width: 455px) and (max-width: 531px){
  .highest-rated-menu-item .tree-node { width: 38% !important; }
  .tree-node { width: 40% !important; }
}
@media (min-width: 532px) and (max-width: 767px) {
  .highest-rated-menu-item .tree-node { width: 40% !important;}
  .tree-node {width: 40% !important; }
}
.flav-display { display:inline-flex;}
@media (max-width: 448px) {
	.width-10 {line-height: 10px !important; }
}
.col-width {
  width:100px;
  display: inline-flex !important;
}
#tree { margin: 0; padding: 0}
#tree ul.fancytree-container {
    font-family: "Open Sans",sans-serif !important;
    font-size: 20px !important;
    background-color:unset !important;
    border: unset !important;
    margin: 0;
    padding: 0
}
#tree .font-10 {font-size:10px;}
.width-25 { width:25%;}
.width-30 { width:30%;}
.width-34 { width:34%;}
.width-20 { width:20%;}
.width-10 { width:10%; line-height:15px;}
.width-50 { width:50%;}
.menu-filter-btn {
    background: #fff;
    border-radius: 4px;
    border: 1px solid mediumseagreen;
    padding: 0.5rem !important;
}
.menu-rating-th {
    font-size: 0.8rem;
    color: #333;
    line-height: 14px;
    margin-top: 10px;
}
.restaurant-menu-item, .menu-item-price, .item-discount, .servings, .days-order-in-advance {
    font-size: 1.5rem;
}
.fancytree-plain span.fancytree-active span.fancytree-title, .fancytree-plain span.fancytree-selected span.fancytree-title {
    background-color: transparent !important;
    border-color: none !important;
}
.tree-node .rating, .flavor-match, .earn-upto {
  display:inline-flex;
  vertical-align:top;
}
.hidden{
  visibility:hidden;
  display:none;
}
.goto-item-btn{	margin-right:0 !important;}
.expand-btn {
	background: #fff !important;
    border: 0 !important;
    border-radius: 4px !important;
    color: #333;
    font-size: 13px !important;

}
 @media (min-width: 950px) {
  .tree-node .ratings-stars {
  	display:inline-flex !important;
  }
}
 .restaurant-menu-btn {
    font-size: inherit;
	 color: #fff!important;
 }
 .fancytree-plain.fancytree-container span.fancytree-node.fancytree-active span.fancytree-title {
	 color:#000;
 }
 .show_diet_pref_label.badge{ padding:0.4rem !important;}
 .restaurant-menu-item, .menu-item-price, .item-discount, .servings, .days-order-in-advance {
    font-size: 14px !important;
}
@media (min-width: 320px) and (max-width: 451px) {
 .menu-rating-th {font-size:9px !important; }
  #header #head-logo a.home-mobile-logo.text-center img{
    max-width: 120px !important;
    width: 120px !important;
    margin-left: 25%;
  }
  .myorderpopupdiv .cart-options label,.myorderpopupdiv .cart-options span{
    font-size: 12px !important;
  }
  .cart-item-details span {font-size: 14px !important;}
}
 /* fancytree div changes for restaurant menu and responsive upto here */
.counter .fancytree-active .clicks-counter {color: #4a4a4a;}
.counter .clicks-counter{
	display: block;
    margin-left: 2px;
}
.edit-item-modal .modal-title, h3 {	font-size:1.2rem !important;}
.edit-item-modal .reduce-item-quantity {
	padding: 0;
    width: 30px;
    height: 30px;
    margin-top: 2px;
}
.edit-item-modal .increase-item-quantity{
	padding: 0;
    width: 30px;
    height: 30px;
    margin-top: 2px;
}
#result_follow_influencer .img {width:100%;}
#highestRatedMenuItemPopup .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
	background: transparent;
}
#restaurantMenuItemPopup .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
	background: transparent;
}
.group-setup-menu .group_order-type {
	display: -webkit-box !important;
    border-bottom: none !important;
	background: transparent !important;
}
.btn-review-cart {
  margin: 10px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  padding: 8px;
  border-radius: 25px;
  transition: .5s;
  border: 2px solid #02843d;
  color: #02843d;
  text-align: center;
  width: 85px;
  height: 40px;
  background: #fff;
}
.item-title {
	font-size: 15px !important;
  display: inline-block;
  width: 70% !important;
}
.itemImage {
	object-fit: contain;
  height:  -webkit-fill-available;
}
.sharedonebutton{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;
    margin-right: 11px;
}
.form-row.col-md-12.col-12.recommend-see-menu-btn.food-flavor.homepage-prefs-div.pl-1{
  bottom: 0px;
  position: absolute;
}
a.re-order-items {
  font-size:14px;
  height: auto;
  padding: 3px 7px !important;
  color: #fff !important;
}
.order-history-info a {
  padding:0px;
  display:inline-block;
}
.order-history-info p {margin:0px;}
input.form-check-input.pickup {
    visibility: visible;
    left: 24px;
	margin-top: 2px;
}
span.location-containersearch{width: 81%}
@media screen and (max-device-width: 1119px){
  span.location-containersearch{width: 78%;}
}
@media screen and (max-device-width: 520px){
 span.location-containersearch{ width: 50%;}
}
@media screen and (max-device-width: 385px){
  .menu_item_title_fonts {font-size: 1rem; }
  span.location-containersearch{width: 38%; }
}
input#head-location-input-search input#homehead-location-input-search {
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  font-size: 15px;
  padding: 0 15px;
  font-family: FontAwesome,Lato,sans-serif;
}
.adv-srch-radius {
    border: 1px solid #ccc;
    padding: 5px 10px 0 0;
    border-radius: 0 1px 1px 0;
}
.orders{width: 80px !important;}
.form-check.deliveryleft{width: 89px !important;}
@media screen and (max-device-width: 580px){
.hide-heading {
    visibility: hidden;
    display: none;
}
}
.results-count {
    align-self: flex-end;
    /*margin-top: 30px;*/
    text-align: center;
    display: block;
    /*position: absolute;*/
}
.venue-location p {
    padding: 0;
    margin: 0 0 10px 0;
}

#filter-form .input-group {
    position: relative !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    width: 70% !important;
    border : none;
    height :0px;
}
.selectbox-width{width: 400px;}
@media screen and (max-device-width: 1000px){
  .delivery-staff {
    display: contents;
    white-space: unset !important;
  }
.selectbox-width{	width: 300px;}
 span.location-containersearch{width: 74%;}
}
@media screen and (min-device-width: 1000px){
.rest-menu-multi-factor-filtering {
	display: inline-flex;
}
.rest-menu-multi-factor-sorting,.multi-factor-sorting {
  display: inline-flex;
}
}
.item-image-container {	width: 100% !important;}
a.btn.btn-link.green.white.toggle-expert-reviews {
    background: #02843d;
}
@media (min-width: 320px) and (max-width: 575px){
  #intro .nav-link {padding:0.5rem 0.5rem};
  div#menuRestaurantBar .nav-rounded.nav-pills .nav-link.active{height: 50px!important;}
	.form-control.resturant-open-time {width:auto!important;}
	div.form-group #open-time-search-filter {width:100%!important;}
}
.hide{display:none!important;}
.label-menu {
    font-size: 1rem !important;
    font-weight: normal;
}
.radio-inline {
    cursor: default !important;
    font-size: 12px !important;
    font-weight: bold;
}
.label-menu .col-xs-12 {   margin-right: 20px;}
.form-check .label-menu {
    font-size: 1rem !important;
    font-weight: normal;
}
.h2, h2 {  font-size: 1.5rem !important;}
#profitRprtTable thead tr th {
  min-width:180px;
  text-align:center;
}
#profitRprtTable tbody tr td {
  min-width:180px;
  text-align:center;
}
.fancytree-ico-c .grey-filter, .green-filter, .orange-filter {
    margin: -15px 0;
    padding: 10px 0;
}
.menu-filter-btn {    font-size: 1rem !important;}
.results-content { min-height: 14.25rem;}
.fancytree-plain span.fancytree-node:hover span.fancytree-title{
  color:#000!important;
}
.pinterest-share-button-web span {
    border-radius: 50px !important;
    height: 28px !important;
    padding: 0px 15px 0 0 !important;
    margin-left: 5px;
    background-size: 15px 15px !important;
    background-position-x: 11px !important;
    font: 12px/27px "Helvetica Neue",Helvetica,Arial,"sans-serif" !important;
    text-transform: uppercase;
}
.pinterest-mob-share-btn {
    border-radius: 50px;
    text-indent: 20px;
    padding: 4px 12px !important;
    text-align: left;
    text-decoration: none;
    font: 12px/20px "Helvetica Neue", Helvetica, sans-serif !important;
    color: #fff!important;
    background: #e60023 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…UsMS42ODYgMTQuNzMzLDEuNjg2IiBmaWxsPSIjZTYwMDIzIj48L3BhdGg+PC9nPjwvc3ZnPg==) 3px 50% no-repeat;
    background-size: 15px 15px !important;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    height: 28px !important;
    background-position-x: 11px !important;
    text-transform: uppercase !important;
}
.insta-share-button, .twitter-share-button, .facebook-share-button ,.linkedin-share-button ,.email-share-button ,.twitter-share{
	/* font-size:0.75rem !important; */
	padding:5px 5px !important;
}
.socialshare-btns-box a{padding:.375rem .75rem !important;}
.socialshare-btns-box .social-icon, .socialshare-btns-box .fb-share-button{
	margin-left: 0px;
  margin-top: 0px;
}
.restaurant_card_badge{
  position: absolute;
  font-size: 14px;
  top:3px;
  color: #000;
  font-weight: bold;
  border-radius: 50%;
  margin-left: -18px;
  min-width: 17px;
}
.shopping_card_badge, .rewards_card_badge{
  position: absolute;
  font-size: 14px;
  top:2px;
  color: #000;
  font-weight: bold;
  border-radius: 50%;
  margin-left: -28px;
}
.editable-container .glyphicon-ok:before {
  content: "\f00c";
}
.editable-container .glyphicon-remove:before {
  content: "\f00d";
}
.editable-container .glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.editable-container .editable-buttons {
  position: relative;
  top: 4px;
}
.editable-container .editable-submit {
  width: 25px;
  height: 25px;
}
.editable-container .editable-submit > i{
  position: relative;
  top: -9px;
  left: -5px;
}
.editable-container .editable-cancel {
  width: 25px;
  height: 25px;
  margin-left: 5px !important;
  background-color: #d43f3a !important;
  background: #d43f3a !important;
  font-size: 1em;
  background-image: none;
  color: #fff;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 1px;
  text-align: center;
  text-shadow: none;
  padding: 0px;
}
.editable-container .editable-cancel > i{
  position: relative;
  top: -1px;
  left: 0px;
}
/* for sales reveneue report */
@media screen and (min-device-width: 1200px) {
  .salestable { display: inline-table !important; }
}
.aff-row {
    background: #02843d !important;
    color: #fff;
    font-weight: bold;
}
@media screen and (min-device-width: 992px) {
	.afftable { display: inline-table !important;	}
	.instruction-box{
	  font-size: 16px;
	  float: left
	}
	.instructionmodel{
	  font-size: 20px;
	  margin-top: 10px
	}
	.gotitcss{font-size: 20px;}
}
form#form-registration a.forgot{float:left;}
#favorites-and-menu-bar a:hover, a:active, a:focus {
    color: #000 !important;
    outline: 0;
    text-decoration: none;
}
.black{ color:#000 !important;}
.datepicker .datepicker-days{
    display: block !important;
}
.cus-btn-green {
    background: #4caf50;
    cursor: pointer !important;
}
.cus-btn {
	cursor: pointer !important;
  width: 70px;
}
.deleteRestaurant button.cancel, .deleteRestaurant button.cancel:hover, .callServer  button.cancel, .callServer button.cancel:hover{
	background-color : #02843d;
}
.popover{	z-index : 9000 !important;}
#createAccountBtn button.btn-create-account{
  float:right;
  height:38px;
  font-size:16px!important;
}
button.btn.btn-md.btn-save-padding{
  padding-left:20px;
  padding-right:20px;
}
.login-view .email-password-area.large {
	padding: 0 20px !important;
}
.view .header-login {
    padding: 34px 23px;
    display: inline-flex;
}
.modal-header.tip-modal-header { display: inline-table;}
.order-tip-btn{font-weight: bold;border-radius: 5px}
a.order-tip-btn.active{
  background-color: #02843d;
  color: white;
}
#edit-delivery-tip-container .modal-header .close {
  padding: 12px;
}
/*  multiselect sort dropdown css */
#venueItemsDiv .list-option.active input[type="checkbox"],
#menuItemsDiv .list-option.active input[type="checkbox"] {
   visibility:visible;
   position: relative;
}
#venueItemsDiv button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info),
#menuItemsDiv button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info) {
    background-color: transparent;
    background-image: none !important;
    color: #02843D!important;
    font-weight: 400 !important;
    font-size: 1em ;
    letter-spacing: 1px !important;
    text-align: left !important;
    text-shadow: none !important;
    border: none !important;
    outline: none !important;
    border-radius: 6px;
}
#venueItemsDiv .dropdown-toggle::after,
#menuItemsDiv .dropdown-toggle::after {
    content: "\f078";
    font-family: "FontAwesome";
    font-size: 10px;
    color: #666;
    right: 8px;
    top: 4px;
    padding: 4px 12px 2px;
    border-top: 0;
    pointer-events: none;
    float: right;
    font-weight: 900;
}
#venueItemsDiv ul.dropdown-menu,#menuItemsDiv ul.dropdown-menu {
    margin-top: 10px;
    width: 65%;
}
#venueItemsDiv .list-option.active,#menuItemsDiv .list-option.active {
    background-color: #fff;
}
#venueItemsDiv .dropdown-menu>li>a,#menuItemsDiv .dropdown-menu>li>a {padding: 3px 0px;}
#venueItemsDiv a,#menuItemsDiv a {color: #666666;}
.reg-top-margin{ margin-top:98px;}
/*for the meet my pref in restaurant menu */
@media (max-width: 545px) {
  ul.select-suitable-list{padding: 0px;}
  li.select-suitable-dish a{
	  font-size: 13px !important;
  }
  li.select-suitable-dish{
    border: 1px solid #C0C0C0 !important;
    padding: 4px !important;
  }
  li.select-suitable-dish:first-child,li.select-suitable-dish-all:first-child{
	  border-right: 0 ;
  }
  li.select-suitable-dish-all a{font-size: 13px !important;}
  li.select-suitable-dish-all{
	  border: 1px solid #C0C0C0 !important;
	  padding: 5px !important;
  }
  .foodpref-font{font-size: 15px !important;  }
}
ul.select-suitable-list{
  width: 100%;
	position: relative;
  z-index: 0;
}
ul.select-suitable-list-all{
  width: 100%;
	position: relative;
  z-index: 0;
}
li.select-suitable-dish:last-child,li.select-suitable-dish-all:last-child{
  border-right: 2px solid #C0C0C0;
}
li.select-suitable-dish{
  border: 2px solid #C0C0C0;
  white-space: nowrap;
  width: 100%;
  padding: 5px;
  font-size: 15px;
  display: inline-block;
  border-right: 0;
  background: #dddddd38 ;
}
li.select-suitable-dish a{
  font-weight: bold;
  font-size: 14px;
  color: black;
}
li.select-suitable-dish-all{
  border: 2px solid #C0C0C0;
  white-space: nowrap;
  width: 100%;
  padding: 5px;
  font-size: 15px;
  display: inline-block;
  border-right: 0;
}
li.select-suitable-dish-all a{
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.foodpref-font{
  font-weight: bold;
  font-style: italic;
  font-size: 20px
}
#earn .social-links {text-align:center;}
#earn .social-links a {
  font-size: 24px !important;
  display: inline-block;
  background: #fff;
  color: #02843D;
  line-height: 1;
  padding: 0;
  margin-right:30px;
  transition: 0.3s;
}
.small-info-orangeearn {
    position: absolute;
    top: -11px;
    font-size: 12px;
}
#earn .orange {
    color: #fc8f3f;
    position: relative;
    float: right;
		display: grid;
}
.rateheading {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
}
#earn .arrow {
 width: 25px;
  height: 0px;
  left: 120px;
  top: 146px;
  border: 1.5px solid #000000;
  box-sizing: border-box;
}
#earn .col-md-6.right-section-column { padding: 0 10px;}
span.right-info-text {
    color: black;
    font-size: 13px;
}
span.info-percentage {
    position: absolute;
    font-size: 20px;
    top: 12px;
    font-weight: bold;
}
 span.main-info-orange {
    padding-top: 15px;
    float: left;
    padding-left: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    padding-right: 10px;
}
span.small-info-orange {
    position: absolute;
    top: 0;
    left: 24px;
    font-size: 12px;
}
#earn .subhading{
  color: #fff;
  left: 0px;
  top: 0px;
  background: #02843D;
}
@media (max-width: 455px) {
  .highest-rated-menu-item .tree-node { width: 38% !important;}
  .slate-gray {
      color: slategray;
      font-size: 14px !important;
  }
	.fancytree-node .restaurant-menu-item, .item-discount, .servings, .days-order-in-advance,  #pick-address-distance {
		font-size: 11px !important;
	}
  #earn span.right-info-text {font-size: 12px;}
  #earn .subhading  { font-size: 12px;}
}
@media (max-width: 650px) {
  .paybuttonMbl{ position: relative;left:-10px}
  .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
  }
  .homeleft{
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
  input { color: #666; caret-color: #303331; }
  #footer-model-instruction{margin-bottom: 26px; }
  .instruction-box{
    font-size: 12px;
    float: left
  }
  .instructionmodel{
    font-size: 14px;
    margin-top: 10px
  }
  .instructionbottom{padding-bottom: 20px; }
  .footerinst{ margin-bottom:25px;}
  .gotitcss{font-size: 14px;}
#earn .subhading{
  color: #fff;
  left: 0px;
  top: 0px;
  background: #02843D;
	font-size: 14px;
  padding: 5px;
}
#earn .modal-title {
    text-align: center;
    font-size: 1rem !important;
}
.static-content{
    background: #f7f7f7;
    padding: 5px;
	margin-bottom:20px;
	padding-top: 32px;
}
}
@media (max-width: 536px) {
#earn .social-links a {
  margin-right:20px !important;
  font-size: 18px !important;
}
#earn .step {
  width: 55px!important;
  height: 55px!important;
  line-height: 11px!important;
  font-size: 10px!important;
  padding-top: 14px!important;
}
#earn .steporange {
  width: 55px!important;
  height: 55px!important;
  line-height: 11px!important;
  font-size: 10px!important;
  padding-top: 14px!important;
}
#discount_flip_div .steporange {
  background: #fff;
  display: block;
  border-radius: 50%;
  font-weight: bolder;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  padding-top: 20px;
}
#earn .fa-arrow-right {
  padding: 0 1px !important;
  font-size: 10px !important;
}
#earn span.main-info-orange {
    padding-top: 28px;
    float: left;
    padding-left: 5px;
    font-size: 28px;
    padding-right: 18px;
}
#earn span.info-percentage {
	top:30px;
	font-weight: normal;
}
}
#earn .social-links a:hover {
  background: #02843D;
  color: #707070;
}
#earn .modal-dialog {height:100%;}
#earn .modal-content{
  border-radius: 10px;
  background-color: transparent;
  background-color: #fff;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 12px rgba(182,182,182,0.2);
  border: 1px solid rgba(246, 243, 243, 0.2);
  padding: 15px 20px;
}
#earn .modal-bg{
border-radius:30px;
background:rgba(255, 255, 255, 0.1);
}
#earn .close{
color:#B2B9BF;
text-shadow:none;
opacity:inherit;
font-size:2rem;
}
#earn .modal-title{text-align:center;}
#earn .maindiv{
  position: absolute;
  width: 325px;
  height: 80px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
}
#earn .modal-header{
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom:0;
  padding-top:3px;
}
#earn .modal-body{
  padding-left: 54px;
  color: #F1F4F3;
  padding-top: 0px;
}
#earn .modal-footer{
  height: 95px;
  display: inherit;
}
.flipformbox{
    box-shadow: 0 2px 12px rgba(156, 156, 156, 0.2);
    margin-top: 10px;
    background-color: #FAFAFA;
    height: 60px;
    border-radius: 5px;
}
.text-link-flip, .text-link-flip:hover{
  color: white !important;
  font-size: 18px;
  text-decoration: underline;
}
.flipsmallsubheading{ float: right;}
.hrdetails{border-top: 1px solid #979797;}
.hrdetailsdashed{border-top: 1px dashed #979797;}

.hrdetails{border-top: 1px solid #cacaca;}
.hrdetailsdashed{border-top: 1px dashed #cacaca;}

.priceright{position: absolute;right: 0;}
.righthr{position: absolute;right: 4rem;}
.show_order_detail_btn{
  color:#02843d;
  background-color:#fff;
  border:1px solid #02843d;
  border-radius: 14px;
  cursor: pointer;
  box-shadow: none !important;
  outline:0px !important;
}
#earnedprogressbar{
  border-bottom-left-radius: 50rem;
  border-top-left-radius: 50rem;
  height:2rem;
  width:80%;
  border: 1px solid #e9e9e9 !important;
}
.circle-input.rounded-circle {
  width: 121px;
  height: 121px;
  border: 5px solid #E9E9E9;
}
.first-span{
  width: 113px;
  height: 113px;
  border: 8px solid #9EE7BF;
}
.second-span{
  width: 100px;
  height: 100px;
  border: 2px solid #9EE7BF;
}

.third-span{
  width: 88px;
  height: 88px;
  border: 1px solid #9EE7BF;
}

.four-span{
  width: 76px;
  height: 76px;
  border: 1px solid #9EE7BF;
  font-size: 18px;
  font-weight: 700;
  /* line-height: 150%; */
  color: #02843D;
}

.middle-input h3{
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.2rem;
  color: #4A4A4A;
  margin-top: -9%!important;
}
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.navbar ul{ display: flex;}



.navbar .flex{ justify-content: space-between;}
.text-type-details{
  font-weight: bold;
  font-size: 25px;
  margin: 0px;
}
.text-detail-order{
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  text-align: center;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
}
.mobile-order-detail{ display:none;}
.price-detail{
  font-weight: 600;
  font-size: 20px;
}
.title-detail-order{
  font-weight: bold;
  font-size: 20px;
  color: #4B4B4B;
}
.list-item-order-detail p { font-weight: 600;}
.title-detail-order p {font-weight: 600;}
.text-align-right{ text-align: right;}
.text-instructions{
  border: 0.5px solid #4A4A4A;
  padding-left: 8px;
  padding-top: 4px;
  height: 68px;
  border-radius: 5px;
  font-style: italic;
  color: #02843D;
}
.item-order-info{ line-height: 35px;}
.item-order-info {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.dots {
  flex-grow: 1;
  border-bottom: 2px dotted #000000; /* Dotted line */
  margin-top: 13px;
}

.price-detail {
  font-weight: bold;
}

.hrdetails {
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
}


@media only screen and (max-width: 720px){
  .text-type-details{font-size: 22px !important;}
  .mobile-order-detail{display: block !important}
  .desktop-order-detail{ display: none;}
  .text-detail-order{
    height: auto !important;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 16px !important;
  }
}
#earn hr {margin:0.5rem 0rem;}
#earn .earn-bg{background:rgba(255, 255, 255, 0.08); padding:15px 0;}
#earn .earn-bg1{background:rgba(255, 255, 255, 0.2); padding:15px 0;}
#earn .earn-bg2{ padding:27px 18px; height: 140px;display: inline;}
#earn .green-bg{
  background: rgba(2, 132, 61, 0.4);
  border-radius: 50%;
  height: 80px;
  width: 80px;
  position: absolute;
  color:#fff; text-align:center; padding-top: 20px;
  font-size: 13px;
}
#earn .connected-steps {
  display: flex;
  width: 100%;
  align-items: center;
	justify-content: center;
}
#earn .step {
  color: white;
  background: #fff;
  color:#4caf50;
  display: block;
  font-weight: bolder;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  padding-top: 20px;
  border: 2px solid #4caf50
}
#earn .connector {
  flex-grow: 1;
  width: 10px;
  content: "";
  display: block;
  height: 1px;
  background-color: red;
}
#earn .fa-arrow-right {
padding:0 5px;
font-size: 20px;
}
#earn .earntext {	font-size: 17px;}
@media (max-width: 440px) {
  .restaurantmaindiv{ left: 0px !important; }
  .search-filter-icon a .fa-sliders-h { margin-left: 0px !important;}
  .availablenowbutton{font-size: 11px !important; }
  .rewardimageclass{
    width: 120px;
    margin-top: 20px
  }
  .restaurant-menu-infor .m-tab__title,.restaurant-menu-infor .m-menu-sub-tabs {
    padding: 0px;
    font-size: 12px !important;
  }
  .sub-menu-row ul.dropdown-menu.show {
    min-width: 332px !important;
    width: 100% !important;
    left: -94px !important;
  }
  .loyaltyText{font-size: 12px !important  }
	#earn .social-links a {
	margin-right:8px !important;
	font-size: 14px !important;
}
#earn .earntext {font-size: 11px !important;}
#head-form-search-basic #search {
  /* margin-left:22% !important; */
  margin-top: 4px;
  }
  #recommended-by-localsspan {
    left: 30%;
}
}
@media screen and (max-width: 536px) and (min-width: 455px) {
	#earn .earn-bg2 {	padding-left:45px !important;}
}
span.btn.btn-xs.facebook-disconnect {
    font-size: .75rem !important;
    padding: 5px 5px !important;
}
span.btn.btn-xs.facebook-connect {
    font-size: .75rem !important;
    padding: 5px 5px !important;
}
.location-containersearch .form-control {
    font-size: 15px;
    padding: 0 15px;
    font-family: FontAwesome,Lato,sans-serif;
}
#searchinputrestaurant ,#searchinputmenuitem{
  font-family: FontAwesome,Lato,sans-serif;
}
span.btn.btn-xs.pinterest-share-connect{
    font-size: .75rem !important;
    padding: 5px 5px !important;
}
span.btn.btn-xs.pinterest-share-disonnect{
    font-size: .75rem !important;
    padding: 5px 5px !important;
}
.circle-rating{
  display: flex;
  align-items: center;
  width: 125px;
  height: 125px;
  background: #218838;
  border-radius: 50%;
  text-align: center;
}
@media only screen and (max-width: 767px) and (min-width: 651px){
  #earn .ratecss {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;
    padding: 30px 13px;
  }
}
@media only screen and (max-width: 650px) and (min-width: 541px){
  #earn .ratecss {
    width: 65px;
    height: 65px;
      border-radius: 50%;
    text-align: center;
    padding: 30px 13px;
  }
}
@media only screen and (max-width: 540px) and (min-width: 521px){
  #earn .ratecss {
    width: 60px;
    height: 60px;
	  border-radius: 50%;
    text-align: center;
    padding: 30px 7px;
  }
}
@media only screen and (max-width: 520px) and (min-width: 457px){
  #earn .ratecss {
    width: 65px;
    height: 70px;
	  border-radius: 50%;
    text-align: center;
    padding: 30px 13px;
  }
}
@media only screen and (max-width: 436px){
  #earn .ratecss {
    width: 50px;
    height: 50px;
	  border-radius: 50%;
    text-align: center;
    padding: 3px;
		font-size: 12px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 520px){
  #discount_flip_div .ratecss{
    background: #218838;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    padding: 12px 8px;
  }
  .earnheading{font-size: 13px; }
	#earn .rateheading{font-size:23px !important;}
}
.group-button-menu{
    margin: auto;
    max-width: 605px;
}
.not-available-request-order-ahead{display: none;}
@media only screen and (max-width: 390px){
  .basicsearch-sidebar {
    position: relative !important;
    top: -54px !important;
  }
  .mobile-filter {
    position: relative;
    display: block !important;
    margin-left: 4px;
  }
.item-list-friend{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
#getCurrentTime { width: 100% !important; }
.float-action-buttons{display: grid !important;}
.not-available-request-order-ahead{padding: 0px !important;}
.change-friend-ahead{ margin-left:0px !important;}
span.main-info-orange { padding-left: 10px;}
#earn_discount .ratecss{
	width: 40px !important;
	height: 40px !important;
}
#earn_discount .subhading {line-height: 1 !important;}
#results .social-links a { margin-right: 10px !important;}
}
@media only screen and (max-width: 409px){
  .bannertext{padding-top: 1% !important;z-index:0}
  .desktopmain{display: unset !important;}
	#earn_discount .rateheading {font-weight: 600; }
  #results .resturantmenu.flip {height: 440px;}
  #regular-menu ul.fancytree-container ul{ padding: 0px;}
}
@media only screen and (max-width: 1199px) and (min-width: 978px){
#earn_discount .ratecss{
	width: 45px !important;
	height: 45px !important;
}
}
#earn_discount .rateheading{
	font-size: 14px;
	font-weight: 600
}
#earn_discount .ratecss{
	width: 55px;
	height: 55px;
}
@media only screen and (max-width: 1199px){
#earn_discount .modal-content {line-height: 1.2 !important;}
#earn_discount .earnheading {font-size: 10px !important;}
#earn_discount span.main-info-orange {
    padding-top: 6px;
    float: left;
    font-size: 26px;
}
#earn_discount .info-percentage {
    position: absolute;
    right: 0;
    font-size: 10px;
    top: 6px;
    font-weight: bold;
}
#results .social-links a {margin-right: 20px !important;}
}
#earn_discount .earnheading{font-size: 12px;}
#earn_discount span.main-info-orange {
    padding-top: 25%;
    float: left;
    font-size: 26px;
}
p.right-info-text {
    display: table-cell;
    vertical-align: middle;
    height: 60px;
}
#earn_discount .info-percentage {
    position: absolute;
    right: 0;
    font-size: 18px;
    top: 7px;
    font-weight: bold;
    left:34%;
}
#earn_discount .modal-content{padding: 5px;}
#earn_discount span.percent-top{top: 10px;}
#earn_discount span.percentage-top{top: 15px;}
#earn_discount .flipsmallsubheading{float: right;}
#earn_discount .subhading {	background-color: #218838;}
#earn_discount .modal-header {
  border :none;
	padding: 0.5rem;
}
#earn_discount .modal-footer {padding: 0.6rem;}
#earn_discount span.right-info-text {
  font-size: 12px;
	padding-top:5px;
}
#earn_discount .flipformbox {
  margin-top: 6px;
	border-radius: 3px;
}
#discount_flip_div button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info) {
	  width: 20px;
    height: 20px;
    padding: 2px;
    margin-right:-10px;
}
#highestRated-container #earn_discount .flipformbox {
  height:42px;
}
.multi-factor-sorting .price-sorting { display: flex;}
@keyframes blink {
    0% {opacity: .2; }
    20% {opacity: 1;}
    100% {opacity: .2;}
}
.loading-span span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
.loading-span span:nth-child(2) {animation-delay: .2s;}
.loading-span span:nth-child(3) {animation-delay: .4s;}
button.gm-ui-hover-effect {
    top: 0px !important;
    right: 0px !important;
}
.mune-img.homepageimage.go-to-specified-restaurant{
  width: 100%;
  height: auto;
	position: relative;
}
#results .like-wishlist-container{
    position: absolute; 
    width: 100%;
    top: unset !important;
    bottom: 0;
    right: 0;
    height: 35px !important;
}
.btn-standard, #open_button, #restore_button{
  height: 35px!important;
  font-size: 14px!important;
}
#organization_meal_hour select, #times_day select{
  width:100px;
}
@media only screen and (min-width: 375px) and (max-width: 559px) {
    .pickupdelivery{top:58% !important;}
    .input-group-homepage {
      margin-left: 0px !important;
  }
  #search {
    margin-left: 10 !important;
    width: 219px !important;
}
.addcartbtn {
  width:344px !important;
}
}
/* only for ipad */
@media only screen and (max-width: 1025px) and (min-width: 767px) and (orientation : portrait){
  #results .results-col .icon{width: 71%;}
  #header #head-logo img{width: 39px !important; }
  #results .results-col .mune-img{ height: 167px; }
  #search-menuItemsDiv .results-col .mune-img{height: 170px; }
  #results .over-image-icons{font-size: 10px !important; }
  #results .over-image-iconstop{font-size: 15px !important; }
  .meet-btnhome{min-width: 69px !important;}
  #results .meet-btn{ width: 88%;}
  #results .flip{ height: 31rem; }
 
  #results .results-col p{ font-size: 14px !important;}
  .recommendedbyfriendbox {
    width: 411px !important;
    height: 44px !important;
  }
  #results .btn-rate{height: 22px !important; }
  .recommendedboximage {
    width: 411px !important;
    height: 44px !important;
    left: 375px;
  }
  .preferencestext{
    color:#02843D !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }
  .preferences{
    height: 34px;
    width: 134px;
    margin-left: 145px;
    margin-top: 1% !important;
    float: left !important;
  }
  .mainsplashscreen{
    top: 20%;
    right: 0;
    height: 100vh;
    width: 100%;
    bottom: 0;
    left: 0;
    position: relative;
  }
}
.modal-dialog{ pointer-events: visible !important;}
.noHover{   pointer-events: none;}
#salesperson_name li label span.icon-checkbox {
    float: left;
    margin-right:10px;
}
.search-input-height { height:32px!important;}
button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info) {
    background-color: #02843D;
    background-image: none !important;
    color: #fff;
    font-weight: 400 !important;
    font-size: 1em ;
    letter-spacing: 1px !important;
    text-align: center !important;
    text-shadow: none !important;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  left: 1px;
  top: 1.5px;
  visibility: hidden;
}
#social-media-ownership input[type="checkbox"],
#social-media-ownership input[type="radio"], #socialbyobCode input[type="checkbox"],
#socialbyobCode input[type="radio"] {
  position: relative;
  visibility: visible;
}
@media only screen and (max-width: 992px) and (min-width: 768px){
  .restaurant-menu-infor { width: 100% !important;}
  .input-group-append .inputgroup-width{ width: 26%;}
  span.location-containersearch{  width: 74%;  }
  #header #head-logo img { width:40px !important; }
}
@media (min-width: 1340px){
  .organization-container, .user-list-container{ max-width: 1220px;}
}
@media only screen and (min-width: 768px){

  .seating-capacity-input ,.seating-capacity-label { max-width: 80%;}
	#restaraunt_attributes_hours .nav.nav-pills{width:fit-content;}
}
#restaurantCollapse span.icon-checkbox.check-box-left {
    margin-right: 10px;
}
.custom-dropdown select {
    background: #fff !important;
    border: 1px solid #666 !important;
    color: #666!important;
}
/*restaurant page css */
.restaurant_main_infor {
	max-width:100%;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.0025em;
}
.restaurant-menu-infor .m-tab__title {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
 color: #000000;
}
.m-menu-sub-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 1.5px rgba(0, 0, 0, 0.05);
}
.restaurant_main_infor #venue-image-carousel {height: 236px;}
.restaurant-menu-infor .desktop-menu ul.dropdown-menu {
  width: auto;
}
.form-inline.w-100.m-tabs{ border: none !important}
.no_box_shadow {box-shadow: none;}
.restaurant-menu-infor .dropdown-toggle{
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  padding: 5px 7px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0015em;
  text-transform: uppercase;
  color: #02843D;
}
.sub-menu-row .button#menu1 {
    background: none !important;
    color: #000 !important;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: flex-end;
    text-align: right
  }
 .sub-menu-row .dropdown{ background: none !important;  }
 .sub-menu-row .row.search-avlable{ padding: 15px; }
.sub-menu-row ul.dropdown-menu.show {
    width: 440px;
    border-radius: 0;
}
.sub-menu-row ul.dropdown-menu.show i.fa {
    font-size: 21px;
    padding: 7px;
}
.sub-menu-row .dropdown-menu li {
    border-bottom: 1px solid #000;
    margin: 0 11px;
    font-size: 13px;
    padding: 10px;
}
.sub-menu-row .dropdown-menu li span{ float: right;}
.sub-menu-row .dropdown-menu li a{
  padding: 0;
  font-size: 16px
}
.sub-menu-row button i.fa {
  font-size: 18px;
  padding-left: 10px;
  color: green;
}
.sub-menu-row .btn:after{ content: none !important;}
.sub-menu-row span i.fa {    color: #4A4A4A;}
.restaurant_main_infor_txt #credit_discount_earn a{	color: #4A4A4A;}
.restaurant-menu-infor {  margin: auto;  width: 100% !important;}
.restaurant-menu-infor .form-group.dropdown-menu.show {
    width: 632px;
    padding: 40px;
}
.rest_btn_container {
  overflow: hidden;
  background-color: #E8ECEA;
}
.rest_btn_container a {
  float: left;
  display: block;
  color: black !important;
  text-align: center;
  padding: 8px 5px;
  padding-bottom: 5px;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  font-family: Lato;
  font-style: normal;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: flex-end;
}
.cdDesktop a {
  padding: 8px 8px !important;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  font-weight: 400 !important;
  font-size: 16px !important;
  display: -webkit-flex;
  display: -ms-flexbox;
  -ms-flex-align: end;
}
.cdDesktop a.active {
  color: black !important;
  color: white !important;
  border-bottom: 3px solid #02843d !important;
  font-weight: 600 !important;
  background-color: #02843d;
}
.cdMobile a.active {  color: #03843E !important;}
#form-search-basic span{  display: flex;}
.search-filter-icon a .fa-sliders-h {
    background-color: gray;
    padding: 3px;
    border-radius: 4px;
    font-size: 15px !important;
    margin-left: 15px;
}

.rest_order_btns_container a {
	float: left;
  display: block;
  text-align: center;
  padding: 8px 8px;
  text-decoration: none;
  font-size: 17px;
  font-family: Lato;
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	background: #FFFFFF;
	border: 1px solid #000000;
	box-sizing: border-box;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.0015em;
	color: #666;
  font-family: 'Lato',sans-serif;
	margin-left:4px;
}
@media only screen and (max-width: 769px)
{
  .restaurant_main_infor {
      padding-left:0px !important;
			padding-right:0px !important;
  }
  .restaurant-menu-infor {
    margin: auto;
    width: 100% !important;
  }
  .item-yelp-ratings-container {
    font-size: 10px;
    line-height: 16px;
  }
  .item-yelp-ratings-container {margin-left: 0px!important; }
  .restaurant-carousel-cust_btns{    margin-top: -75px;  }
}
.clicks-restraunt-views {
	margin-top: 0.062rem!important;
	margin-left: 0px!important;
}
.yelp-rating-count{	color: #4A4A4A!important;}
.meet-btn {
  background: #ececec;
	border: 1px solid #DCDCDC;
  color: black;
  border-radius: 4px;
  font-size: 13px;
  height: 35px;
  text-align: center;
  padding: 8px 1px;
  margin-bottom: 6px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.meetbtngreen{  background-color: #c8e2d4 !important;}
.meetbtnred{ background-color: #f17373 !important;}
.meetbtnbrown{ background-color: #e8b473 !important;}
.meet-btn i.fa{
    font-size: 15px;
    padding: 0 4px;
    font-weight: 400;
  }
.my-food-preferences {
    font-family: Lato;
    font-style: normal;
    font-size: 10px;
    line-height: 150%;
    background: #fff;
    font-weight: normal;
}
.my-food-preferences .foodpref-font {
    font-family: Lato;
    font-size: 14px;
    color: rgb(0, 0, 0);
    text-align: left;
    font-style: unset;
    padding: 11px 0px;
}
.col-sm-12.col-md-7.text-center>div{
    border: none !important;
    width: 500px;
}
.recommendedbox{
  padding: 20px 0 10px 0 ; box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-top: 10px;
  min-height: 230px;
  background: linear-gradient(101.31deg, rgba(136, 62, 62, 0.2) 5.85%, rgba(0, 255, 230, 0.2) 92.5%), #085077;
  background-blend-mode: exclusion, normal;
  border-radius: 4px;
  padding:10px;
}
.recommendedboxheading{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: flex-end;
  color: #FFFFFF;
}
.menu_item_title_fonts {
	font-style: normal;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 150%;
	color: #000000;
}
.discount_highlight{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 1px;
  font-size: 20px;
  padding: 5px 7px;
  background-color: #02843d;
  color: #fff;
  font-weight: 500;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
  display: table;
  text-align: left;
  text-decoration: underline;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7px;
  text-underline-offset: 5px;
}
.instructionsheading{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: black;
}
.orderinformation{
  color: #000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
#restaurant_search_filter_div .modal-dialog, #modal_search_filter_div .modal-dialog,  #reward_div .modal-dialog{
  max-width:626px;
}
#restaurant_search_filter_div .custom-control-label::before, #modal_search_filter_div .custom-control-label::before{
  width:14px;
  height:14px;
  left:-1.4rem;
}
#restaurant_search_filter_div .custom-control-label, #modal_search_filter_div .custom-control-label{
  font-size:10px;
  padding-top:3px;
}
#restaurant_search_filter_div .modal-content,#modal_search_filter_div .modal-content{
  border-radius: .2rem;
}

div#modal_search_filter_div .home-search-range-box{
  border: 1px solid #bdc6c1;
  height: 35px;
}
.rating_color_description {
	font-size: 14px;
  line-height: 19px;
  padding-top: 4px;
}
.m-card {
  background: rgba(214,209,209,0.2) ;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  height: max-content;
  width: 100%;
  margin: 1rem;
  padding: 1rem;
}
.m-card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.m-slider {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  border-radius: 6px !important;
  height: 6px !important;
  width: 100% !important;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, green), color-stop(0%, grey)) !important;
  background-image: -moz-linear-gradient(left center, green 0%, green 0%, grey 0%, grey 100%) !important;
  cursor: pointer !important;
  padding: 0px 0px 4px 0px;
}
.m-slider:focus {
  outline: none !important;
  border: none !important;
}
.m-slider::-moz-range-track {
  height: 6px !important;
  width: 100% !important;
  background: green !important;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: none !important;
}
.m-slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border: 3px solid green !important;
  background: none !important;
  background-color: wheat !important;
  height: 13px !important;
  width: 13px !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  cursor: pointer !important;
  margin-top: none !important;
}
.m-slider::-moz-range-thumb {
  -moz-appearance: none !important;
  cursor: pointer !important;
  background: green !important;
  background-color: green !important;
  height: 13px !important;
  width: 13px !important;
  border: none !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}
.rest-menuitem-description {
  line-height:16px;
  font-size: 14px;
}
.text-description-item {  cursor: pointer;}
 @media screen and (max-width: 1200px) and (min-width: 850px) {
  .rest-menuitem-description { font-size:14px !important;  }
 }
.m-slider::-webkit-slider-runnable-track {
  width: 100% !important;
  height: 0px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  background: green !important;
  border-radius: none !important;
  border: none !important;
}
.m-slider::-moz-slider-runnable-track {
  width: 100% !important;
  height: 0px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  background: green !important;
  border-radius: none !important;
  border: none !important;
}
.m-slider:focus::-webkit-slider-runnable-track {
  background: green !important;
  background-color: green !important;
}
.m-slider::-ms-track {
  width: 100% !important;
  height: 0px !important;
  cursor: pointer !important;
  background: green !important;
  border-color: none !important;
  color: none !important;
}
.m-slider::-ms-fill-lower {
  background: green !important;
  border: none !important;
  border-radius: none !important;
  box-shadow: none !important;
}
.m-slider::-ms-fill-upper {
  background: green !important;
  border: none !important;
  border-radius: none !important;
  box-shadow: none !important;
}
.m-slider::-ms-thumb {
  box-shadow: none !important;
  border: none !important;
  height: 0px !important;
  width: 100% !important;
  border-radius: none !important;
  background: wheat !important;
  cursor: pointer !important;
}
.desktop-menu{
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top: 1px solid rgba(0,0,0,0.05);
  box-shadow: 0 1.5px rgba(0,0,0,0.05);
}
.desktop-menu .m-menu-sub-tabs{
  border-bottom:none !important;
  border-top: none !important;
  box-shadow: none !important;
}
.clicksViewRestraunt, .clicks-counter {font-size: 10px!important;}
.dietdesc{
  margin-right:-18px;
  float: left;
  text-align: left;
  width: 142px;
  word-break: break-word;
}
.logovideosplashdesktop{
  height: 48px !important;
  object-fit: unset !important;
}
.fistscreenofsplash{  background: #f9f9f9; }
.logovideosplashdesktop{
  height: 48px !important;
  object-fit: unset !important;
}
.fistscreenofsplash{  background: #f9f9f9;}
.pdReview{  padding: 0px 15px;}
@media only screen and (min-width: 480px)
{
  #discount_flip_div .connected-steps {
   padding: 0px 36px;
  }
  #topPageRestaurant{
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .rate-social{
    margin-left: auto;
    margin-right: auto;
    max-width: 125px;
  }
}
@media only screen and (min-device-width: 350px) and (max-device-width: 450px){
  #modal-order-item-addons .cus-btn {
    height: 52px!important;
    margin-top:5px;
  }
}
@media only screen and (min-device-width: 370px) and (max-device-width: 400px){
  .order-for-later-box { margin-right:15%;  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 480px){
  .order-for-later-box {margin-right:20%;  }
}
@media only screen and (max-width: 400px){
  .rangeslider {    width: 60% !important;}
  #form-filter-menuitem{
    padding-left: 0px !important;
    padding-right:  0px !important;
  }
  .order-for-later-box {width: 47%;  }
  .order-for-later-leftbox { width: 53%;}
}
#modal-order-item-addons .cus-btn {
	width: 160px;
  height: 32px;
}
#modal-order-item-addons .decrease-number, #modal-order-item-addons_edit .decrease-number {
	border-top-left-radius:40px;
	border-bottom-left-radius:40px;
}
#modal-order-item-addons .increase-number, #modal-order-item-addons_edit .increase-number {
	border-top-right-radius:40px;
	border-bottom-right-radius:40px;
}
.head-location-container input#locationinputheader{
  width:180px;
}
.header-filter-orderType li a,.restaurant-filter-orderType li a{
  font-size:12px;
}
.header-filter-orderType,.restaurant-filter-orderType{
  min-height:30px;
  border-radius: 0px;
}
.header-filter-orderType li,.restaurant-filter-orderType li{
  border: 1px #000 solid ;
  background-color: #fff;
  color: #02843d;
}
.header-filter-orderType li.active,.restaurant-filter-orderType li.active{
  border: 1px #000 solid ;
  background-color: #02843d;
  color: #fff;
}
.menuheading{
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 29px;
  display: flex;
  align-items: flex-end;
  color: #000000!important;
}
.menusubheading, .favorites-heading{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  display: block;
  background-color: #EAEAEA;
  align-items: flex-end;
  color: #000000 !important;
  padding: 10px 15px !important;
  cursor: pointer;
}
.expertReview {
    width: 100%;
    position: relative;
    background: #fff;
    z-index: 9;
    margin: 10px 37px;
}
.see-review-title{
  width: 100%;
  background: #E8ECEA;
  margin: 0px;
  padding: 5px 15px !important;
  cursor: pointer;
  font-size: 12px;
}
.blur-div{
  position: absolute;
  bottom: 25px;
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), white);
}
#list-restaurant-reviews input[type=range]::-webkit-slider-thumb{
  width: 0px !important;
  height: 0px !important;
  border: 0px !important;
}
 .expertReview .row.flavor-title {  padding: 4px;}
.right_dc_item, .more_des{
  float: right;
  color: black;
  font-weight: bold;
  margin: 0px;
  font-size: 13px;
  cursor: pointer;
}
.menu-description{
  flex: auto;
  padding-top: 6px;
}
#modal-order-item-addons .add-to-cart {
  background: white;
  bottom: 0;
  padding: 5px;
  position: sticky;
}
/*#modal-order-item-addons .add-to-cart-actions-wrapper {
  padding: 1rem;
}*/
.loginmeetbtn{
  background-color: #F1F1F1;
  font-style:italic;
   position: absolute;
   left: -11px;
   top: 12px;
}
.rate-heart-plate.jq-ry-container{
    padding: 2px 9px;
}
#m-menu-tabs .list-group-item .form-inline{ min-width: 103px;}
.meet-btn span.slider-track {
    position: relative;
    width: 100%;
    background: none !important;
}
.my-food-preferences {
  position: relative !important;
  width: 100% !important;
  margin: 5px 10px ;
}
.all-dietary-preferences{
  background-color: #eee;
  margin-bottom: 4%;
  padding: 2%;
  margin-left: -4%;
  margin-right: -4%;
}
.divChoosebox {
  margin-left: -4%;
  margin-right: -4%;
}
.item-preferences{ padding: 5px 15px 15px 15px;}
li.select-suitable-dish {
    border: 1px solid silver;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    border-right: 0;
    background: #dddddd38 ;
    padding: 8px;
}
li.select-suitable-dish-all {
    border: 1px solid silver;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    border-right: 0;
    padding: 8px;
}
/*--------------rhs order model---------------*/
.hatsIcon {
  content: ' ';
  display: block;
  position: absolute;
  right: 12%;
  top: -15px;
  border: 15px solid #e8e8e8;
  border-bottom-color: transparent;
  border-right-color: transparent;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
       -o-transform: rotate(45deg);
          transform: rotate(45deg);
}

#myorder-accordion .modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		height: 100%;
		width : 450px;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	#myorder-accordion .modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}

	#myorder-accordion .modal.right.fade .modal-dialog {
		right: 0px;
    height: 0px;
    top: 65px;
		-webkit-transition: height 0.3s ease-out;
		   -moz-transition: height 0.3s ease-out;
		     -o-transition: height 0.3s ease-out;
		        transition: height 0.3s ease-out;
	}

	#myorder-accordion .modal.right.fade.show .modal-dialog {
		right: 0;
    top: 65px;
    height: 100%;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
	}

/* ----- MODAL STYLE ----- */
	#myorder-accordion .modal-content {		border: none;	}
	#myorder-accordion .modal {  background: transparent;
    right: 0;
    z-index: 9990 !important;
  }
	#order-container ul li a{		padding: 4px 5px;  }
.radio-block-button .label-text{
	display: inline-block;
	padding: 5px 50px;
	font-size: 14px;
	border: 1px solid #1F6D42;
	border-radius: 5px;
}
.radio-block-button input[type="radio"]+.label-text:before {
  opacity: 0;
  position: fixed;
}
.radio-block-button input[type="radio"]:checked + span {
    background-color:#02843D;
    color: #fff;
}
#modal_search_filter_div .ordertype-control label.custom-control-label, #restaurant_search_filter_div .ordertype-control label.custom-control-label{
	display: inline-block;
	padding: 4px 55px;
	font-size: 12px;
	border: 1px solid #1F6D42;
}
#modal_search_filter_div .ordertype-control input[type=checkbox] + label .custom-control-label:before, #restaurant_search_filter_div .ordertype-control input[type=checkbox] + label .custom-control-label:before {
  opacity: 0;
  position: fixed;
}
#modal_search_filter_div .ordertype-control input[type=checkbox]:checked + label.custom-control-label, #restaurant_search_filter_div .ordertype-control input[type=checkbox]:checked + label.custom-control-label {
    background-color:#02843D;
    color: #fff;
}
#modal_search_filter_div .ordertype-control label.custom-control-label:before, #restaurant_search_filter_div .ordertype-control label.custom-control-label:before{
	visibility: hidden;
}
 #modal_search_filter_div .ordertype-control .custom-control-input:checked~.custom-control-label::after, #restaurant_search_filter_div .ordertype-control .custom-control-input:checked~.custom-control-label::after{
	background-image: none;
 }
 #modal_search_filter_div .orders-type-container, #restaurant_search_filter_div .orders-type-container{
	margin-left: 0px;
 }
 @media only screen and (max-width: 575px) {
   #intro .homepage-container{
    width: 100%;
    height: 100% !important;
    display: flex;
    margin-top: -3% !important;
    background-size: 100% 100% !important;
    background-image: url('../images/home-bg-mobile.jpeg') !important; 
  }
 }
@media only screen and (max-width: 480px) {

  .title-top-rating {  padding-left: 15px !important;  }
  .fancytree-node .restaurant-menu-item, .item-discount, .servings, .days-order-in-advance, #pick-address-distance {
    font-size: 12px !important;
  }
  .btn-neverplay {font-size: 12px !important;}
   #results .flip2 { height: 30rem !important; }
  .menuItemsId .flavor-match-circle{
    top: 7px !important;
    right: 57px !important;
    float: right!important;
    position: relative;
  }
  .share-mail-for-friend{width: 114px;  }
  .sharedonebutton{
    position: fixed;
    bottom:  5%;
    right: 34%;
  }
  div .item-details {
    width: 100% !important;
    border-right: none;
  }
  .loggedinheadericon{ display: none; }
  #food-pref .select-btn{
    width: 100% !important;
  }
  .rating-img {
    max-width: 213px !important;
    max-height: 300px !important;
  }
  .rest-dropdown-text{font-size: 14px !important; }
  #headersearchnavpill{
    height:40px;
    border: none;
  }
  #headersearchnavpill li a{
    padding-top: 7px !important;
    background: #EAEAEA;
    height: 100% !important;
    font-size: 13px;
  }
  #headersearchnavpill  li a.active{
    background-color: #02843d !important;
    border-radius: 0px !important;
  }
  #headersearchnavpill li{ margin-top: 0px;}
  #headersearchnavpill {height: 40px !important;}
  #intro .home-missions-heading {font-size: 16px !important;}
  #intro .home-missions-text {font-size: 11px !important; }
  #intro .home-customer-rest-btn ul#headersearchnavpill {
      width:70% !important;
      margin: 0 auto;
      border-radius: 50px !important;
      padding:0px !important;
  }
  #intro .home-customer-rest-btn ul#headersearchnavpill li {
      max-width: 50%;
	width:100%;
  }
  #intro .home-customer-rest-btn ul#headersearchnavpill li a{
      padding: 7px 4vh !important;
  }
  #intro .home-customer-rest-btn li a{font-size: 12px !important; }
  .homepage-label {border-radius: 20px !important;}
  .mobile-fix{ padding: 0px !important;padding-bottom: 6px !important;}
  #results a.item-name.item-title{
    font-size:14px!important;
    font-weight: 600;
    color: #000000 !important;
  }
  #results .results-col .price-title{
    font-size: 14px !important;
    font-weight: 600;
  }
  #results .results-col .mune-img{height:  auto;  }
  #results .flip { height: 31rem;}
  #recommended_friends .delivery-time-icons, #home-restlocations-miles .delivery-time-icons, #dietpref-venues .delivery-time-icons {
  position: unset !important;
  bottom: unset !important;;
}
  #results .over-image-icons{margin-right: 10px !important;}
  #results .over-image-iconstop{
    font-size: 15px !important;
    margin-top: -45px !important;
  }
  #regular-menu .img-thumbnail{ padding: 0px  !important;}
  .results_info{ width: 100% !important; }
  #results .hvr-underline-from-center.star-rating.mobileratingdiv {
    top: 37% !important;
    position: absolute;
  }
  #results .star-rating{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    margin-left: 28%;
  }
  #results .homecontainer .star-rating{
    margin-left: 0%;
  }
  #mapviewbtn{
    width: 76px !important;
    height: 22px !important;
    left: 289px;
    top: 154px;
    border: 1px solid #02843D !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
  }
  .rest-menuitem-description, .fix_text_mobile{
    width: auto;
    word-break: break-all;
    text-overflow: ellipsis;
  }
  .ranting_items svg {
    width: 12px !important;
    height:12px !important;
  }
  .rate-heart-plate.jq-ry-container{
    width: auto !important;
    padding: 2px 9px;
  }
  .rest-menuitem-description{
    line-height: 16px !important;
    font-size: 14px !important;
  }
  .loginmeetbtn{ top: 0px !important;  }
  .total_review_item{ font-size: 12px;}
  .menu-description{
    padding-top: 0px;
    min-height: 20px !important;
  }
  .images-of-menu-item {  width: 100% !important;}
  .rest-menuitem-description{ margin-bottom: 0px !important;}
  .flavor-match {  padding: 0 !important; }
  .mobile-fix .justify-content-center{
    height: auto !important;
  }
  .menuitem_rr{
    min-height: 88px !important;
    padding: 0px 0px;
  }
  .item-check {padding: 4px;}
  .input-group-location {margin-left: 2% !important;}
  #desktopsubheader span.input-group-location {margin-left: 0% !important;}
  .discount_highlight{
    font-weight: 500;
    left: 1px;
    padding: 7px 7px;
    font-size: 14px;
    text-decoration: none;
  }
  .right_dc_item, .more_des{
    font-size: 11px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
  }
  .more_des{
    display: block !important;
    color: #02843d !important;
    margin-right: 6px !important; 
  }
  .favorite-item, .regular-menu{  padding: 0px;}
  #modal-order-item-addons .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  #modal-order-item-addons .modal-content {
    height: 100%;
    border-radius: 0;
    position:relative;
  }
  #modal-order-item-addons .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    /*max-height: calc(100vh - 220px);*/
  }
  #modal-order-item-addons .modal-footer {
    border-radius: 0;
    bottom:0px;
    width:100%;
    background: white;
  }
  #modal-order-item-addons .add-to-cart { padding: 1em 0.25em; }
  #modal-order-item-addons .input-group-text {padding: .375rem .25rem; }
  #modal-order-item-addons #add-to-order { padding: .375rem .25rem; }
  .restaurant_card_badge{
    position:relative !important;
    margin-top:0px !important;
    top:0px !important;
    font-size: 11px;
    text-align: left;
    left:10%;
    color: #000;
    margin-left: -8px !important;
    vertical-align:top;
    border-radius: 50%;
  }
  .shopping_card_badge, .rewards_card_badge{
    position:relative !important;
    margin-top:0px !important;
    font-size: 11px;
    text-align: left;
    left:2%;
    color: #000;
    vertical-align:top;
    border-radius: 50%;
    margin-left: 0 !important;
  }
  div#item-review-count{ margin-left: -3px !important;}
  /* #results .menuItemsId .star-rating{margin-left: 35% !important;} */
 .mobile-store-icon{
   position: absolute !important;
   bottom: 52px !important;
 }

a#download_personal_data{ font-size: 12px;}
#results .results-col .earn{font-size: 14px !important;}
#MenuResult .btn-rate{  font-size: 10px !important;  width: 59px !important;}
#rest .rest-col .icon{left: calc(40% - 89px) !important;}
#results .star-rating{
  padding: 0px !important;
  width: auto;
  overflow: visible;
}
button[type="button"].btn-danger{font-size: 1em !important;}
button[type="button"]:not(.btn-danger):not(.btn-warning):not(.btn-info){
  font-size: 12px !important;
}
section#food-pref, div#saveBtn-2, .submitDiv.saveBtn-3{ padding-bottom:0px;}
section#food-pref .profile-food-prefs{
  max-width: 95% !important;
}
.inputSwitch{  margin-top: -2px !important;
  text-align: center;
  position: absolute;
  font-size: 16px;
  min-width:250px !important;
}
.hatsIcon {display: none !important;}
#icon-close-order .material-icons{ display: none !important;}
#icon-close-order .fa-times{
  display: block !important;
  position:fixed;
}
.meetflavordiv{
  flex: none;
  color: black;
  max-width: 100%;
}
#results .results-col p {
  font-size: 14px !important;
  font-weight: 500 !important;
}
#results .restimageeclipse {
  
  width: 100% !important;
}
#results .itemImage {height: 100%;}
#results .btn-cart {
    height: 30px !important;
    margin-top: 0px !important;
    line-height: 30px;
}
#results .btn-rate {
    width: 50px !important;
    height: 30px !important;
}
#order-modal-content .cart-actions-sticky .eligible-credits-label {
  font-size: 14px !important;
}
ul.slick-dots.firstslick{ right: 1%; }
.splashmobo2{ background-color: #fff !important;}
.fistscreenofsplash{
  background: #fff;
}
a.delivery.cartordertype{ padding-left: 28% !important; }
a.pickup.cartordertype{padding-left: 28% !important; }
a.order-ahead.cartordertype{    padding-left: 28% !important;  }
a.waitlist.cartordertype{    padding-left: 28% !important;  }
.font-11{  font-size: 10px !important;  }
.dietdesc{
  margin-right: -18px;
  text-align: right;
  width: 63px;
}
.dietcircle{
  float: right;
  margin-left: 42px;
}
  .main-border{    width: 100% !important;  }
  #results .results-col .icon { width: 96% !important; }
  .restimageeclipse{
    width: 135px !important;
    height: 120px !important;
    border-radius: 8px 0px 0px 8px !important;
  }
  .meet-btnhome{min-width:77px !important;}
  .pdReview {padding: 0px !important;}
  .addbtn-cart{
    width: 100% !important;
    float: center;
  }
  .search-avlable .dropdown-menu{left: -112% !important;  }
  /* #close-upload-modal { display: none;} */
  #modal-upload-images {margin-bottom: 73px; width: 90%;}

  /* #list-of-uploaded-images .btn-image-rotate {width: 348px !important; } */
  #list-of-uploaded-images #upload-footer{ width: 355px !important; }
  #order-modal-dialog .modal-empty-btn{ font-size: 16px !important; }
  #earn_discount .info-percentage{   left: 52%; }
  .restaurant-bg-width{width: 100% !important;}
  .highest-rated-items-labels {
      padding-left: 20px;
      padding-right: 12px;
      letter-spacing: -0.8px;
  }
  .my-food-preferences { margin: 5px 0px !important;}
  .flitercss{ display: block !important;}
  /* .sortbytextclass{display:block !important;} */
  .sortbytextmobo{display:flex !important; top: 0 !important;}
  .rating-stars { margin-left: 5px !important;}
  .mitem-reviews{ padding-right: 10px !important;}
  #recommended_friends .food-flavor.homepage-prefs-div, #recommended-by-locals .food-flavor.homepage-prefs-div {
    bottom:  0% !important;
  }
  .topreview{margin-top: 3px !important;}
  .search-my-restaurant{text-align: center;}
  .resulttext{
    font-size: 18px !important;
    margin: 15px 0 24px !important;
    border-bottom: none !important;
    line-height: 2.1em;
  }
  .homepage-earn-percent-img{
    width: 121px;
    top: -5rem !important;
    right: 0 !important;
  }
 
  .home-preferred-by-rest{
    margin-top: -30% !important;
    font-style: normal;
    font-weight: bold;
    border-bottom:  none !important;
    width: 100% !important;
    margin-left: 0% !important;
  }
  /* #intro {padding-top:16px !important;} */
  #hometopheader{height: 600px !important; width: 100%;}
  .home-earn-percent-img{
    width: 99% !important;
    left: 0% !important;
    margin-left: 1% !important;
  }
  .sharedbutton{width: 86px !important;}
  .headingmyplaterate{margin-left: 9% !important;}
  .firstlastname{ margin-top: 36px !important;}
  .menuitem_rr {  height: auto !important;}
  .header-bg .head-location-src-box input[type=text] {
     padding-left: 18%!important;
  }
  div#downloadCardPDF, div#downloadThisCard {font-size: 14px !important; }
  .menuitem_rr .meetflavordiv {
    padding-left: 0;
    position: relative;
  }
  .menuitem_rr .meetflavordiv .col-12 {padding: 0;}
  .menuitem_rr .meetflavordiv .col-12 div .meet-btn {padding: 0; }
  .menuitem_rr .meetflavordiv .col-12 div .meet-btn.cus-btn-fluid {padding: 4px !important;}
  .flavor-match-login {
    padding-top: 1px;
    width: 100%;
    padding-bottom: 1px;
  }
  #orderdatepicker {
    font-size: 12px !important;
    padding: 5px 6px !important;
  }
  #modal-change-delivery .btn-container div {
    padding-left: 3px;
    padding-right: 3px;
  }

  .header-searchinputspan button#head-search-button  {
    margin-right: 14px !important;
  }
  .header-searchinputspan button#head-search-button  { 
    margin-right: 6px !important;
    position: absolute;
    right: 0;
    margin-top: -33px !important;

  }
  .marginTopMbl{margin-top: 7px;}
  #collapseRestaurantReview .rest-min-max-text{ font-size: 14px;}
  .nearclass{  margin-top: -3px;}
  #homehead-location-input-search {
    width: 100% !important;
  }
  #intro input.searchinputcenter{
    border-radius: 25px !important;
    border-right-color: #979797 !important;
  }
  #intro input.searchinputcenterlocation {
    margin-top: 0px !important;
    border-radius: 25px !important;
    border-left-color: #979797 !important;
  }
  #restaurant-menu{ margin: 0px !important;  }
  .expertReview{ margin: 10px 10px;}
   .usd-currency {     flex: 0 0 18%;   }
   .find {    padding-top: 0 !important;  }
  div#menuShow{    margin-bottom: 112px !important;  }
  .restaurant-menu-btn{    font-size: 12px !important;  }
  .recommendedbyfriendbox {
    width: 411px !important;
    height: 70px !important;
  }
  .recommendedboximage {
    width: 411px !important;
    height: 44px !important;
    left: 375px;
  }
  .preferencestext{
    color:#02843D !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }
  .preferences{
    height: 34px;
    width: 134px;
    margin-left: 145px;
    margin-top: 1% !important;
    float: left !important;
  }
  .container{
    padding-bottom: 1px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* margin-top: 35px !important; */
  }
  #modal_search_filter_div .orders-type-container, #restaurant_search_filter_div .orders-type-container{
    margin-left: 0px;
  }
  #modal_search_filter_div .ordertype-control label.custom-control-label, #restaurant_search_filter_div .ordertype-control label.custom-control-label{
    display: inline-block;
    padding: 4px 20px;
    font-size: 12px;
    border: 1px solid #1F6D42;
  }
  .venue-name {font-size: 20px !important;  }
  strong.black.col-md-9.col-12.font-subheader-m {
    margin-top: 120px;
  }
  .recommendedheadingbox .earn-invite-box{
    max-width:72% !important;
    text-align: center;
    padding-left: 0px;
    padding-right: 3px;
  }
  .recommendedheadingbox .earn-invite-box.expanded-text-full{
    max-width:100% !important;
  }
  .recommendedheadingbox .discoverfood{margin-left: 0px;}
  .recommendedheadingbox .flavorpreferences{
    margin-left: 0px;
    line-height: 18px;
    margin-top: 10px;
  }
  .recommendedheadingbox{ height: auto !important;}
  .recommendedheadingbox .inviteBtn {
    text-align: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 5px;
    display: block;
  }
  div#order-modal-dialog{
    width: 100% !important;
    height: 100% !important;
  }
}
 @media only screen and (max-width: 420px) {
  .mobile-text-look {
    font-size: 9px !important;
  }
  .offers-section {
    padding: 1rem 1.5rem !important;
  }
  .terms-form {
    background: white;
    padding: 10px 5px 0px 5px;
  }
  strong.black.col-md-9.col-12.font-subheader-m {
    margin-top: -50px;
  }
}
@media only screen  and (min-device-width: 355px) and (max-device-width: 667px)  and (-webkit-min-device-pixel-ratio: 2) {

  .intro-container .first_home{margin-right: 29px !important; margin-top: 120px !important;  right: 0% !important;}

  .flavorpreferences {
    font-size: 12px !important;
    text-align: center;
  }
  button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: none !important;
  }
  .happy-hour {   display: none; }
  .recommendedheadingbox .discoverfood{
    margin-left: 0px;
    /* text-align: left; */
    font-size: 20px !important;
  }
  .recommendedheadingbox button.inviteBtn{
    margin-left: 0px;
    width:100%;
  }
}
.recommendedheadingbox a.inviteBtn:focus{
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #000;
}
.venue-name {
  color: #000 !important;
  font-weight: bold;
  font-size: 24px ;
  line-height: 29px;
}
.platerate_review_outer_div ,.clicksViewRestraunt {
	color: #02843d!important;
}
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    color: #aaa;
}
.text-block{
  position: absolute;
  top: 0px;
  left: 12px;
  width: 85%;
  color: white;
  font-size: 12px;
  text-decoration: underline;
  border-radius: 3px 0 0 0;
}
/* .homebannertext{ padding-top: 7%; position: relative;} */
/* .bannertext{ position: absolute;} */
.banneradvance{ position: relative;}
.recommendedheading{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
}
.recommendedheadingbox{
  height: max-content;
  background: #FFF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  padding-right: 11px;
}
.recommendedboximage{
  width: 538.08px;
  height: 100px;
  float: right;
  background: url('../images/recommendedboximage.png'), #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  border-radius: 4px;
}

.discoverfood{
font-style: normal;
font-weight: 900;
font-size: 22px;
line-height: 33px;
display: flex;
align-items: flex-end;
color: #000000;
margin-left: 72px;
}
.flavorpreferences{
  font-size: 16px;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 72px;
}
.inviteBtn {
  margin: -5px 0 14px 72px;
  border-radius: 5px;
  padding: 8px 32px;
}
.recommendedbyfriendbox{
  width: 538.08px;
  height: 90%;
  float: right;
  background: url('../images/recommendedfriend.png'), #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  background-size: cover;
  margin: 6px 0;

}
#results .results-col .yelp-rating-stars .yelp-rating-count{
	font-size: 12px;
}
.home_order_btns span{
	color: #707070;
	cursor: pointer;
	height: 32px;
	font-weight: 600;
	font-size: 14px;
}
.cust-form-check{	height: 36px!important;}
.max-width-span {
  min-width: 100%;
  text-align: center;
}
.joined-orders {  padding-left: unset;}
.order-tree {  padding-left: unset;}
.sidebar_heading_font {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
.preferences{
  width: 131px;
  height: 34px;
  float: right;
  margin-right: 10px;
  margin-top: 10%;
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  z-index: 11;
}
.preferencestext{
  position: static;
  width: 111px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
  margin: 5px 9px;
}.sidebar_heading_font {
	color: #000000;
	font-weight: 600;
	font-size: 16px;
	line-height: 150%;
}
.happyHoursBox {
	background: rgba(255, 255, 255, 0.13);
	border: 0.5px solid rgba(0, 0, 0, 0.32);
	height: 41px;
	border-radius: 4px;
}
.basicsearch-sidebar label{
	font-size: 12px;
	font-weight: 600;
	color: #000000;
	line-height: 150%;
}
.basicsearch-sidebar .custom-control-label {padding-top:3px;}
.range-control {  position: relative;}
.basicsearch-sidebar input[type=range] {
  display: block;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  outline: none;
}
.basicsearch-sidebar input[type=range]::-webkit-slider-runnable-track {
  position: relative;
  height: 5px;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	padding-top:1px;
}

.basicsearch-sidebar input[type=range]::-webkit-slider-thumb {
  position: relative;
  width: 15px;
  height: 15px;
  border: 3px solid #02843D;
  -webkit-appearance: none;
  background-color: #FFFFFF;
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  cursor: pointer;
}

.basicsearch-sidebar output {
  position: absolute;
  top: -25px;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  font-weight: 500;
  color: #000000;
}

.basicsearch-sidebar input[type=range]:active + output {
  display: block;
  transform: translateX(-50%);
}

#mobilefooter-new {   display: none ;}
.footerlogo{ height: 18px;}
.text-block .menu_item_title_fonts {
  font-weight: 500;
  line-height: 150%;
  color: #FFFFFF;
  background-color: #02843D;
  padding: 4px 7px;
}

input.wide-input {
  display:inline;
  height: 60px;
  font-size: 18px;
  border-radius: 0px;
  border-color: black;
  background-color: white;
  color: black;
  text-align: center;
}

.menueratingsection{
top: 36.5%;
height: 35px;
bottom: 62.42%;
border: 1px solid #02843D;
box-sizing: border-box;
border-radius: 4px;
}
.cart-actions-sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 50px;
  background-color: white;
  padding: 30px 0px 30px 0px;
  /* font-size: 20px; */
}
.findclassheader{
  padding-top: 3%;
  background: none;
  color: #000;
  border: none;
}
.findclass{
  background: none;
  color: #000;
  border: none;
}
.head-search-icon{
  background: none;
  color: #000;
  border: none;
  margin: -1px 0 0 0px;
  cursor: pointer;
}
.cartBtn{
  position: relative;
    top:-24px;
    margin: 0 auto;
    float:none;
 }
 @media only screen and (max-width: 560px){
  .cartBtn{
    position: relative;
    top:0;
    margin: 0 auto;
    float:none;
  }
  .moveCheckBox{
    display:flex;
    justify-content:center;
  }
  .free-food-label { font-size: 20px !important;}
}
.head-mobilesearch-button{
  background: 0;
  color: #000;
  border: 0;
  margin-left: 183px;
  padding: 11px;
  z-index: 999;
  cursor: pointer;
}
.dietary_preferencesection{
  height: auto;
  background: #F5F5F5;
  border-radius: 8px;
}
/* NEW SWITCHEs Style */
.toggle-switch {
	 margin: auto;
	 width: 55px;
	 background: white;
}
 .toggle-switch input.mobileToggle {
	 opacity: 0;
	 position: absolute;
}
 .toggle-switch input.mobileToggle + label {
	 position: relative;
	 display: inline-block;
	 user-select: none;
	 transition: .4s ease;
	 height: 30px;
	 width: 60px;
	 border: 1px solid #e4e4e4;
	 border-radius: 60px;
   background-color: #BDC6C1;
}
 .toggle-switch input.mobileToggle + label:before {
	 content: "";
	 position: absolute;
	 display: block;
	 transition: .2s cubic-bezier(.24,0,.5,1);
	 height: 30px;
	 width: 58px;
	 top: 0;
	 left: 0;
	 border-radius: 30px;
}
 .toggle-switch input.mobileToggle + label:after {
	 content: "Off";
	 position: absolute;
	 display: block;
	 box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 0px 0 rgba(0,0,0,0.04), 0 4px 9px rgba(0,0,0,0.13), 0 3px 3px rgba(0,0,0,0.05);
	 transition: .35s cubic-bezier(.54,1.60,.5,1);
	 background: whitesmoke;
	 height: 28px;
	 width: 36px;
	 top: 1px;
	 left: 0px;
	 border-radius: 60px;
   text-align: center;
}
 .toggle-switch input.mobileToggle:checked + label:before {
	 background: #02843d;
	 transition: width .2s cubic-bezier(0,0,0,.1);
}
 .toggle-switch input.mobileToggle:checked + label:after {
	 left: 24px;
   content: "On";
}
.restaurant_main_infor .item-gallery{
  position: relative !important;
}
.restaurant_main_infor .col-12.col-md-7.order-sm-12 .rest-slider{
    background: #dddddd4a;
    display: flex;
    border-radius: 15px;
}
.restaurant-carousel-cust_btns a { font-size: 11px !important; }
.venueHoursLink{
  font-family: Lato;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  align-items: center;
  letter-spacing: 0.0015em;
  color: #000000;
 }
.restaurant_main_infor .row.restaurant-carousel-cust_btns.pl-md-3 {
    margin: 10px 0;
}
.restaurant-carousel-cust_btns .carousel-cust_btns{
  display: inline-flex;
  margin: 10px 0;
}
.restaurant_main_infor .platerate_review_outer_div img{
  width: 102px;
  margin: 10px;
  height: 26px;
}
.rewards{  display: block;}
.aboutus{  display: block;}
.flavor-match-circle {
  border: 0.5px solid !important;
  border-radius: 50%;
  text-align: center;
  width: 7px;
  height: 7px;
  padding-left: 1.5px;
  padding-right: 1.5px;
  bottom: 7px;
  right: 2px;
  position: relative;
  font-weight: bold;
  font-size: 5px;
}
.menuItemsId .flavor-match-circle {
  bottom: 7px;
   right: 2px;
  position: relative;
}
#Whats_unique .flavor-match-circle, .link-about-us .flavor-match-circle {
  bottom: 10px;
  right: 4px;
  position: relative;
}
.modal_search_filter_div .flavor-match-circle {
  right: 5px;
  position: relative;
}
#highestRated-container .flavor-match-circle {
	right: 2px;
	bottom: 7px;
  position: relative;
}
.mobile_backarrow{  display: none;}
.mobile_backarrow img{  max-height: 50px;}
.menuitem_location_miles {  line-height: 100%;}
.meet-btn span {
  font-size: 15px !important;
  font-weight: 900 !important;
  color: black;
  cursor: pointer;
  margin: 0 4px;
	line-height:16.46px;
}
.image-upload {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    background: #E9E9E9;
    overflow: hidden
}
.item-wrapper form img {
    margin-bottom: 20px;
    width: auto;
    height: auto;
    max-height: 400px;
    width: auto;
    border-radius: 5px;
    overflow: hidden
}
.image-upload img {
    height: 100% !important;
    width: auto !important;
    border-radius: 0px;
    margin: 0 auto
}
.image-upload i {font-size: 3em;}
.image-upload input {
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0
}
.item-wrapper input {
    height: 43px;
    line-height: 43px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px
}
/* for order cart modal background should be active */
.my-ordermodal-backdrop {  display: none !important;}
.rest-menu-multi-factor-filtering .custom-control-input {
    z-index: 1;
    opacity: 0;
}
.rest-menu-multi-factor-filtering input[type="checkbox"] {
    position: absolute;
    left: 1px;
    top: 10.5px;
    visibility: inherit;
}
.meetflavordiv{
  padding: 5px 15px 0 0px;
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top: 0;
}
a.flavor-match {
    min-height: 20px;
    width: 110px;
}
div#modal_search_filter_div{ bottom: 52px;}
#mapviewbtn{
  width: 125px ;
  border-radius: 4px !important;
}
#listviewbtn{
  width: 125px !important;
  border-radius: 4px !important;
}
.inputSwitch{
  margin-top: 11px;
  color: #02843d;
  min-width: 164px;
}
.meet-btnhome{min-width: 87px;}
div#search_filter_delivery input[type=range]::-webkit-slider-thumb {
  margin-top: -5.5px;
}
div#search_filter_distance input[type=range]::-webkit-slider-thumb {
  margin-top: -5.5px;
}
div#search_filter_deliveryfees input[type=range]::-webkit-slider-thumb {
  margin-top: -5.5px;
}
#waitlist-notification-setting .label-text{
  font-weight: normal !important;
}
span.mobile_restaurant {
    text-align: center;
    display: grid;
    margin-right: 0px;
    display:none;
    font-size: 12px;
    color: #4A4A4A;
    font-weight: 900;
}
span.mobile_cart {
    text-align: center;
    display: grid;
   font-size: 12px;
    color: #4A4A4A;
}
.mobile-bottom-nav{
	bottom:0;
	left:0;
	right:0;
	z-index:1000;
	will-change:transform;
	transform: translateZ(0);
	display:flex;
  background-color:#fff;
  border-top: 1px solid #80808026;
  padding-bottom: 4px;
  padding-top: 5px;
}

.mobile-bottom-nav__item{
  flex-grow:1;
  text-align:center;
  font-size:12px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  color: black;
}
.mobile-bottom-nav__item--active{ color:red;}
.mobile-bottom-nav__item-content{
  display:flex;
  flex-direction:column;
}
.top_item_nav{  height: 21px;}
.count_Restautrent {text-align: center;}
.restaurant-toast-top-right {
    margin-top: 40px;
}
.headsearch{
  float: right;
 position: relative;
  /* padding-top: 6px; */
  z-index: 2;
  margin: -34px 6px 0 1px !important;
  height: 32px;
  margin-right: 35px !important;
  color: #4a4a4a;
}
.headsearchhome{
  float: right;
  position: relative;
  padding-top: 5px;
  z-index: 2;
  margin: -39px 10px 0 -16px !important;
  height: 32px;
  margin-right: 1% !important;
  color: #4A4A4A;
}
.tophead-search-filter{
  margin: -34px 4px 0 -4px !important;
    border-left: 1px solid rgba(0,0,0,0.25);
    float: right;
    /* background-color: #fbfbfb; */
    position: relative;
    padding-top: 8px;
    z-index: 2;
    height: 33px;
    color: #4a4a4a;
}
.head-search-filter{
  float: right;
  position: relative;
  padding-top: 8px!important;
  z-index: 2;
  height: 44px;
  color: #4A4A4A;
  border-left: 0.5px solid #c4c4c4;
}
.intro-container .head-search-filter{padding-top: 8px;}
button.close.btn { height: 30px; width: 30px;}
span.mobile_home {
    text-align: center;
    display: grid;
    color: #4A4A4A;
    font-size: 12px;
    font-weight: 900;
}
i.fa.fa-shopping-cart.shopping_card {
   font-size: 23px !important;
}
/* for ios phone 11 and 10 */
@media only screen and (min-width: 375px)
{ 
  i.fas.fa-store-alt {margin-left: 21px;}
  .mobile-filter{
    position: relative;
    /* display: block !important; */
    margin-left: 4px;
  }
}

/* for ios phone 11 and 10 */
@media only screen and (max-width: 375px)
{ 
  .addcartbtn {
    width: 327  px !important;
  }
}
.range-control {position: relative;}
.home-rangevalue {  width: 100%;background-color: #02843D;border-radius:8px}
.home-range-bubble {
  position: absolute;
  font-size: 12px;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
}
span a.signup-sidenav:hover{
  color: #02843d !important;
  border: 1px #02843d solid;
  background-color: #fff;
  text-decoration: none;
  margin-right: 20px;
  padding-right: 7px;
  padding-left: 11px;
}
ul#ui-id-2 {z-index: 1100 !important;}
/* for ios phone 5s and 11 max pro */
@media only screen and (min-device-width: 320px) and (max-device-width: 810px) {
  .rewards{display: none !important;  }
  .aboutus{display: none !important;  }
  #mobilefooter-new{
    border-top: 1px solid grey;
    background: #fff;
    z-index: 999;
    display: block;
    position: fixed;
    height: 73px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
}
.carausalmenuitem{ max-height: 412px !important;}
.rest-menu-multi-factor-filtering .custom-control{
  display: inline-block;
}
div#modal_search_filter_div .home-search-range-box{
  height: 80px;
}
  div#modal-order-item-addons { margin-bottom: 20%;}
 .panel-body { margin-bottom: 5%;}
 .socialmediafeedscss {margin-bottom: 5% !important;}
}
#loadmenuvenuitems {margin-left: 57%;}
#modal_search_filter_div .select2-container--default.select2-container--focus .select2-selection--multiple, #div_advanced_menuitem .select2-container--default.select2-container--focus .select2-selection--multiple, #div_advanced_restaurant .select2-container--default.select2-container--focus .select2-selection--multiple {
	border-radius: 4px !important;
	box-shadow: inset 0 1px 1px #707070, 0 0 6px #02843d !important;
	color: #495057 !important;
	background-color: #fff !important;
	border-color: #80bdff !important;
	outline: 0 !important;
}
#modal_search_filter_div .select2-container--default .select2-selection--multiple{
  min-height: 100px;
  max-height: 105px;
  overflow-y: auto;
}
.restaurantmaindiv{
  background-color: #dddddd4a;
  border-radius: 5px;
  position: relative;
  left: 32px;
}
.sortbytextclass{
  float:right;
  display:inline-flex;

}
.item-review-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.selectfilter{
  -webkit-appearance: none;
   border:none;
  background-image: url('/images/down_arrow.png');
  background-position : right center;
  background-size: 21px 20px;
  background-repeat: no-repeat;
  padding-right: 2.5em;
}
.menu-item-image {
  padding: 0 !important;
  border-radius: 4px !important;
}
.menu-item-title {
  padding: 0px;
  margin: 0px;
-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
 width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.menu-item-title-container {  padding-top: 5px;}
/* FONT FACES */ /* FONT FACES */
@font-face {
  font-family: 'custom-icon';
  src:  url('fonts/custom-icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="pi-"], [class*=" pi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'custom-icon' !important;
  speak: never;
  font-style: normal;
  font-size: 15px;

  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-weight: 900;
}
.pi-bookmark:before { content: "\e903"; color: #4a4a4a;}
.pi-bookmark-o:before { content: "\e902"; color: #4a4a4a;}
.pi-svg-bookmark {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 29 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='14.4709' cy='15.4612' rx='14.1448' ry='14.6448' fill='white'/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M9.32812 7.47266H20.1488V24.2305L14.7385 18.2456L9.32812 24.2305V7.47266Z' fill='%2302843D'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='6.4153' y='5.5073' width='16.6464' height='22.5835' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='0.94747'/%3E%3CfeGaussianBlur stdDeviation='1.45641'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
}
.pi-svg-bookmark-o {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 29 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='14.4709' cy='15.4612' rx='14.1448' ry='14.6448' fill='white'/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M9.32812 7.47266H20.1488V24.2305L14.7385 18.2456L9.32812 24.2305V7.47266Z' fill='%23979797'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='6.4153' y='5.5073' width='16.6464' height='22.5835' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='0.94747'/%3E%3CfeGaussianBlur stdDeviation='1.45641'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
}
.pi-svg-30-bookmark {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 29 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='14.4709' cy='15.4612' rx='14.1448' ry='14.6448' fill='white'/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M9.32812 7.47266H20.1488V24.2305L14.7385 18.2456L9.32812 24.2305V7.47266Z' fill='%2302843D'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='6.4153' y='5.5073' width='16.6464' height='22.5835' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='0.94747'/%3E%3CfeGaussianBlur stdDeviation='1.45641'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
}
.pi-svg-30-bookmark-o {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 29 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='14.4709' cy='15.4612' rx='14.1448' ry='14.6448' fill='white'/%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M9.32812 7.47266H20.1488V24.2305L14.7385 18.2456L9.32812 24.2305V7.47266Z' fill='%23979797'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='6.4153' y='5.5073' width='16.6464' height='22.5835' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='0.94747'/%3E%3CfeGaussianBlur stdDeviation='1.45641'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
}
.pi-svg-zoom {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.1292' cy='15.4612' r='14.6448' fill='white'/%3E%3Cpath d='M15.0738 21.174C18.2601 21.174 20.843 18.5911 20.843 15.4049C20.843 12.2187 18.2601 9.63574 15.0738 9.63574C11.8876 9.63574 9.30469 12.2187 9.30469 15.4049C9.30469 18.5911 11.8876 21.174 15.0738 21.174Z' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22.2835 22.6184L19.1465 19.4814' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.0742 13.2441V17.571' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.9102 15.4072H17.237' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.pi-svg-30-zoom {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.1292' cy='15.4612' r='14.6448' fill='white'/%3E%3Cpath d='M15.0738 21.174C18.2601 21.174 20.843 18.5911 20.843 15.4049C20.843 12.2187 18.2601 9.63574 15.0738 9.63574C11.8876 9.63574 9.30469 12.2187 9.30469 15.4049C9.30469 18.5911 11.8876 21.174 15.0738 21.174Z' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22.2835 22.6184L19.1465 19.4814' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.0742 13.2441V17.571' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.9102 15.4072H17.237' stroke='%2302843D' stroke-width='1.44229' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.pi-svg-expander-close {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='17' viewBox='0 0 20 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='7' rx='3.5' fill='white'/%3E%3Ccircle cx='5.5' cy='3.5' r='1.5' fill='%23979797'/%3E%3Ccircle cx='10.5' cy='3.5' r='1.5' fill='%23979797'/%3E%3Ccircle cx='15.5' cy='3.5' r='1.5' fill='%23979797'/%3E%3C/svg%3E%0A");
}
.pi-svg-expander-open {
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='17' viewBox='0 0 20 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='7' rx='3.5' fill='white'/%3E%3Ccircle cx='5.5' cy='3.5' r='1.5' fill='%2302843D'/%3E%3Ccircle cx='10.5' cy='3.5' r='1.5' fill='%2302843D'/%3E%3Ccircle cx='15.5' cy='3.5' r='1.5' fill='%2302843D'/%3E%3C/svg%3E%0A");
}
.pi-bowl:before { content: "\e901";}
.pi-leaf:before { content: "\e900";}
.footerIcons{
  margin-bottom: 3px;
  background-color: #02843d;
  border-radius: 8px;
  padding-top: 10px;
  color: white;
  padding-bottom: 10px;
}
#collapsePaymentOptions .toggle-group{width:131% !important;}
.recentlocation{
  color:#4A4A4A ;
  padding: 6px 3px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25) ;
  text-align:left ;
}
.homelocation{
  color:#4A4A4A ;
  padding: 6px ;
  text-align:left ;
}
#head-location-input-search::placeholder,#homehead-location-input-search::placeholder, #head-mobilelocation-input-search::placeholder {
  font-size: 0.75rem;
}
#head-location-input-search::-webkit-search-cancel-button, #head-mobilelocation-input-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
}
#mobileinputSwitchSpan { padding: 0 3px;}
#head-mobilelocation-input-search {
  padding:3px !important;
  height:auto !important;
  border-radius: 2px !important;
}
.recentlocationheading{
  color:#4A4A4A;
  padding: 6px 3px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
  text-align: left;
  font-weight: bold;font-size: 14px;
}
.find{  margin-top:20%;  position: relative;}
#headingAll .can-toggle label .can-toggle__switch, #collapseSpecificCapability .can-toggle label .can-toggle__switch, #collapseFreeFoodNC .can-toggle label .can-toggle__switch{
	position: unset;
	margin-top: -2px;
	margin-left: -2px;
}
#messsage-login #error {word-wrap: break-word;}
/* remove form home page and added in style.css*/
#header , #header.header-scrolled{ border-bottom: none; }
  #header #head-logo img {
    padding: 0;
    width: 151px;
}
.head-location-src-box{ display: inline-flex;}
.search-container button {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}
.head-location-src-box .searchinput{ width:320px;}
.search-container button:hover { background: #ccc;}
.head-location-src-box input[type=text] {
    /* padding: 6px; */
    padding-right: 60px;
    margin-top: 5px;
    border: none;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    width: 369px;
    height: 35px;
    background: #FBFBFB;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-left: 80px;
}

 #header .container-fluid span a{font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin: 17px 0 0 0;
  position: relative;
}
#intro .intro-container{
  position: relative;
  height: 100%;
 /* background-image: linear-gradient(to top, rgba(255,255,255, -7.48), rgba(213,230,255, 0.63)), url(../images/home-banner-building.svg);
 */ 
  /* background: radial-gradient(52.38% 52.38% at 50% 50%, #014B23 0%, #002812 100%); */
  background-size: 100% 112%;
  background-repeat: no-repeat;

  }
  .head-inner-location-container{
    position: absolute;
    width: 100%;
    
    z-index: 1;
  }
  .head-location-container{ position: absolute; }
  #mobilelocationdiv{
    padding: 5px;
    border-radius: 8px;
    top: 5px;
    margin-left: 35px;
  }
.desktopsubheader{
  padding-top: 0%;
  z-index: 0;
 }
 .cuisoninput{
    background: #FFFFFF;
    border: 2px solid rgba(151, 151, 151, 0.3) !important;
    border-radius: 7px !important;
    width: 171px !important;
  }
 .homedesktopsubheader{padding-top: 2%;}
.topratingheading{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-top: 6%;
  color: #4A4A4A;
}
.howtoearnheading{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-top: 6%;
  color: #02843D;
}
.craousalitemImage{
  width:100%;
  height: 55vh !important;
}
.localinfufreind{
  background: #F8F8F8;
  border: 0.5px solid #E0E0E0;
  border-radius: 10px;
}
.localinfufreind img{
  width:20px !important;
  height:20px;
}
.addbtn-cart{
  font-weight: 500;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 5px 5px;
    border-radius: 4px;
    transition: .5s;
    margin: 8px 0;
    color: #fff;
    background: #02843d;
    border: 0;
    text-transform: capitalize;
    width: 100%;
    height: 35px;
}
.dietary_signup{
  background: rgba(31, 109, 66, 0.2);
  mix-blend-mode: normal;
  opacity: 0.98;
  width: 240px;
  height: 85px;
}
.dietary_login{
  background: rgba(31, 109, 66, 0.2);
  mix-blend-mode: normal;
  opacity: 0.98;
 height: 30px;
}
.dietary_login a{color: #404040;}
.darkgrey{color:#4A4A4A;}
.menuimageeclipse{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: auto;
}
.carausalmenuitem{
  position:relative;
  max-height: 472px;
  overflow-y: auto;
  overflow-x: hidden;
}
.carausallike-wishlist-container{
  float: right;
  right: 5%;
  position: absolute;
}
.topwishlist{top:2% ;}
.bottomwishlist{ bottom:30% ;}
.carousel-control-prev{
    left: 3% ;
    width: 30px;
}
.carousel-control-next{
    right: 2% ;
    width: 30px;
 }
 .topcarousel-control-next{
    right: 4% ;
    width: 0px;
    margin-bottom: 16% !important;
 }
 .topcarousel-control-prev{
    right: 3% ;
    width: 30px;
    margin-bottom: 16%;
 }
 .rate-heart-plate{
  margin-left: -6px;
 }
.menu-item-title-container .rate-heart-plate{
  margin-left: 0px !important;
}
.menu-item-title-container .jq-ry-container {
  padding: 0px;
}
.invitefriends{
  border: 1px solid #02843D;
  box-sizing: border-box;
 margin-left:12%;
 border-radius: 4px;
}
.restimageeclipse{
  width: 135px !important;
  height: 120px !important;
  border-radius: 8px 0px 0px 8px;
}
.mobilebybutton{ display: none;}
.desktopbybutton{  display: block;}
.menu-item-review{
  max-height: max-content;
  height: auto;
}
.ratebutton{
    bottom: 6px;
}

.ratingdiv.mt-md-3{  display: flex;}
@media screen and (max-width:480px) {
  .dietary_signup {height: 70px;}
   .item-image-container{float:none !important;height:auto !important;width:100% !important}
  /* .home-search-arrow-btn{width:96%;} */
  .menu-item-review{ height: auto;  }
  .ratingdiv.mt-md-3{display: flex !important;}
  .craousalitemImage{height: 220px !important}
  .menucard {width: 100% !important;}
  #search-menuItemsDiv .menucard { width: 100% !important;}
  .carausalmenuitem {
    max-height: 100% !important;
    overflow: hidden;
    /* overflow-y: auto;
    overflow-x: hidden; */
  }
  .venueslick > div { height: unset !important;}
  .count_Restautrent {left: 0 !important;}
  .cuisoninput{  width: 134px !important;}
  .home_order_btns span{font-size: 9px !important;}
   span.pl-2.pr-2.span-check.restauranttoprated{
    font-size: 11px !important;
  }
  #span-closest,#span-toprated,#span-pickup{font-size: 16px !important};
  .topratingheading{font-size: 14px !important;}
  .howtoearnheading{font-size: 14px !important; margin-top: -4% !important; position: absolute; right: -2% !important;}
 .header-bg #head-auto-complete-div{
    left: 0px!important;
    margin-top: -17px;
    width: 235px !important;
  }
	.header-bg .header-searchcustom #head-auto-complete-div {
		/* width: 50% !important; */
		left: 30% !important;
	}
  .topratingearnfoodheading{right: -3%; }
  .dietary_signup{
    background: none;
    width: 100%;
  }
  .dietary_signup a.home-set-prefs-login {
    font-size: 14px !important;
    line-height: 17px;
    font-weight: bold;
  }
  .homepage-prefs-div {
    position: absolute;
    border-left:none;
    bottom: 0%;
  }
  .homepage-prefs-div .diet-prefs-container {
    border-right: 1px solid #EFEFEF;
    padding-right: 13px !important;
  }
  .carousel-buy-btn {margin-top: 18% !important;}
  .results-col .diet-flavor-text {
    font-size: 14px !important;
    color: #000000;
  }
  #results .results-col {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  #recommended_friends .food-flavor.homepage-prefs-div {
    bottom: 0%;
  }
  #search-menuItemsDiv .food-flavor.homepage-prefs-div {
    bottom: 0% !important;
  }
  #recommended-by-locals .food-flavor.homepage-prefs-div {
    bottom: 0% !important;
  }
  .mobilebybutton{display: block !important; }
  /* .desktopbybutton{  display: none !important; } */
  #recommended_friends .home-time-dilvery-div {
    margin-left: 2px !important;
  }
  .header-bg #head-search-filter{
    right: 0;
    margin-right: -9px !important;
    margin-top: -27px !important;
  }
  #recommended_friends .yelp-rating-stars, #home-restlocations-miles .yelp-rating-stars, #dietpref-venues .yelp-rating-stars {
    bottom: 12% !important;
    top: inherit !important;
  }
  .tophead-search-filter { border-left: none; }
  /* .mainhome .slick-list .slick-track {top: 26px;} */
  .friends-freefood-scanner .text-loto-italic {font-size: 9px !important; }
  .friends-freefood-scanner .friend-percent-font {
   font-size: 50px !important;
   line-height: 40px !important;
  }
  .friends-freefood-scanner .friends-platerate-logo {
   width: 40%;
   padding-top:15px;
   padding-bottom:15px;
  }
  #friends-qrcode span{
    position: absolute;
    bottom: 8%;
    left: 17%;
    color: #fff;
    font-size: 12px;
  }
  #friends-qrcode{
    position: absolute;
    left: 28%;
    top: 24%;
  }
  #friends-qrcode canvas{
    padding: 5px;
    width: 80px;
    height: 100px;
    border-bottom: 20px solid #000;
    border-top: 3px solid #000;
    border-right: 3px solid #000;
    border-left: 3px solid #000;
  }
  #intro a.home-address-text, #intro a.home-filter-icon{ height: 42px;}
}
#recommended_friends .recommend-see-menu-btn, #recommended-by-locals .recommend-see-menu-btn, #dietpref-venues .recommend-see-menu-btn {
  position: absolute;
  bottom: 0%;
}
#recommended_friends .home-restlocations-miles, #home-restlocations-miles .home-restlocations-miles, #dietpref-venues .home-restlocations-miles {
  position: absolute;
  bottom: 18%;
}
#recommended_friends .yelp-rating-stars, #home-restlocations-miles .yelp-rating-stars, #dietpref-venues .yelp-rating-stars {
  position: absolute !important;
  bottom: 13% !important;
  top: inherit !important;
}
#recommended_friends .delivery-time-icons, #home-restlocations-miles .delivery-time-icons, #dietpref-venues .delivery-time-icons {
  position: absolute !important;
  bottom: 27% !important;
}
.header-bg #head-search-filter{
  right:0;
  margin-right: 4px;
}
#recommended_friends .food-flavor.homepage-prefs-div, #recommended-by-locals .food-flavor.homepage-prefs-div {
  bottom: 0%;
}
.topratingearnfoodheading{
  text-align: right;
  text-decoration-line:underline;
}
.header-bg #head-auto-complete-div{

	left: 53%;

	margin-top: -17px;
}
.header-bg .head-location-src-box input[type=text] {
	width:450px;
  /* padding-left: 11%; */
}
/* .header-bg #mobilelocationdiv {
  left: 0;
} */
.header-bg #mobilelocationdiv #inputSwitch{
	margin-top: 0;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 999;
}
.header-bg #mobilelocationdiv #inputSwitch #head-location-input-search {
  position: absolute;
  top: 0;
  bottom: 0;
}
.header-bg #mobilelocationdiv #inputSwitch .fa-map-marker.home-map-marker-cust{
	margin-left: -14px;
}
.rateByInfluencer, .rateByFriends, .rateByLocals{
  display: inline-flex;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  width: 50px;
  height: 50px;
}
.foodlabel{
  float: left;
  margin-top: 2px;
}
#delivery-address_input::-webkit-search-cancel-button {
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;

}
#delivery-address_input {display: block !important;}
.aspect-ratio-box {
  padding-top: 2/3 * 100%;
  position: relative;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.div-orientation {
    padding: 1% 0 0 0;
    margin: 5% 0 0 0;
}
.imagefriend{
  width: 70px;
  height: 70px;
  background: #C4C4C4;
  border-radius: 50%;
}
.imagefriendlist{
  height: 109px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(200, 226, 212, 0.7);
  border-radius: 10px;
}
@media screen and (min-width: 830px) {
  .mainfriendpopup{
    width: 832px;
    max-width: 832px !important;
  }
  .video-number{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
  }
}
.sharedbutton{
  width: 106px;
  height: 42px;
  background: #C8E2D4;
  border-radius: 10px;
}
.headingmyplaterate{
  color: #4A4A4A;
   margin-left: 37%;
}
.firstlastname{ margin-top: 36px !important;}
.rateearndiv{
  background-image: url('/images/rateearn.png') !important;
  background-repeat: no-repeat;
  background-size: 1139px 220px;
  height: 220px;
}
#removeWords{height: 43px !important;}
.rest-like-wishlist-container{
  top:0px !important;
  right: 15px;
}
input#menu-search{ padding-right: 26%;}
.rate-earn-rewarded-text{
  font-size: 60px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  line-height: 58px;
  margin-bottom: 30px;
  /* text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 13px; */
}
.highly-rated-dish-text{
  font-size: 60px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  color: rgba(255,255,255,0.8);
  margin-bottom: 15px;
  color: #fff;
  
  text-decoration-thickness: 2px;
  text-underline-offset: 13px;
}
.home-earn-percent-img{
  z-index: 1;
  margin-top: 2%;
  margin-left: 29%;
  width: 38%;
}
.homepage-earn-percent-img{
  position: absolute;
  z-index: 1;
  float: right;
  top: -7rem;
  right: 10%;
}
.home-preferred-by-rest{
  position: absolute;
  font-size: 20px;
  margin-top: 0%;
  -webkit-text-decoration-line: underline;
  -moz-text-decoration-line: underline;
  text-decoration-line: underline;
  text-align: center;
  border-bottom:  1px solid #D3D3D3;
  width: 90%;
  margin-left: 10%;
  line-height: 3;
}
#preferred-by-rest .modal-content {
  height: 495px;
  max-width: 395px;
}
#preferred-by-rest .right-info-preferred {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #377D22;
}
#preferred-by-rest .right-info-preferred-small {
  font-family: Lato;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #4A4A4A;
}
#set-dietary-preferences .modal-dialog {
  width: 341px;
  height: 158px;
}
#set-dietary-preferences .custom-control-label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #090808;
}
@media only screen and (min-device-width: 350px) and (max-device-width: 650px) {
  #preferred-by-rest .modal-body {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  #advn-search-form-container .filter-menustarrating svg {
    margin-left: 3px !important;
  }
  #advn-search-form-container .black-4a {
    font-size: 12px;
    font-family: Lato;
    font-weight: 500;
    font-style: normal;
  }
  #advn-search-form-container .advn-search-sortby-box .custom-control-label {
    font-size: 12px;
    font-family: Lato;
    font-weight: 500;
    font-style: normal;
    margin-left: 0px;
  }
  #advn-search-form-container .advn-search-sortby-box .form-check-inline {
    margin-right: 0px;
    margin-bottom: 1rem !important;
  }
  #advn-search-form-container .advn-search-sortby-box .custom-control-label::after {
    top: 0.10rem !important;
  }
  #advn-search-form-container .advn-search-sortby-box .custom-control-label::before {
    top: 0.06rem !important;
  }
  #menuItemCollapse .edit_text {font-size: 14px;}
  #menuItemCollapse .edit_text span.label-text label{
    font-size: 12px;
  }
  .adv-srch-radius { padding: 11px 10px 0 0; }
  #advn-search-user-data {text-align: right !important;}
  #menuItemCollapse .advn-price-slider{
    margin-left: 15px !important;
  }
  #menuItemCollapse #forQuantity .forQuantityPortions{
    width: 100%;
    max-width: 100%;
    bottom:0px !important;
  }
  #collapseRestaurant .flavors-slider-container .flex-container{
    position: absolute;
    top: 9px;
    right: 0px;
  }
  #collapseRestaurant .preference-items input[type="checkbox"]+span.icon-checkbox:before, .natext input[type="checkbox"]+span.icon-checkbox:before, #menuItemCollapse .preference-items input[type="checkbox"]+span.icon-checkbox:before, .natext input[type="checkbox"]+span.icon-checkbox:before{
    font-size: 1rem;
  }
  #collapseRestaurant span.icon-checkbox.check-box-left{
    margin-top: -2px;
  }
  .advanance-search-exp-filter-slider .alignCenter {
    text-align: left !important;
  }
  #collapseRestaurant .custom-control-label {
    font-size: 10px;
  }
  #collapseRestaurant, #seating_capacity .seating-capacity-label, #collapseRestaurant, #seating_capacity .seating-capacity-input{
    padding-left: 0px;
    padding-right: 0px;
  }
  #collapseRestaurant, #seating_capacity .seating-capacity-label label{
    font-size: 11px !important;
  }
  #collapseRestaurant .accord-grey .panel-title a {
    color: #02843D !important;
    font-weight: 500;
  }
  #collapseRestaurant select#hasBathroom {
    width: 100% !important;
  }
  #collapseRestaurant .slider-bathroom-cleanliness{
    padding-left: 0px;
    padding-right: 0px;
  }
  #collapseRestaurant .accord-grey {
    padding: 3% 1%;
  }
  #collapseRestaurant .custom-control-label::before {
    width: 14px;
    height: 14px;
    top: 0rem;
  }
  #collapseRestaurant .custom-control-label::after {
    top: 0rem;
  }
  #collapseRestaurant h4.edit_text, #collapseRestaurant h4.alignCenter  {
    text-align: left;
  }
  #form-search-advanced .advn-mobo-banner {
    height: 100%;
    background-image: url('../images/advanced-search-mobo.png') ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-height: 350px;
    height: 330px;
  }
  #form-search-advanced .mobo-hideimage { display: none; }
  #form-search-advanced h2.text_edit {font-size: 22px;}
  #paymentAccordion input[type="checkbox"]+.label-text:before {
    margin-right: 0px;
  }
  #byobCode .edit_text {font-size: 14px;}
  #byobCode #byob .btn-group .btn-primary {
    padding: 4px 5px !important;
  }
 /* .cuisine-container #earntext.homepage-item */
}
#collapseProfitability .advanance-search-exp-filter-slider label {
  float: left;
  margin-top: 0px;
}
#collapseProfitability .advanance-search-exp-filter-slider .portion-size {
  display: none;
}
.home-cuisine-box .select2-container--default .select2-selection--multiple {
  border-radius: 8px;
  height: 27px;
  font-size: 14px;
}
#preferred-by-rest .modal-header {
  border-bottom: none;
  background-color: #D1EBDD;
}
#preferred-by-rest .text-header-bt{
  text-align: center;
  color: #4A4A4A;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
}
#preferred-by-rest .text-header-top {
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  line-height: 33px;
  color: #377D22;
}
#preferred-by-rest .flipformbox {
  height: auto;
  background: none;
  box-shadow: none;
}
#intro .homepage-container{
  height: 100%;
 background-image: url('../images/background-home.jpeg') ; 
margin-top: -1% ;
  background-repeat: no-repeat;
  background-size:  100%;
}
#hometopheader{ height: 90vh;width: 100%;}
#homepage-main-content{
  /* overflow-x: hidden; */
  /* background: #F8F8F8; */
  margin: auto;
  margin-top: -24px;
}
.headhome-inner-location-container{
  width: 46.5%;
}

.headhome-inner-search-container{
  width: 46.5%;
}

.headhome-inner-inputlocation-container{
  z-index: 1;
  height: 79px;
  /*width: 48%;*/
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none;
}

.homeheadsearchhome {
  float: right;
  position: relative;
  padding-top: 5px;
  width: 44px;
  z-index: 2;
  height: 45px;
  margin-right: -1px !important;
}
#intro .searchinputcenter {
    font-size: 15px;
    border: 1px solid #979797 ;
    font-family: FontAwesome,Lato,sans-serif;
    height: 40px;
    border: 0!important;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    border-right-color: #fff ;
    border-bottom-right-radius: 0px;
    border-right-style: hidden;
    padding: 0 0% 0 0;
    top: 3%;
    border-radius: 18px!important;
}
#intro .searchinputcenterlocation {
    font-size: 15px;
    border: 1px solid #979797 ;
    font-family: FontAwesome,Lato,sans-serif;
    height: 35px;
    border: 0 !important;
    border-bottom-left-radius: 0px;
    border-left-color: #fff ;
    border-top-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-style: hidden;
    padding: 0 0% 0 0;
    top: 3%;
}
.headhomesearchhome{
  float: right;
  position: relative;
  padding-top: 5px;
  z-index: 2;
  margin: -42px 10px 0 -16px !important;
  height: 32px;
  margin-right: 28px !important;
  color: #4A4A4A;
}
.mobile-width {width: 100px;}
.mainhome {
  font-family:Arial;
  width:200px;
  display:block;
  margin:0 auto;
}
.cusionlabel{
  width: 68%;
  color:#494949;
  min-height: 35px;
}
.home-filters-lables{
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.0015em;
  line-height: 19px;
}
.header-flavor-percent {margin-left: -17px;}
button#menusplash-next {
    color: green;
    background: transparent;
    background-color: transparent !important;
    font-size: 4em !important;
    font-weight: 300 !important;
    top:30%;
}
button#menusplash-prev{
  height: 42px;
  color: green;
  background: transparent;
  font-size: 4em;
  top:30%;
}
button#splash-next {
    color: green !important;
    background: transparent;
    background-color: transparent !important;
    font-size: 4em !important;
    font-weight: 300 !important;
    top:30%;
    height: 42px;
}
button#splash-prev{
  height: 42px;
  color: green !important;
  background: transparent;
  font-size: 4em;
  top:30%;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 450px) {
  button#menusplash-next {
    color: green !important;
    top:13% !important;
    font-size: 2em !important;
    display: none !important;
  }
  button#menusplash-prev{
    color: green !important;
    top:13% !important;
    font-size: 2em !important;
    display: none !important;
  }
  #slectboxcurrentlocation {
    border: 0 !important;
  }
  #header-search-radius input[type=range] {
    width: 60%;
  }
  .header-bg #mobilelocationdiv {
    left: 25%;
    top: 10px;
    border: none; 
    flex-wrap: nowrap;
  }
  #head_search_box_expand{
    display: none;
  }
  #autocomplete-results-basic{
    width: 250px !important;
    left: -5px !important;
    padding: 0 !important;
  }
  .home-map-marker-cust {
    margin-left: 5px !important;
  }
  .localinfufreind{font-size: 12px; }
  .maxflvrmatch .slider-infl {width: 265px !important;}
  .header-flavor-percent {margin-left: 0px;}
  #home-advanced-search {font-size: 14px;}
  .login-right-errorbox {width: 290px !important;}
  #results .results-col .yelp-rating-stars{top: 81%;position: absolute;}
  #results .results-col .home-avg-ratings{margin-top: 5%;}
  #results .results-col span.green.home-rest-price{float: right;}
  .search-resultsitems .home-time-dilvery-div{margin-left: 7px;}
  #search-menuItemsDiv .home-time-dilvery-div{margin-left: 0px;}
  #search-menuItemsDiv .descriptiontext{ padding-top: 3px !important;}
  section.container.search-resultsitems .home-time-dilvery-div{
    margin-left: 3px;
  }
  #search-menuItemsDiv span.flavor-match-circle{right: 26px !important; }
  .friends-copy-url #copy_connect_referrer_id, #edit_connect_referrer_id {
    background: #fff;
    padding: 2px 4px !important;
  }
  .friends-copy-url .text-for-link {font-size: 10px !important;}
}
  #search-menuItemsDiv .home-time-dilvery-div{margin-left: 0px;}
#preferred-by-rest .flipformbox {
  height: auto;
  background: none;
  box-shadow: none;
}
button#splash-next {
    color: green !important;
    background: transparent;
    background-color: transparent !important;
    font-size: 1em !important;
    font-weight: 300 !important;
    top:30%;
    height: 42px;
}
button#splash-prev{
  height: 42px;
  color: green !important;
  background: transparent;
  font-size: 1em;
  top:30%;
}
.cuisine-container button#splash-next {font-size: 3em !important;}
.cuisine-container button#splash-prev{font-size: 3em !important;}
.free-food-card--credit {
  font-size: 1.55rem;
  padding: 0 10px;
}
.menu-card-actions > span {font-size: 0.75rem;}
.homeeattext{
  font-size: 12px;
  color:#000;
  text-align: center;
}
.mostprofitable{text-align: center;}
.mostprofitableheading{
  color: #4A4A4A;
  font-weight: bold;
  font-size: 28.875px;
}
.mostprofitablelabel{
  color: #4A4A4A;
  font-weight: normal;
  font-size: 22px;
}
#tipContainer .btn-outline-success:not(:disabled):not(.disabled).active {
  background-color: #02843d;
  color: #fff;
}
#tipContainer .btn-outline-success {
  background-color: #fff;
  color: #969696;
}
#tipContainer .btn-outline-success:hover{
  background-color: #02843d;
  color: #fff;
}
.login-right-errorbox #alert p#error{
  font-size: 12px;
  margin-bottom: 2px;

}
.login-right-errorbox {width: 400px;}
.login-right-errorbox .alert{padding: 6px 10px;}
.social-sharing-icons .card-body{
  background: none;
  border: none;
  padding: 0px;
}
.share-slider-box{
  background: #F4F4F4;
  box-shadow: inset 0px 0.885246px 3.54098px rgba(0, 0, 0, 0.1);
  border-radius: 3.54098px;
  width:100%;
}
.rating-social-icons a img{max-height: 40px !important;}
#modal-invite-friends{
	width: 478px;
	height: 472px;
}
.swiper-button-prev {color: darkgreen !important}
.swiper-button-prev::after {display: none;}
.swiper-button-prev::before {display: none;}
.swiper-button-next {color: darkgreen !important}
.swiper-button-next::after { display: none;}
.swiper-button-next::before {display: none;}
.resulttext{
  width: 100%;
   text-align: center;
   border-bottom: 1px solid #000;
   line-height: 0.1em;
   /* margin: 10px 0 20px; */
}
.resulttext span {
    background:#fff;
    padding:0 4px;
}
.m-card-diet-pref-label{
	  font-size: 10.97px !important;
    font-weight: 700;
}
.neardeskclass{ display: block;}
.neariconmobile{display: none;}
.findtextdesk{display: block;}
.findicondesk{display: block;}
.findmobile{display: none;}
.locationdesk{display: block;}
/* .d-pref-btns-row {min-height: 31px } */
.addtocartbtn_h{  position:absolute; bottom:3%;}
.delete-upload-icons{position: absolute; bottom: -16%; right: 31%;}
.menu-section .bh-list-menu-items {align-items: flex-end !important;}
@media (max-width: 767px) {
  .neardeskclass{ display: none;}
  .neariconmobile{display: block;}
  .findtextdesk{display: none;}
  .findicondesk{display: none;}
  .findmobile{display: block;}
  .locationdesk{display: none;}
  
  .awardimg{width: 183px !important; height: 160px !important;}
  .menu-card-actions > span {font-size: 0.5rem !important; }
  #modal-invite-friends{width: auto !important;}
  .resulttext span {
    font-size:14px;
    background:#fff;
    padding:unset;
    line-height: 2.1em;
  }
  .restaurantView{ flex-direction: column; }
	.home-earn-percent-img{margin-top: 10%;	}
  .m-card-diet-pref-label{
		font-weight: 500;
		line-height: 150%;
		font-size: 0.5rem!important;
	}
	.responsive-font-9{font-size: 9px !important;	}
	.meet-btn span.mobile-desktop-text{
		font-size: 9px !important;
		line-height: 140%;
	}
	.meet-btn.meet-btn.btn-block.cus-btn-fluid.meetbtn{
		font-size: 9px !important;
		line-height: 140%;
	}
	.meet-btn.meet-btn.btn-block.cus-btn-fluid.meetbtn span{
		font-size: 9px !important;
		line-height: 140%;
	}
	.meet-btn span.favorite-item-name{
		font-size: 9px !important;
		line-height: 140%;
		font-weight: 700!important;
	}
  .meet-btnhome{ min-width: 129px !important;}
	.meet-btn{
		height: 27px;
		min-width :54px !important;
	}
	#earntext #cusionslider .menuTypePreferences .cusionlabel{
		font-size : 8px !important;
	}
	/* #earntext #cusionslider .icon-width{width: 79px !important;} */
	.home-flip-mission-back .flip-heading-txt .green {
		font-size: 23px;
		font-weight: 700;
	}
	.home-flip-mission-back .flip-heading-txt > :nth-child(2) {
		font-size: 17.67px;
	}
	.home-flip-mission-back .flip-heading-txt > :nth-child(3) {
		font-size: 17.67px;
	}
	.home-flip-mission-back .flip-sub-heading-txt > :first-child {
		font-size: 19.53px;
	}
	.home-flip-mission-back .flip-sub-heading-txt > :last-child {
		font-size: 12px !important;
	}
	.home-flip-mission-back .flip-sub-heading-txt > :nth-child(2) {
		font-size: 12px !important;
	}
	.home-flip-mission-back .flip-sub-heading-txt > :nth-child(3) {
		font-size: 12px!important;
	}
	.d-pref-btns-row {min-height: 36px !important;}
	.m-card-diet-pref-txt {
		font-size: 12px !important;
    font-weight: 500 !important;
	}
	#forQuantity .forQuantityPortions {bottom:12px;}
/*	.search-resultsitems .marginTopMbl {	right: 48px;}*/
	#search_results_div .marginTopMbl {
		right: 48px;
		top: 19px !important;
	}
	.sweet-alert p{
		color: #4a4a4a!important;
		font-weight: 500;
	}
  .header-locationcustom #inputSwitch {
		padding: 5px 0;
		margin-top: 5px;
		font-family: "Open Sans";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 19px;
		height: 35px;
		background: rgb(251, 251, 251);
		border: 0.5px solid rgba(0, 0, 0, 0.25);
		box-sizing: border-box;
		border-radius: 5px;
    left: -30px;
	}
	.header-locationcustom .inputSwitch {
		min-width: 195px !important;
		top: -11px !important;
    margin-left: 20px;
	}
	.header-locationcustom #inputSwitchSpan {font-size: 12px !important;}
	.header-searchcustom #search-filter {	display:none;	}
	.header-searchcustom {display:none;	}
	.header-searchcustom #search {
		/* width: 195px !important; */
		padding-left: 8px !important;
		margin-left: 36px !important;
	}
  #head-location-input-search{
    padding-left: 10px !important;
    margin-top: -1.5px !important;
  }
  .addtocartbtn_h{ bottom:3%;}
}
.elevation-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.meet-btn.btn-block.cus-btn-green{color: #fff !important;}
.activevideo{
  height: 28px;
  font-weight: bold;
}
.inactivevideo{
  height: 22px;
  margin-top: 7px;
  font-weight: bold;
  text-decoration-line: underline;
}
.home-info-flip-cards .card-flip > div {
  backface-visibility: hidden;
  transition: transform 300ms;
  transition-timing-function: linear;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
}
.home-info-flip-cards .card-front {transform: rotateY(0deg);}
.home-info-flip-cards .card-back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
}
.home-info-flip-cards .card-flip:hover .card-front {
  transform: rotateY(-180deg);
}
.home-info-flip-cards .card-flip:hover .card-back {
  transform: rotateY(0deg);
}
.home-flip-mission-back .flip-sub-heading-txt {
	font-weight: 600;
}
.home-flip-mission-back .flip-sub-heading-txt > :first-child {
	font-size: 18px;
}
.home-flip-mission-back .flip-sub-heading-txt > :last-child {
	font-size: 12px;
	font-style: italic;
}
.home-flip-mission-back .flip-sub-heading-txt > :nth-child(3) {
	font-size: 12px!important;
	font-style: italic;
}
.m-card-diet-pref-txt {
	font-weight:700;
	font-size: 13px;
}
#friendsListTable > thead > tr {background-color: white;}
#friendsListTable > tbody > tr { background-color: #E6F3EC;}
.order-state-bar {
  max-width: 840px;
  margin: 0 auto;
}
.carousel-control-next, .carousel-control-prev{
  opacity: 2.5 !important;
}
.item-details{border-right: 1px solid #EFEFEF;}
/*adv search*/
#searchtype_foradvanced {
	height: 40px;
	font-size: 16px;
	border: none;
	font-weight: bold;
}
#form-search-advanced .search-input-height {
	height: 40px !important;
	border: none !important;
}
.black-4a{color: #4A4A4A;}
.home-cust-btn-height {height: 48px !important;}
.font-w-500 {font-weight: 500;}
.font-w-600 {	font-weight: 600;}
.home-cust-btn-height .custom-dropdown option {font-size: 16px !important;}
.popover {	background-color: #e5e5e5 !important;}
.no-outline {outline:0px !important;}
/** ************************** **/
/** Delivery and Pickup Status **/
/** ************************** **/
.m-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  margin-top: 1rem;
}
.m-wrapper::after {
  content: "";
  display: block;
  padding-bottom: calc(50% + 76px);
}
.halfling {
  position: absolute;
  height: 76px;
  top: 50%;
  left: -10px;
  right: -10px;
  background: white;
}
.arc {
  display: flex;
  position: absolute;
  top: 76px;
  border-radius: 50%;
  width: calc(100% - 152px);
  background-clip: padding-box;
  background: white;
  --arc-bg: linear-gradient(1deg,#3cb371 23%,#e1e1e1 50%);
}
.arc::before {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -1;
  margin: -10px;
  border-radius: inherit;
  background: var(--arc-bg);
}
.arc::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.timer {
  position: absolute;
  top: 0;
  background: white;
  border-radius: 50%;
  z-index: 999;
  border: 5px solid #3CB371;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer.step {
  left: -13px;
  width: 26px;
  height: 26px;
  border: 2px solid #3cb371;
}
.timer.step::after {
  content: '';
  padding: 6px;
  border: 2px solid #3cb371;
  border-radius: 50%;
}
.timer.step.on::after {
  content: '';
  padding: 6px;
  background-color: #3cb371;
  border-radius: 50%;
}
.timer.clock {
  left: -76px;
  width: 152px;
  height: 152px;
}
.timer-rotor {
  position: absolute;
  transform: rotate(2deg);
  left: 0px;
  z-index: 9;
  width: 100%;
}
.timer-rotor.clock {
  --degree: rotate(2deg);
  height: 152px;
  top: calc(50% - 76px);
  display: none;
  transform: var(--degree);
}
.timer-rotor.step {
  height: 26px;
  top: calc(50% - 20px);
}
.timer-text {
  font-size: 12px;
  color: #959595;
}
.timer-terminals {
  position: absolute;
  width: 26px;
  height: 26px;
  background: #fff;
  top: -3px;
  border-radius: 50%;
  border: 2px solid #3CB371;
  padding: 3px;
}
.timer-terminals.left { left: -8px;}
.timer-terminals.right {  right: -8px;}
.timer-terminals::before {
  content: '';
  display: block;
  background-color: white;
  border: 2px solid #3CB371;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.timer-terminals.on::before { background-color: #3CB371;}
.timer-label-wrapper {
  display: flex;
  z-index: 99;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  color: #959595;
}
.content-text-wrap {
  position:relative;
  left: -100px;
  top: -12px;
  display: inline-block;
}
.content-text {
  --degree: rotate(-2deg);
  padding: 26px;
  text-align: center;
  transform: var(--degree);
}
.content-text.timer-step {
  transform: rotate(-90deg);
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.content-text.clock::after {
  content: '';
  border-right: 2px solid #3CB371;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0
}
.topreview{  margin-top:-2px;}
.home-item-price{  margin-top: 6px;}
.sweet-alert p{
	color: #4a4a4a;
	font-weight: 500;
}
.sweet-alert input{
	border-color: #C2C2C2!important;
	font-weight: 500 !important;
}
.sweet-alert input:focus{	border-color: #2F80ED!important;}
.sweet-alert input::placeholder{color: #C2C2C2!important;}
.sweet-alert .cancel{
	color: #F15C4F;
	background-color: #ffffff !important;
}
.sweet-alert .confirm {
	color: #ffffff;
	background-color: #02843d !important;
}
.restaurant-menu-infor .fix_size_more {
	font-style: normal !important;
	font-weight: normal !important;
}
.restaurant-menu-infor .sticky {
	 box-shadow: 0 1px 5px 0px green !important;
	 border-bottom: 1px solid green !important;
}
.restaurant-menu-infor .mobile-menu #menu-search {
	 min-width: 150px !important;
}
.restaurant-menu-infor .mobile-menu .sub-menu-row {
	border-bottom: 1px solid green !important;
}
.advanced-searchtypebox span.select2.select2-container{
		margin-top: 6px;
    margin-left: 10px
}
.advanced-searchtypebox span.select2-selection {
		border: none !important;
		background-color: #ffffff !important;
}
.scrolling-wrapper { width: 100%;}
.scrolling-wrapper, .scrolling-wrapper-flexbox {
  margin-bottom: 20px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}
.menucard {
  width: 260px;
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}
.expert-review-quantity {
  position: absolute;
  margin-left: 49%;
}
.quantity-one-serving {
  position: absolute;
  margin-left: 28%;
}

.home-time-dilvery-div{margin-left: 2px;}
.title-item-rating{
  flex: 1;
  padding: 0;
  margin: 0;
  -webkit-line-clamp: 2;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.skip-menu-item:hover, #btn-skip-earn-free-food:hover, .not-want-free-food:hover{
  border: none !important;
}
#modal-earn-free-skiping .modal-dialog.modal-dialog-centered.rate-skip-desktop{
  max-width: 660px;
}
#modal-earn-free-skiping .modal-dialog.modal-dialog-centered.rate-skip-desktop .modal-content{
  border-radius: 0px;
  height: 530px;
}
#modal-earn-free-skiping .modal-dialog.modal-dialog-centered .modal-content{
  border-radius: 20px 20px 0px 0px;
  height: 530px;
}
#modal-earn-free-skiping .btn-link {
  border: 1px solid #F15C4F;
}
.eligible-credits-label img {width: 90px;}
#results .carousel-item-Name {padding-left: 2px;}
#results .home-carausal-items p {padding-left: 2px;}
  /* Styling for Flavor N/A Preference */
  @media (min-width:768px) and (max-width:1023px){
    div.flex-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 55px !important;
    }
    .headsearch{

      margin-right: 26px !important;

      margin-right: 25px !important;

    }
  }
  @media (min-width:1024px) and (max-width:1199px){
    div.flex-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 78px !important;
    }
    .container{max-width: 1150px;}
  }
  @media (min-width:1200px) and (max-width:1366px){
    div.flex-container {
      display: flex;
      justify-content: flex-end;
      margin-left: -20px !important
    }

    .header-bg #mobilelocationdiv #inputSwitch{
      width: 150px;
    }

  }
  @media (min-width:1367px){
    div.flex-container {
      display: flex;
      justify-content: flex-end;
      margin-left: -20px !important
    }
  }
  .homepage-label {
    padding: 4px 20px 7px 8px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0px 4px 10px rgba(204, 204, 204, 0.25);
    cursor: pointer;
  }
 .item-check {
    margin-right: 5px;
    padding: 2.5px;
    border-radius: 4px;
  }
 .img-check { margin: 0 5px;}
  @media (min-width:414px){
    .span-check {padding-right: 0 !important;}
  }
  @media (min-width:374px) and (max-width: 413px){
    .homepage-label {padding: 5px 6px;}
    .span-check {padding-left: 0 !important;}
  }
  .boxElipsis p{
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin-bottom: -3px;
    word-break: break-word;
  }
  .orderaheadcss{display: none !important;}
  .friends-copy-url{
      background-image: url('../images/connect-invite-bg.png') ;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .friends-copy-url #copy_connect_referrer_id, #edit_connect_referrer_id{
      background: #FFFFFF;
  }
 @media all and (max-width: 360px) {
  #modal-order-item-addons .modal-dialog .modal-content .modal-footer .cus-btn {
    width: 140px;
  }
  .share-order-review-via-friend, .copy-link-to-clipboard, .share-via-social {
    margin-left: 5px !important;
  }
  .vSmallScreenImageSize{
    width: 87% !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    margin-left: 20px !important;
  }
 .headsearch {
    float: right;
    position: relative;
    padding-top: 6px;
    z-index: 2;
    margin: -36px 6px 0 1px !important;
    height: 32px;
    margin-right: 35px !important;
    color: #4a4a4a;
  }
  #header .container-fluid span a {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin: 17px 0 0 0;
    position: relative;
    /* margin-right: 0px !important; */
    margin-top: -12px;
  }
  .header-searchcustom #search {width: 200px !important; }
  .header-searchinputspan button#head-search-button { margin-right: 7% !important; margin-top: -36px !important;}
  .header-searchcustom #search { padding-right: 45px !important;}
  .header-bg #head-search-filter{margin-left: 5px !important;margin-right: 5px !important;}
  .earn_small_screen {
    font-size: 16px !important;
    color: white;
    font-weight: bold;
    white-space: nowrap;
  }
  .discount_highlight{
    font-size: 0.5em !important;
    padding: 5px 0.75em 5px 0.25em;
  }
  .aspect-ratio-box { padding-top: 84.666667% !important;}
  .small_screen_width {width: 90px;}
  .login-right-errorbox {width: 200px;}
  }
  @media all and (max-width: 308px) {
    #header #head-logo img {
      max-width: 25px !important;
      max-height: 25px !important;
      margin-top: 10px !important;
    }
    .head_location_box_expand_input{
      margin-top: -13px !important;
      width: 35px !important;
      margin-left: -12px !important;
    }
    .head_location_box_expand_img {
      margin-left: 14px;
      position: absolute;
      bottom: 9px;
      left: -15px;
  }
  .sidenav-icon {
        font-size: 22px;
        cursor: pointer;
        float: right;
        color: #4a4a4a;
        margin-top: 10px;
        margin-right: -9px;
  }
  .login-right-errorbox { width: 100px !important; }
  #head-form-search-basic #search {  margin-left: -8% !important;}
  }
  .preference-items input[type="checkbox"]:checked+i.fa.fa-check.item-check{
    background-color: rgb(2, 132, 61) !important;
    color: rgb(255, 255, 255) !important;
  }
.preference-items .item-check{margin-left: 5px;}
@media only screen and (min-width: 350px) and (max-width: 973px) {
  .discount_highlight {
    font-size: 10px !important;
    padding: 5px 0.5em 5px 0.25em;
    right: 1px;
  }
}
@media only screen and (min-width: 412px) and (max-width: 480px) {
	/* do something in this width range. */
  .basicsearch-sidebar {
    position: relative !important;
    top: -54px !important;
  }
  .addcartbtn {
    width: 369px !important;
  }
  strong.black.col-md-9.col-12.font-subheader-m {
    margin-top: 0;    
  }
}
@media only screen and (max-width: 411px) {
  .basicsearch-sidebar {
    position: relative !important;
    top: -54px !important;
  }
  strong.black.col-md-9.col-12.font-subheader-m {
    margin-top: -40px;    
  }
}
@media (min-width:380px) and (max-width:700px){
  .basicsearch-sidebar {
    position: relative !important;
    top: -54px !important;
  }
 
  div#restaurant-header{
		margin-bottom: 1rem !important;
	}
  .d-flex.justify-content-center.mt-5.mb-3 {
    margin-top: 1rem !important;
  }
}
@media (min-width: 300px) and (max-width:344px){
  .basicsearch-sidebar {
    position: relative !important;
    top: -54px !important;
  }
  .ratingdiv {  margin-top: 10px;}
  .black { color: #4a4a4a;}
  .recommendedheadingbox .earn-invite-box { max-width: 66% !important;
  }
  .homepage-label {padding: 5px 0px !important;}
  #intro a.home-address-text {width: 60px !important;}
  /* navbar responsive mobile */
  .header-bg .header-searchcustom #head-auto-complete-div {
    left: 66px !important;
  }
  .header-searchcustom #search {
    width: calc(100vw - 160px) !important;
    padding-right: 45px;
  }
  .header-searchinputspan button#head-search-button {
    margin-right: 20px !important;
  }
  .header-bg .header-searchcustom #head-auto-complete-div {
    width: calc(100vw - 125px) !important;
  }
  .sidenav-icon {
    margin-top: 5px;
  }
  /* cart addons mobile */
  #modal-order-item-addons #res-parentmenuimage {
    width: 100%;
    text-align: center;
  }
  #modal-order-item-addons #res-parentmenuimage #res-menuimage {
    padding: 0 !important;
    margin-bottom: 1rem;
  }
  #modal-order-item-addons #res-parentmenuimage #res-menuimage img {
    width: 100% !important;
  }
  #modal-order-item-addons #res-parentmenuimage #res-menuimage .aspect-ratio-box-inside {
    position: relative;
  }
  #modal-order-item-addons .row.mb-5.mb-md-3 {
    margin-right: 10px !important;
    margin-bottom: 1rem !important;
  }
  #modal-order-item-addons .res-details {
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media only screen and (min-device-width: 580px) and (max-device-width: 768px) {


  div#search-menuItemsDiv .btn-cart{ width: 91% !important;}
  div#search-menuItemsDiv .menucard{max-width: 50%;}
  img.rewards-icon-trophy {margin-left: 40% !important;}
  .desktopmain{display: unset !important;}
  .bannertext{ padding-top: 8% !important;}
   .recommendedbyfriendbox{ height:auto;}
  .recommendedheadingbox{ height: 310px; }
  .recommendedheadingbox .flavorpreferences{text-align: left;}
  /* #intro .form-control{height:46px!important;} */
}
/* Share menu review style for pretty large screen */
@media screen and (max-width: 1199px) and (min-width: 992px) {
  .platerate-icon, .clipboard-icon, .share-icon {
    max-height: 40px !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .platerate-icon, .clipboard-icon, .share-icon {
    max-height: 35px !important;
  }
  .share-order-review-via-friend, .copy-link-to-clipboard, .share-via-social {
    margin-left: 0 !important;
  }
  .head-location-src-box input[type=text]{

    margin-left: 0 !important;

    margin-left: 65px !important;
  }
  .header-bg .head-location-src-box input[type=text] {
    width: 245px !important;

  }
}
.friends-freefood-scanner{
  border: 1.01293px solid rgba(0, 0, 0, 0.29);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
}
.friends-freefood-scanner .friends-food-plate{
  background-image: url('../images/friend-freefood-plate.png') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.friends-freefood-scanner .text-loto-italic {
 font-family: Lato;
 font-style: italic;
 font-weight: bold;
}
.friends-freefood-scanner .friend-percent-font {
 font-size: 90px;
 line-height: 70px;
 font-style: normal;
 font-weight: bold;
}
.share-and-earn-hyperlink {text-decoration: underline;}
.share-and-earn-hyperlink:hover {color: #FF624B;}
.restaurant-bg-width{width: 90%}
#homepage-location-input-search,#searchinputcenter {box-shadow: none;}
span#head-auto-complete-divinput:focus-within, div#head-auto-complete-div:focus-within{
    box-shadow: inset 0 1px 1px #707070, 0 0 6px #02843d;
}
.popupModalNav{
  display: flex;
  justify-content: space-around;
  background-color: #D7D7D7;
  border-radius: 5px;
 }
.popupModalNav > a::focus {
  background-color: #02843D !important;
  color: white;
}
.target_box {
  border: 1px solid #D7D7D7;
  margin-top: -10px;
}
.scrollModal {
  overflow-y:scroll;
  overflow-x: hidden;
}
.btnModal {
  border: none;
  outline: none;
  height: 41px;
  cursor: pointer;
  font-size: 11px;
  width: 33%;
}
.btnModal:focus {
  background-color: #1e7e34;
  color: white;
  outline: none;
  height: 41px;
  width: 33%;
}
/* OVERRIDE BS Carousel */
.desktop-carousel-ui{  display: none;}
.desktop-carousel-ui.mblWishlistIcon{
  position: absolute;
  bottom: 0%;
  left: 3%;
  z-index:10;
}
.desktop-carousel-ui.zoom-menu-item-photo{
  position: absolute;
  bottom: 0%;
  right: 5%;
  cursor: pointer;
  z-index:10;
}
.desktop-carousel-ui .resto-remove-from-wish{
  cursor: pointer;
  width:40px;
  height:40px;
}
.desktop-carousel-ui  .resto-add-to-wish{
  cursor: pointer;
  width:40px;
  height:40px;
}
.carousel-control-next, .carousel-control-prev {
  width: 40px;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 40px;
  height: 40px;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.1292' cy='14.6448' r='14.6448' fill='white'/%3E%3Cpath d='M13 9L18 14.775L13 20' stroke='%23979797' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='14.4709' cy='14.6448' rx='14.1448' ry='14.6448' fill='white'/%3E%3Cpath d='M17 20L12 14.225L17 9' stroke='%23979797' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.mobile-carousel-ui-expander{
  position: absolute;
  right:4px;
  width:30px;
  height:17px;
  top:5px;
  z-index: 1;
}
.mobile-carousel-ui{
  z-index: 10;
  display: none;
}
.mobile-carousel-ui .resto-remove-from-wish{
  cursor: pointer;
  width:30px;
  height:30px;
}
.mobile-carousel-ui .resto-add-to-wish{
  cursor: pointer;
  width:30px;
  height:30px;
}

.btn-bigz {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
  flex-grow: 1;
  margin-right: 0.1rem !important;
}
.btn-outline-successs {
  color: #4A4A4A;
  background-color: transparent;
  background-image: none;
  /* border-color: #28a745; */
}
.btn-success {
  background-color: #02843d !important;
  border-color: #02843d !important;
  color: #fff !important;
}

.indicators-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2px;
  z-index: 10;
  width: 100%;
}

.indicators-wrapper span {
  width: 5px;
  height: 5px;
  margin: 2px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
  transition: all 500ms ease-out;
  text-indent: -9999px;
  box-shadow: 0 0 2px 0px rgba(0,0,0,0.3);

}
.indicators-wrapper .active {
  width: 5px;
  height: 5px;
  margin: 2px;
  background-color: #02843D;
}
.indicators-wrapper .small {
  width: 3px;
  height: 3px;
  margin: 2px;
}
.indicators-wrapper .micro {
  width: 2px;
  height: 2px;
  margin: 2px;
}
.indicators-wrapper .hidden {
  width: 0;
  height: 0;
  margin: 4px 0;
}
.btnBgColor{
  border-radius: none !important;
  color: #4A4A4A;
  background-color: #D5DCD8;
}
.header-toggle-searchbtn{
  width: 50%;
}
#headersearchnavpill{
  height:auto;
  border: none;
}
#headersearchnavpill li a{
  padding-top: 10px;
  background: #EAEAEA;
  height: auto !important;
  border-radius: 0px !important;
}
#headersearchnavpill  li a.active{
  background-color: #02843d !important;
}
#headersearchnavpill li{
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .hide-on-mobile{display: none !important;}
  .summary-cmp {
    display: block !important;
  }
  .topgreen {
    display: none;
  }
  .swiperMobilImg{
    border-radius: 0 !important;
  }
  .btn-bigz {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
    flex-grow: 1;
    margin-right: 0.1rem !important;
  }
  .desktop-desc {display: none !important;}
  .mobile-desc {display: unset !important;}
}
.tooltip{z-index: 1 !important;}
.tooltip-inner {background-color: #e5e5e5 !important;}
.vertical-line {
  border-left: 2px solid #ccc;
  height: 33px; top: 0;
  position:absolute;
  left: 50%;
}
.activedietary{
  background-color: #1e7e34;
  color: white;
  outline: none;
  border-style: none;
  height: 41px;
  width: 33%;
}
.burger-container svg{
  display: block;
  margin: 0 auto;
}
.burger-piece {
  display: none;
  position: absolute;
}

.burger-1 { animation: drop_1 0.7s ease forwards; }
@keyframes drop_1{
  0%{
    opacity: 0;
    transform: translateY(100px);
  }
  100%{
    transform: translateY(315px);
  }
}

.burger-2 { animation: drop_2 0.7s ease-in-out forwards; }
@keyframes drop_2{
  0%{
    opacity: 0;
    transform: rotate(5deg) translateY(160px) translateX(20px);
  }
  100%{
    transform: rotate(0deg) translateY(295px);
  }
}

.burger-3 { animation: drop_3 0.7s ease-in-out forwards; }
@keyframes drop_3{
  0%{
    opacity: 0;
    transform: rotate(-7deg) translateY(100px) translateX(-15px);
  }
  100%{
    transform: rotate(0deg) translateY(272px);
  }
}
.burger-4 { animation: drop_4 0.7s ease-in-out forwards; }
@keyframes drop_4{
  0%{
    opacity: 0;
    transform: translateY(90px);
  }
  100%{    transform: translateY(272px);  }
}

.burger-5 { animation: drop_5 0.7s ease-in-out forwards; }
@keyframes drop_5{
  0%{
    opacity: 0;
    transform: rotate(3deg) translateY(50px) translateX(6px);
  }
  100%{
    transform: rotate(0deg) translateY(255px) translateX(2px);
  }
}
.burger-6 { animation: drop_6 0.7s ease-in-out forwards; }
@keyframes drop_6{
  0%{
    opacity: 0;
    transform: rotate(-7deg) translateY(0px) translateX(-2px);
  }
  100%{
    transform: rotate(0deg) translateY(235px) translateX(2px);
  }
}

.burger-7 { animation: drop_7 0.7s ease-in-out forwards; }
@keyframes drop_7{
  0%{
    opacity: 0;
    transform: rotate(7deg) translateY(-80px) translateX(10px);
  }
  100%{
    transform: rotate(0deg) translateY(185px) translateX(-2px);
  }
}
ul#slectboxcurrentlocation{display: block;}
.awardimg{width: 209px; height: 208px}
/* ************************ ANIMATED BURGER ************************* END */
/* Style for datapicker in order */
.order-for-later-box .btn[data-action="togglePeriod"],.order-for-later-box .btn[data-action="togglePeriod"]:hover {
  color: #fff !important;
  background-color: #02843d !important;
  border-color: #02843d !important; 
}
#wrapper-orderdatepicker #orderdatepicker { 
  position: absolute;
  width: 100%;
  top: 9px;
  opacity: 0;
  cursor: pointer; 
}

#wrapper-orderdatepicker #btn-orderdatepicker {
  display: block;
  width: 100%;  
  color: #bdc6c1;
  background-color: #fff;
  border: 1px solid #979797 !important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 6px 25px !important;
  background: transparent !important;
  cursor: pointer;
  font-size: 13px !important;
  border-radius: 10px; 
}
#wrapper-orderdatepicker #btn-orderdatepicker.active {  color: #4a4a4a;} 
.iti-mobile .iti--container {  z-index: 9999 !important;}

.rest-available-now .card-specific-time { overflow: unset !important;}
.checkbox-rounded {
  border-radius: 50% !important;
  padding: 3px !important;
}
.home_order_btns .nav-rounded{ background: #fff!important;}
.home_order_btns #intro .nav.nav-pills .nav-item { max-width: 50%; min-width: 49%;}

#head-icons > li::before {
  display: none;
}

/* style for email template delivery, order-ahead, pickup */
.fax-p-text {
  font-size: 12px;
}
.fax-title-text {
  font-size: 13px;
}

/** CODE ADDED BY mangita **/
#cusionslider .slick-slide {
    background: #f8f8f845;
    /* border-radius: 68%; */
    /* width: 111px;
    height: 111px; */
    /* margin-right: 5px */
    padding: 5px;
}
.topsearch-resultsitems{
    /* background: #E6F3EC; */
    padding-top: 30px;
    padding-bottom: 30px;
}

.homedoorder{
  background: radial-gradient(52.38% 52.38% at 50% 50%, #014B23 0%, #002812 100%);
  height: 244px;

}
.imgcuisine{
    height: 80px;
  }

  /* summary styling by Ayinde Samuel */
  .summary-cmp {
    display: none;
  }
  .preference-items input[type="checkbox"]:checked+i.fa.fa-check.item-check{
    background-color: rgb(2, 132, 61) !important;
    color: rgb(255, 255, 255) !important;
  }
  .preference-items .item-check{margin-left: 5px;}  
  .item-check {padding: 4px;}  

  .item-check {

    margin-right: 5px;
    padding: 5px;
    border-radius: 9999px;
  }
.intro-container .first_home{
    margin-top: 5px;
    right: 15%;
    position: relative;
  }
.findclassvalue{
    margin-top: -19px;
    position: absolute;
    z-index: 9;
    right: 4px;
    color: #7a7777;
    bottom: 11px;
    float: right;
}
.sweet-alert input .wrongNumber {
  z-index: 9999;
  border: 1px solid #F74646 !important;
}

.desktopmain{
  display: flex;
}
/* How do I earn free food */
#intro .earn-free-food-step {
  width: 26px;
  height: 26px;
  background: #02843d;
}
#intro .earn-free-food-content-left {
  max-width: 680px;
}
#intro .earn-free-total {
  /* background: #ffe20b; */
  border-radius: 6px;
  padding: 6px 9px;
  line-height: 26px;
}
#intro .img-arrow-total {
  position: absolute;
  top: -46px;
  right: 34%;
  /* transform: rotate(-15deg); */
}
#intro .earn-free-food-image {
  padding: 0 36px;
}
#intro .earn-free-food-text-last {
  font-size: 14px;
}
/*End  How do I earn free food */

/* style for email header  */
/* #spannear{
  padding-left: 3rem;
} */


@media screen and (max-width: 1440px) {
  .header-bg .head-location-src-box input[type=text]{

    width: 310px !important;

    width: 290px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  
  .head-location-src-box input[type=text] {
    margin-left: 140px;

  }

#head_location_box_expand{left:76px!important;}

  /* #spannear{
    padding-left: 1.5rem;
  } */
}
/* End style for email header  */

#intro-container .intro-search-wrapper {
  padding: 0 136px;
}

/* Style for restaurant card 2nd homepage  */
@media screen and (min-width: 300px) and (max-width: 400px) {
  #homepagesearch #results .flip2 {
    height: 28rem;
  }
}  

.search-resultsitems .results-cols {
  align-items: end;
}


/* Trinhnt style for restaurant page, menu item */

.bhs-menu-item .bhs-hide-earn-item,  .bhs-menu-item.bhs-menu-has-image .bhs-show-up-item {
  display: none;
}
.bhs-menu-item.bhs-menu-has-image .bhs-hide-earn-item,.bhs-menu-item .bhs-show-up-item  {
  display: block;
}

.bhs-menu-item:not(.bhs-menu-has-image) .list-image-item {
  opacity: 0; 
  height: 0 !important;
}
.bhs-menu-item.bhs-menu-has-image .list-image-item {
  opacity: 1;
  height: auto !important;
}

.bhs-menu-item:not(.bhs-menu-has-image) .bhs-add-btn {
  max-width: 82px;
  margin-bottom: 0;
}
.bhs-menu-item .bhs-show-up-item .discount_highlight, .bhs-menu-item .bhs-hide-earn-item .discount_highlight {
  font-size: 12px !important;
  font-weight: 500;
  padding: 10px;
}
.bhs-menu-item .bhs-show-up-item {
    display: block;
    margin-right: 10px;
    border-radius: 15px;
    background: transparent;
}

/* trinhnt style for basic search home page */
.bhs-search-card-menu .bhs-earn-up-2 {
  margin-right: 8px;
}
.bhs-search-card-menu .bhs-earn-up-2 a{
  bottom: 0;
  /* font-size: 11px; */
  /* font-weight: normal; */
  margin-bottom: 0 !important;
  padding-top: 5px;
  height: 35px !important;
  border-radius: 4px !important;
  margin-right: 10px;
}
.bhs-search-card-menu .bhs-wrap-earn-add {
  display: flex;
}
.bhs-search-card-menu:not(.bhs-search-menu-img) .bhs-wrap-earn-add.bhs-menu-has-up .bhs-menu-add-btn, .bhs-search-card-menu .bhs-wrap-earn-add.bhs-menu-has-up .bhs-menu-add-btn {
  width: 82px !important;
}
.bhs-search-card-menu .bhs-earn-up-2 a span.earn{
  font-size: 11px !important;
  font-weight: normal !important;
  padding-bottom: 0px !important;
  /* padding: 10px; */
}

.bhs-search-card-menu:not(.bhs-search-menu-img) .bhs-wraper-card-image{
  opacity: 0; 
  height: 0 !important;
}

.bhs-search-card-menu:not(.bhs-search-menu-img).flip{
  height: 13rem !important;
}
.bhs-search-card-menu.bhs-search-menu-img .bhs-wrap-earn-add.bhs-menu-has-up .bhs-earn-up-2{
  width: 0 !important;
  visibility: hidden;
  margin-right: 0;
}
.bhs-search-card-menu .bhs-wrap-earn-add:not(.bhs-menu-has-up) .bhs-earn-up-2{
  width: 0 !important;
  visibility: hidden;
  margin-right: 0;
}
.bhs-search-card-menu.bhs-search-menu-img .bhs-wrap-earn-add.bhs-menu-has-up .bhs-menu-add-btn {
  width: 100% !important;
}

/* trinhnt style for connect wifi section */
#bhs-connect-wifi #form-update-wifi input.error {
    box-shadow: rgb(149, 157, 153) 0px 1px 1px inset, rgb(255, 89, 89) 0px 0px 6px;
    border-color: rgb(255, 101, 55);
}

/* Trinhnt style for Delivery Partners */
#deliveryPartner input.error {
  box-shadow: rgb(149, 157, 153) 0px 1px 1px inset, rgb(255, 89, 89) 0px 0px 6px;
  border-color: rgb(255, 101, 55);
}

/* Danhnv write style for search in homepage */
.autocomplete-homepage-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.autocomplete-homepage-wrapper .autocomplete-customize {
  width: inherit;
  height: inherit;
}
.autocomplete-homepage-wrapper .autocomplete-results {
  top: 50px;
  left: -67px;
  /*right: 0;*/
  border-top: 0;
  width: 339px;
  /*border-radius: 23px 26px 25px 25px ;*/
}

.autocomplete-homepage-wrapper .autocomplete-results-home {
    top: 50px;
  left: 20px;
  /*right: 0;*/
  border-top: 0;
  width: 100%;
 /* border-radius: 23px 26px 25px 25px ;*/
  }
#head-auto-complete-divinput .autocomplete-homepage-wrapper {

  padding-top: 2px;
  padding-bottom: 2px;
}
#head-auto-complete-divinput .autocomplete-homepage-wrapper #autocomplete-results {
  top: 43px;
}

/* Update UI for user join order mobile */
@media screen and (max-width: 768px) {
  .restaurant-menu-infor { width: 100% !important;}
  #collapse_your_order {
    margin-left: 0px; 
  }
  .autocomplete-homepage-wrapper .autocomplete-results {
  top: 50px;
  left: 0px !important;
  /*right: 0;*/
  border-top: 0;
  width: 339px;
  /*border-radius: 23px 26px 25px 25px ;*/
}
}
.activeLocation {
  background-color: antiquewhite!important;
}
/* For Google Chrome: */
#form-search-advanced .search-input-height::-webkit-input-placeholder {
  font-weight: bold;
}
/* For Mozilla Firefox: */
#form-search-advanced .search-input-height::-moz-placeholder {
  font-weight: bold;
}
/* For Internet Explorer: */
#form-search-advanced .search-input-height:-ms-input-placeholder {
  font-weight: bold;
} 
/* For Opera: */
#form-search-advanced .search-input-height:-o-input-placeholder {
  font-weight: bold;
} 

/* Style for Email/Phone to PERMANENTLY delete: */
#modal-admin-delete-user .error {
  font-size: 13px;
  margin-top: 5px;
  color: red;
}
#btn-admin-delete-account {
  border: none;
  background: transparent;
  color: #707070;
  padding-left: 34px !important; 
  font-family: 'Lato', sans-serif;
  font-size: 18px !important;
}

.m-slider::-webkit-slider-thumb {
    margin-top: -4px;
}

/* Style for google search dropdown basic search page */
#autocomplete-results-basic {
  width: 289px;
  display: none;
  position: absolute;
  left: 0px;
  top: 34px;
  border: 0.7px solid #BDBCBC;
  border-radius: 8px;
}


.dropdown-container {
  display: none;
  width: 286px;
  position: absolute;
  
  left: 0px;
  background: #FFFFFF;
  border: 0.7px solid rgba(189, 188, 188, 0.65);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.dropdown-item, .dropdown-item-menusection {
  padding: 10px 35px 10px 16px;
  width: 97% !important;
  font-size: 14px;
  color: #4A4A4A;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#inputSwitch .form-control:disabled {
  background-color: #FFF !important;
}

.input-border-focus{
  border: 0.7px solid #BDBCBC;
}


.homesignup-sidenav{
  line-height: 17px;
    padding-top: 10px;
    margin-right: 10px;
    text-align: center;
    color: #494949;
    border-radius: 4px;
    font-weight: bold;
    width: 120px;
    height: 39px;
    left: 238.64px;
    top: -2.27px;
    background: #FFFFFF;
    border-radius: 30px;
}
@media (max-width:1300px){
  .header-bg #mobilelocationdiv #inputSwitch{
    width: 150px;
  }
  #mobilelocationdiv{
    margin-left: 25px
  }
}
textarea#email-my-friends{
box-shadow: none !important;
}

.iconUploadImages {
  width: 40px;;
  height: 40px;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
}

.top-rated-menu-item-text {
  color: #000 !important;
  font-size: 20px !important;
}



.headerbanner {
 background-color: #02843D;
 color: white;
 display: flex;
 justify-content: center;
 padding: .8rem
} 

.headerbannerred {
  background-color: #F74646;
  color: white;
  display: flex;
  justify-content: center;
  padding: .8rem
}
input#order-history-from-date{
  height: 41px !important;
}
input#order-history-to-date {
  height: 41px !important;
}
.addcartbtn{
  width: 235px;
  background:#02843d;
  color:#ffffff;
  position: absolute;
  bottom: 8px;
  min-width: 70px;
}
.addcartbtnh{
  width: 230px;
  background:#02843d;
  color:#ffffff;
  bottom: 8px;
  min-width: 70px;
  height: 32px;
}
.menuitemhorziontalbox{
  background:#fff;
  box-shadow: rgba(70, 62, 62, 0.1) 5px 5px 30px, rgba(0, 0, 0, 0.24) 0px 0px 0px;
  margin-top: 25px;
  border-radius:6px;
 
}
.menu_btn{
  justify-content: space-between; 
  align-items: center;
  width: 100%; 
}

input#invites-day::-webkit-inner-spin-button, 
input#invites-day::-webkit-outer-spin-button {
  opacity: 1;
  -webkit-appearance: block; 
}

.hWidth {
  width: 540px; min-height: 186px; background:#fff;box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 30px, rgba(0, 0, 0, 0.24) 0px 0px 0px; margin-top: 30px;border-radius:6px;
}

/* responsive for horizontal menu card */
@media only screen and (max-width: 500px) {
  .hWidth {
    width: 100%; min-height: 145px;background:#fff;box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 30px, rgba(0, 0, 0, 0.24) 0px 0px 0px; margin-top: 25px;border-radius:6px;
  }
  .text-description-item{
    height: 27px;
  }
  div#bh-list-menu-items.text-description-item{
    height: 0px;
  }
  #hmenu_card{ min-height: 145px;}
  .images-for-item-box{ min-height: 145px;}
  .addcartbtnh {
    margin-top: 0;
    width: 150px;
    background: #02843d;
    color: #fff;
    min-width: 70px;
  }
  
  .boxLabelTop {
    padding-left: 2%;
    padding-right: 2%;
    background: #dff3e8;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    font-size: 12px;
  }

  .btnSettings {
    width: 120px
  }
  
}
.toast-above-b-bar {
  bottom: 65px !important;
}
input#header_search_radius_inputbox{
  margin-top: 12px;
  width: 52% !important;
}
.address{
  transform:translateY(15px);
  border-radius: 35px;
  max-width: 90%;
  padding-left:15px;
  padding-right:15px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 40px;
  
}  

.contact{
  color:black;
  height:400px;
  background-image: url('/images/Rectangle 4483.png');
  background-size:cover;
  height: auto;
}
.card-contact{
  width:30%;
  height:auto;
  text-align: center;
  box-shadow: 1px 2px 5px 1px #9E9E9E;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 10px;
}
 @media only screen and (max-width: 1052px) {
  .card-contact{
    width:60%;
  }
  .section{
    width:100%;
  }
  .img-fluid{
    height:80%;
    width:100%;
  }
}
@media only screen and ( max-width: 700px) {
  .cards{
    flex-flow: wrap;

  }
  /* .section{
    width:100%;
    height: 100%;
  } */
  .img-fluid{
    height:80%;
    width:100%;
  }

 
}
.payment-box {
  background: #ececec;
  width: 100%;
  max-width: 375px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
}
.stripe-card-design {
  background: white;
  padding: 12px;
  border-radius: 8px;
}
.amount-type {
  background: white;
  padding: 8px;
  border-radius: 8px;
  outline: none;
  border: none;
}

.amount-type:focus {
  border: none;
  outline: none;
}

.competitor-bg {
  background-color: #002b14;
  height: 293px;
  margin-top:-2%;
}
.competitor-align {
  height: 100%;
}
.primary-title {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
}
.vector-img {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.vector-img img {
  height: 100%;
}
.competitor-align p {
  color: #fff;
  font-size: 19px;
  font-weight: 300;
  line-height: 154%;
  letter-spacing: 0.029px;
}
.invalid-select {
  border-color: red !important;
}
